import React from 'react'
import CompConfirmPwd from '../../components/Login/RecuperarPwd/CompConfirmPwd'
import "../Login/Login.scss";

const ConfirmPwd = () => {
  return (
    <div className="wrapperLogin">
       <CompConfirmPwd/>
    </div>
  )
}

export default ConfirmPwd
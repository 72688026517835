import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../CheckOut/checkOut.scss"
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const CardTC = (props) => {
  const navigate = useNavigate();
  const storedData = localStorage.getItem("SessionUser");
  const SessionUser = JSON.parse(storedData);
  const PropAdrs = props.Adrs
  const isSelected = props.selected === PropAdrs[0];
  const [IsDeleteTC,SetIsDeleteTC] = useState(false)


  const deleteTC = () => {
    SetIsDeleteTC(true)
  } 
  const ConfirmDeleteTC = (id, event) => {
    event.preventDefault();


      axios
        .delete(process.env.REACT_APP_API_LINUM + '/api/credit_card/' + id, {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
          //console.log(response);
            if(response.status === 204)
              props.onRegistroEliminado(true);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/login")
          }else
            console.error('Error delivery_points:', error);
        });
  }

  const handleDeleteClick = (event) => {
    ConfirmDeleteTC(PropAdrs[0], event);
  };

  const CancelDeleteTC = () => {
    SetIsDeleteTC(false);
  }


  return (
    props.comp !== "perfil" ?
    <div className={`cardAddress ${isSelected ? 'selected' : ''}`} onClick={props.onClick}>
      <div className="iconCard"><CreditCardIcon/></div>
      <div className="dir">
        <div className='item'>{PropAdrs[1]}</div>
      </div>
    </div>
    :
    <div className={`cardAddress ${isSelected ? 'selected' : ''}`} onClick={props.onClick}>
      <div className='title'>{PropAdrs[2]}</div>
      <div className="tc">
        <div className="dir">
          <div className="iconCard"><CreditCardIcon/></div>
          <div className='item'>{PropAdrs[1]}</div>
        </div>
        <div className="acciones">
        
          { !IsDeleteTC ? 
          <>
            <div className="itemAccion delete">
              <Link className='Link' onClick={deleteTC}> 
                Eliminar 
              </Link>
            </div>
          </>:
          <>
            <div className="itemAccion delete">
              <Link className='Link' onClick={handleDeleteClick}> 
                <CheckCircleOutlineIcon/>
              </Link>
              <Link className='Link' onClick={CancelDeleteTC}> 
                <HighlightOffIcon/>
              </Link>
            </div>
          </>
          }
        </div>
      </div>
    </div>
    )
}

export default CardTC
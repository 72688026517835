import React,{useEffect, useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios';
import FormFactura from './FormFactura/FormFactura';
import { FacturacionCard } from '../../Cart/Delivery/AddressCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "./CompFacturacion.scss"

const CompFacturacion = () => {
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const navigate = useNavigate();
    const [isAddAdress , setisAddAdress] = useState(false);
    const [DeleteAddress,SetDeleteAddress] = useState(false);
    const [newAddress, SetNewAddress] = useState(false);
    const [IsEditDireccion,setIsEditDireccion] = useState(null);
    const [DataDirFactura, SetDataDirFactura] = useState([]);
    const [selectedFacuracion, setSelectedFacuracion] = useState(null)

    useEffect(() => { // Obtener datos de facturacion
        const fetchData = () => {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/invoice_data', {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
              SetDataDirFactura(response.data.invoice_data);
              SetNewAddress(false);
              SetDeleteAddress(false);
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error delivery_points:', error);
          });
        };
        fetchData();
      }, [DeleteAddress, newAddress]);

    const handleAddressCard = (Address) =>{
        //console.log(Address);
    }

    const handleopenAddress = () => {
        if(!isAddAdress)
            setisAddAdress(true);
        else
        {
            setisAddAdress(false);
            setIsEditDireccion(null);
        }
    }
    const handleRegistroEliminado = (DeleteAddress) => {  //Manejador para saber si se elimino un registro
        if(DeleteAddress)
          SetDeleteAddress(true);
      };

      const handleRegistroAgregado = (newAddress) => {  //Manejador para saber si se agrego un nuevo registro
        if(newAddress)
          SetNewAddress(true);
      };

      const handleCloseAddDireccion = () =>{
        setisAddAdress(false);
      }

      const handleEditDireccion = (EditDireccion) =>{
        if(EditDireccion){
            setIsEditDireccion(EditDireccion);
            setisAddAdress(true);
        }
      }

      const handleFactura = (Facturacion) =>{ //Manejamos informacion de factura
        setSelectedFacuracion(Facturacion.id);
      }

  return (
    <div className="wrapperFactura">

        <div className="tituloFactura">Datos de Facturación</div>
        
            {!isAddAdress ?
               <div className="ContentFactura">
                {DataDirFactura.length < 1 &&
                <div className="ContentBtn">
                    <Link className='add' onClick={handleopenAddress}>AGREGAR FACTURACIÓN</Link>
                </div>
                }
                <div className="AccountFactura">
                    {DataDirFactura.map((Facturacion,index)=>(
                    <FacturacionCard 
                        Fact = {Facturacion}
                        selected = {selectedFacuracion} 
                        onClick={() => handleFactura(Facturacion)}
                        onRegistroEliminado = {handleRegistroEliminado}
                        onEditDireccion = {handleEditDireccion}
                        comp = "perfil"
                    />
                    ))}
                </div>
                </div>:
                <div className="ContentAddFacturacion">
                    <Link className='Link' onClick={handleopenAddress}>
                            <ArrowBackIosNewIcon/>
                        </Link>
                    <div className="AddFacturacion">
                        <FormFactura  
                        onRegistroAgregado = {handleRegistroAgregado} 
                        closeAddDireccion = {handleCloseAddDireccion}
                        EditDireccion = {IsEditDireccion}
                        Ref = "/Account" />
                    </div>
                </div>
            }
        </div>
    


  )
}

export default CompFacturacion
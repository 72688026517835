import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from "axios";
import Modal from 'react-modal';
import "./modalResponse.scss";
import FormFactura from '../Facturacion/FormFactura/FormFactura';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CompFactura from './Actions/CompFactura';
import CompTracking from './Actions/CompTracking';


const PedidosCard = (props) => {
    const navigate = useNavigate();
    const storedData = localStorage.getItem("SessionUser");
    const state = localStorage.getItem("Logged");
    const SessionUser = JSON.parse(storedData);
    const [imageUrls, setImageUrls] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [DataFactura, SetDataFactura] = useState("");
    const [newAddress, SetNewAddress] = useState(false);
    const [modalSegPedidoIsOpen,SetmodalSegPedidoIsOpen] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => { // Obtener datos de facturacion
    const fetchData = () => {
    axios
        .get(process.env.REACT_APP_API_LINUM + '/api/invoice_data', {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            SetDataFactura(response.data.invoice_data[0]);
        })
        .catch((error) => {
        if(error.response?.status === 401){
            localStorage.removeItem('SessionUser');
            localStorage.removeItem('Logged');
            navigate("/login")
        }else
            console.error('Error delivery_points:', error);
        });
    };
    fetchData();
    }, [newAddress]);

    useEffect(() => {
        const loadImages = async () => {
          const urls = [];
          for (const pedido of props.Pedidos.products) {
            if (pedido.name !== "Envio") {
              try {
                const response = await axios.get(
                  process.env.REACT_APP_API_URL + 'product-catalogues/' + pedido.id + '?populate=*'
                );
                const DataFull = response.data.data;
                const urlImagen = DataFull.attributes.productImages.data[0].attributes.formats.thumbnail.url;
                const imgThumbnail = process.env.REACT_APP_UPLOAD_URL + urlImagen;
                urls.push(imgThumbnail);
              } catch (error) {
                if (error.response?.status === 401) {
                  localStorage.removeItem('SessionUser');
                  localStorage.removeItem('Logged');
                  navigate("/login")
                } else {
                  console.error('Error strapi Product-catalogues:', error);
                }
              }
            }
          }
          setImageUrls(urls);
        };
    
        loadImages();
      }, [props.Pedidos.products]); // Ejecutar cuando props.Pedidos.products cambia

    const HandleOpenDetalle = () =>{
        props?.openDet(props.Pedidos);
        props?.openDatFact(DataFactura);
    }
      
    const openModal = () => {
        setModalIsOpen(true);
    };

    const IsCreateFactura = () =>{
        props.onGenerarFactura(true);
    }

    const openModalSegPedido = (event) =>{
        SetmodalSegPedidoIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
        SetmodalSegPedidoIsOpen(false);
    };

    const customModalStyles = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '80%' : '50%', 
          height: '60%',
          background: 'white'
        }
      };

    const customModalStylesPedido = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '80%' : '50%', 
            height: '80%',
            background: 'white'
          }
    };


    const handlestatus = (status) =>{
        switch (status){
            case "Nuevo":
            case "En Despacho":
                return "Procesando Pedido"
            case "En Transito":
                return "En transito"
            case "Entregado":
                return "Entregado"
            case "Cancelado":
                return "Cancelado"
        }
        
    }


  return (
    <>
        <div className="cardPedidos">
            <div className="infoCard">
                <div className="item">
                    <div className='title'>PEDIDO NO.</div> 
                    {props.Pedidos?.id}
                </div>
                <div className="item">
                    <div className='title'>PEDIDO REALIZADO</div>
                    {props.Pedidos?.order_date}
                </div>
                <div className="item">
                    <div className='title'>TOTAL</div>
                    ${props.Pedidos?.total}
                </div>

                <div className="item">
                    <div className='title'>ESTATUS</div>
                        <div className={props.Pedidos?.status === "Cancelado"?"cancelado":""}>
                            {handlestatus(props.Pedidos?.status)}
                        </div>
                </div>
                
            </div>
            <div className="detalleCard">
                <div className="InfoDetalle">
                    <div className="ProductImages">
                        {imageUrls.slice(0,isMobile ? 1:2).map((imageUrl, index) => (
                            <div className="imagenPedido" key={index}>
                                <img src={imageUrl} alt="" />
                            </div>
                        ))}
                        {props.Pedidos.products?.length > (isMobile ? 2:3) && (
                            <div className="masImagenes"  onClick={HandleOpenDetalle} >
                                <span> 
                                    + 
                                    {props.Pedidos.products
                                        .filter(producto => producto.name !== "Envio")
                                        .length - (isMobile ? 1:2)
                                    }
                                </span>
                            </div>
                        )}
                        
                    </div>

                    <div className="BtnAcciones">
                        <div className="btnDetalles" onClick={HandleOpenDetalle}>
                            <div className="icono">
                                <InfoIcon/>
                            </div>
                            <div className="txt">Detalle</div> 
                        </div>
                        <div
                            className={`btnDetalles ${props.Pedidos?.status === "En Transito" || props.Pedidos.status === "Entregado"  ? '' : 'deshabilitado'}`}
                            onClick={props.Pedidos?.status === "En Transito" || props.Pedidos.status === "Entregado" ? openModalSegPedido : undefined}
                            >
                                <div className="icono">
                                    <LocalShippingIcon/>
                                </div>
                                <div className="txt">
                                    {props.Pedidos?.status === "Entregado" ?
                                        "Entregado":
                                        "Pedido" 
                                    }
                                </div>
                            </div>
                            <div
                            className={`btnDetalles ${(props.Pedidos?.status !== 'Nuevo' && props.Pedidos?.status !== 'En Despacho'  && props.Pedidos?.status !== 'Cancelado') ? 
                                        '' : 'deshabilitado'}`}
                            onClick={props.Pedidos?.status !== 'Nuevo' && props.Pedidos?.status !== 'En Despacho' && props.Pedidos?.status !== 'Cancelado' ? openModal : undefined}
                            >
                                <div className={"icono"}>
                                    <ReceiptIcon/>
                                </div>
                                <div className="txt"> 
                                {props.Pedidos.invoiced === "SI" ?
                                    "Facturado":
                                    "Facturar"
                                }
                                </div>
                            </div>
                    </div>
                </div>
               
            </div>
        </div>
        {/*Modal de factura*/}
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style = {customModalStyles}
        >
            <div className="modal-header">
                <p className='title'>Generación de factura</p>
                <Link onClick={closeModal} className='Link'><ClearIcon/></Link>
            </div>
            <div className="modal-body">
            {props.Pedidos.invoiced === "FECHA_PARA_FACTURACION_EXPIRADA" ?
            <>
                <div className='txt'>
                    El periodo de facturación para este pedido ha vencido
                </div>
            </>
            :
             <CompFactura 
                IsReFactura = {props.Pedidos.invoiced === "SI" && true}
                CloseModal = {closeModal}
                Pedidos = {props.Pedidos}
                onCreateFactura = {IsCreateFactura}
                />
            }
           
            </div>
    </Modal>
    <Modal
            isOpen={modalSegPedidoIsOpen}
            onRequestClose={closeModal}
            style = {customModalStylesPedido}
        >
            <div className="modal-header">
                <p className='title'>Seguimiento de pedido</p>
                <Link onClick={closeModal} className='Link'><ClearIcon/></Link>
            </div>
            <div className="modal-body">
                  <CompTracking 
                    Detalle = {props?.Pedidos}/>
              
            </div>
        </Modal>
    </>
  )
}

export default PedidosCard
import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useAsyncError } from 'react-router-dom';
import "../Register/CompRegister.scss"
import Modal from 'react-modal';
import axios from "axios";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CompRegister = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nombre, setnombre] = useState('');
    const [apellido, setapellido] = useState('');
    const [ErrorRegister, setErrorRegister] = useState("");
    const [ErrConfPwd, setErrConfPwd] = useState("");
    const [passwordConfirm , setConfPassword] = useState("");
    const [telefono,setTelefono] = useState("");
    const [telefonoMovil,setTelefonoMovil] = useState("");
    const [isCheckedTyC, setIsCheckedTyC] = useState(false);
    const [ErrCheckedTyC,setErrCheckedTyC] = useState(false);
    const [isCheckedPromos, setIsCheckedPromos] = useState(true);
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [IsLoading, SetIsLoading] = useState(false);
    const state = localStorage.getItem("Logged");


    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (modalIsOpen) {
        const timer = setTimeout(() => {
          setModalIsOpen(false);
          setErrConfPwd("");
          setErrorRegister("");
          setErrCheckedTyC(false);
        }, 4000); //
  
        return () => clearTimeout(timer);
      }
    }, [modalIsOpen]);
  
    useEffect(()=>{
      if(state){
          navigate('/Account');
      }
    },[])

    const closeModal = () => {
      setModalIsOpen(false);
      setErrConfPwd("");
      setErrorRegister("");
      setErrCheckedTyC(false);
    };
  
    const handleNombreChange = (event) => {
      setnombre(event.target.value);
    };

    const handleApellidoChange = (event) => {
      setapellido(event.target.value);
    };

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };

    const handleConfPasswordChange = (event) => {
        setConfPassword(event.target.value);
      };

    const handleTelefonoChange = (event) => {
      setTelefono(event.target.value);
    }

    const handleTelefonoMovilChange = (event) => {
      setTelefonoMovil(event.target.value);
    }
      
    const handleRegister = (event) => {
      event.preventDefault();

      if (password != passwordConfirm){
        setModalIsOpen(true);
        setErrConfPwd(true);
        return;
      }

      if(!isCheckedTyC){
        setModalIsOpen(true);
        setErrCheckedTyC(true);
        return;
      }

      const RegisterData = {
        name: nombre ,
        last_name: apellido,
        email,
        password,
        passwordConfirm,
        phone: telefono && parseInt(52 + telefono),
        phone_mobile: telefonoMovil && parseInt(52 + telefonoMovil)
      }
      
      SetIsLoading(true);

      axios
          .post(process.env.REACT_APP_API_LINUM + '/api/register', RegisterData, {
              headers: {
                  'Content-Type': 'application/json'
              }
          })
          .then((response) => {

            //console.log(response);
            
              if (isCheckedPromos){
                
                  const DataPromo = {
                    first_name: nombre,
                    last_name: apellido,
                    phone:telefonoMovil,
                    email
                  }
                  
                  axios
                  .post(process.env.REACT_APP_API_LINUM + '/api/mailchimp', DataPromo, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                  })
              }

              if(response.data.status)
              {
                const jsonData = JSON.stringify(response.data);
                localStorage.setItem("SessionUser", jsonData);
                localStorage.setItem("Logged", true);
                  if(props.Ref)
                    navigate(props.Ref)
                  else
                    navigate("/Account");
              }
          })
          .catch((error) => {
              if(error.response?.data.detail)
                setErrorRegister(error.response?.data.detail)
              else{
                setErrorRegister("Ocurrio un error interno, intente más tarde");
                console.error('Error al registrar usuario:', error.code);
              }
              setModalIsOpen(true);
          }); 

        SetIsLoading(false);
    };

    const handleTyC = () =>{
      setIsCheckedTyC(!isCheckedTyC)
    };

    const handlePromos = () =>{
      setIsCheckedPromos(!isCheckedPromos)
    };

    const customModalStyles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      content: {
        overflow:"hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '80%' : '25%', 
        height: isMobile ? '15%' : '10%',
        background: 'white',
        border: '2px solid rgb(210, 67, 67)', // Agrega un borde negro
        borderRadius: '10px'
      }
    };
  
    return (
      <>
      <div className="contentRegister">
        <h2 className='titulo'>Crear cuenta</h2>
        <form onSubmit={handleRegister} className='form'>
          <div className="row">
          <div className='FormRow'>
            <label className='label'>Nombre</label>
            <input 
              type="text" 
              value={nombre} 
              onChange={handleNombreChange} 
              placeholder="Nombre" required 
              className='FormRow'/>
          </div>
          <div className='FormRow'>
            <label className='label'>Apellidos</label>
            <input 
              type="text" 
              value={apellido}
              onChange={handleApellidoChange} 
              placeholder="Apellido" 
              required 
              className='FormRow'/>
            </div>
          </div>
          <div className="row">
          <div className='FormRow'>
            <label className='label'>Teléfono Móvil {!isMobile && "(Notificaciones)"}</label>
            <input 
              type="text" 
              value={telefonoMovil} 
              onChange={handleTelefonoMovilChange} 
              placeholder="Teléfono a 10 digitos" 
              pattern="[0-9]{10}"
              title="Ingresa los 10 digitos de tu télefono"
              maxLength="10"
              inputMode="numeric"
              required/>
            </div>
            <div className='FormRow'>
            <label className='label'>Teléfono</label>
            <input 
              type="text" 
              value={telefono} 
              onChange={handleTelefonoChange} 
              placeholder="Teléfono a 10 digitos" 
              pattern="[0-9]{10}"
              title="Ingresa los 10 digitos de tu télefono"
              maxLength="10"
              inputMode="numeric" />
            </div>
          </div>
          <div className="row">
            <div className='FormRow'>
              <label className='label'>Email</label>
              <input 
                type="email" 
                value={email} 
                onChange={handleEmailChange} 
                placeholder="Email" required />
            </div>
          </div>
          <div className="row">
            <div className='FormRow'>
              <label className='label'>Contraseña</label>
              <input 
                type="password" 
                value={password} 
                onChange={handlePasswordChange} 
                placeholder="Debe tener al menos 8 caracteres" 
                minLength="8" required />
            </div>
          </div>

          <div className="row">
            <div className='FormRow'>
              <label className='label'>Repetir Contraseña</label>
              <input type="password" value={passwordConfirm} 
                onChange={handleConfPasswordChange} 
                placeholder="Contraseña" 
                minLength="8" required />
            </div>
          </div>
          <div className="optionsCheck">
          <div className="Check">
              <input type='checkbox' 
              checked={isCheckedPromos}
              onClick={handlePromos}/>
              Deseo estar enterado de ofertas y promociones
          </div>
          <div className="Check">
              <input type='checkbox' 
              checked={isCheckedTyC}
              onClick={handleTyC}/>
              Al registrarme, acepto los <Link to="/tyc" target="_blank" className='Link'>terminos y condiciones.</Link>
          </div>
          </div>
          {
          !IsLoading ?
          <button type="submit">REGISTRARME</button>:
          <button className='inactive' type="submit">PROCESANDO...</button>
          }
          <div>
            ¿Ya tienes una cuenta? &nbsp;
            <Link to = "/Login" className='Link'>Iniciar sesión</Link>
          </div>
        </form>
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style = {customModalStyles}
        >
          <div className="modal-Register">
            <div className="mejerror">
              <div className="icon"><ErrorOutlineIcon/></div>
              {ErrorRegister && <div className='txt'>{
              ErrorRegister === "Account already exist" ?
              "No es posible generar la cuenta debido a que ya existe un Cliente registrado con ese email, para aclaraciones por favor contacta a tu vendedor o mandanos un correo a administracion@linum.mx":
              ErrorRegister
              }</div>}
              {ErrConfPwd && <div className='txt'>Contraseña no coincide</div>}
              {ErrCheckedTyC && <div className='txt'>Por favor acepta los terminos y condiciones</div>}
            </div>
          </div>

      </Modal>

    </>
    );
  };

export default CompRegister
import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import CompLogin from '../../Login/CompLogin'
import "../CartAuth/CartAuth.scss"

const metodo = "/Delivery"

const CartAuth = () => {
    const navigate = useNavigate();
    const state = localStorage.getItem("Logged");
  
    useEffect(()=>{
      if(state){
          navigate('/Delivery', { state: "RutaDePago" });
      }
    },[])
  return (
    <div className="wrapperLogin">
    <CompLogin Ref = {metodo}/>
    </div>
  )
}

export default CartAuth
import React, { useEffect, useState } from 'react'
import "../Cart/CartComp.scss";
import axios from "axios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { removeItem, resetCart } from '../../redux/CartReducer';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from 'react-modal';
import ContadorExp from './ContadorExp';

export const useReduxProducts = (callback) => {
  const products = useSelector(state => state.cart.products);

  useEffect(() => {
    callback(products);
  }, [products]);
};


const CartComp = (props) => {
    const products = useSelector(state=>state.cart.products)
    const dispatch = useDispatch();
    const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    const [showModal, setShowModal] = useState(false);
    const [RespSesion,setRespSesion] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [is320, setis320] = useState(false);
    const [ExpiredCart,setExpiredCart] = useState(false);
    const tiempoExpiracionModal = 60; // segundos
    const [tiempoEnMinutos,SettiempoEnMinutos ] = useState(5); // minutos default 5
    let temporizador = null;

    useEffect(() => {
      const fetchData = async () => {
          const catalogoData = await axios.get(process.env.REACT_APP_API_LINUM + '/api/parameters');
          const DataFull = catalogoData.data;

          const foundTime = DataFull.find(BajaExistencia => BajaExistencia.parameter === "expiration_shopping_cart");
          SettiempoEnMinutos(foundTime.value);
      };
      fetchData();
  }, []);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
          setis320(window.innerWidth <= 320);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
      if (ExpiredCart) {
        temporizador = setTimeout(() => {
          //console.log("expired")
          setExpiredCart(false);
          setShowModal(false);
        }, 3000); //
  
        return () => clearTimeout(temporizador);
      }
    }, [ExpiredCart]);

    useEffect(() => { // generamos el temporizador para expirar sesion y mandamos a limpiarCarritoYEnviarPeticion

      if (products.length > 0 )
      {
        if (RespSesion.length === 0) {
          const tiempoDeExpiracion = tiempoEnMinutos * 60 * 1000; // Convertir minutos a milisegundos
          temporizador = setTimeout(limpiarCarritoYEnviarPeticion, tiempoDeExpiracion);
          //console.log(tiempoDeExpiracion)
        }
        else {
          limpiarCarritoYEnviarPeticion();
        }

        window.addEventListener('beforeunload',(event) =>  limpiarCarritoYEnviarPeticion(event, 'user_leaving_page'));
      }

      return () => {
          clearTimeout(temporizador);
          if (ExpiredCart) setShowModal(false);
          window.removeEventListener('beforeunload', limpiarCarritoYEnviarPeticion);
        };

    }, [products, RespSesion]);
        
    const limpiarCarritoYEnviarPeticion = (event, reason) => {

      const CancelDispatch = () => {
        dispatch(resetCart({ goStrapi: true }));
        setRespSesion("");
      };

      if (reason === 'user_leaving_page') {
        if (event.returnValue !== '') CancelDispatch();
        const message = '¿Seguro que quieres salir?';
        event.returnValue = message; // La mayoría de los navegadores requieren esta línea
        return message;
      } 
      else{
        if(!showModal)
          OpenModal();

        if(!RespSesion){
            temporizador = setTimeout(() => {
            //console.log('Tiempo de expiración alcanzado');
            setExpiredCart(true);
            CancelDispatch();
          }, tiempoExpiracionModal * 1000);
        }
        else if (RespSesion === "cancelar") {
            clearTimeout(temporizador);
            setShowModal(false);
            CancelDispatch();
        }else if (RespSesion === "confirmar"){
            clearTimeout(temporizador);
            setShowModal(false);
            setRespSesion("");
        }
      }
    };
      
    const HandleRespSesion = (e) => {
        setShowModal(false);
        setRespSesion(e);
    };

    const OpenModal = () => {
        if(!showModal)
          setShowModal(true); 
    }

    const CloseModal = () => {
        setShowModal(false); 
        setExpiredCart(false);
    }

    const TotalPrice = () => {
        let total = 0;
        products.forEach((item) => {
            const precio = parseFloat(item.priceD.length > 0 ? item.priceD.replace('$', ''):item.price.replace('$', ''));
            total += item.quantity * precio
        });
        
        return total.toFixed(2);
    };

    const handleClick = () => {
        props.cerrarC(true);
      }
      
    const HandleDelete = (id, quantity) =>{
            dispatch(
                removeItem({
                id,
                quantity
                })
            );
            props.OnDelete(true)
    }

    const customModalStyles = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          overflow: 'hidden',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '80%' : '35%', 
          height: isMobile ? is320 ? '40%':'30%' : '200px',
          background: 'white',
          zIndex: 1001,
        }
      };


  return (
    <>
        <div className="cart">
            <div className="infoCart">
                <div className="topcart">
                    <div className="title">
                        Productos en tu carrito
                    </div>
                    <div className="cerrar" onClick={handleClick}><ClearIcon className='icon'/></div>
                </div> 
                <hr></hr>
                <div className="cartItems">
                    <div className="itemCart">
                        {products.length === 0 && "Aun no cuentas con productos en tu carrito"}
                        {products.map((item) =>(    
                        <div className="imgDetails" key={item.id}>
                            <img 
                            src={item.img}
                            alt=""/>
                            <div className="details">            
                                <div className="titleProd">{item.title}</div>
                                <div className="price">{item.quantity} x {item.priceD.length > 0 ? item.priceD : item.price}</div>
                                { item.priceD.length > 0 ? 
                                    <div className = 'Oferta'>Oferta</div>
                                    :""
                                }
                                <div className="delete">
                                    <DeleteForeverIcon onClick={()=>HandleDelete(item.id, item.quantity)} className='del'/>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="contentBottom">
                        <div className="itemCartBottom">
                            <div className="Total">
                                    <span>SUBTOTAL</span>
                                    <span>{formatter.format(TotalPrice())}</span>
                                </div>
                                
                                <div className="pago">
                                    <Link to ="/Orden" className='botonCompra' onClick={handleClick}>VER CARRITO</Link>
                                    <Link to ="/Catalogo" className='botonCompra' onClick={handleClick}>CONTINUAR COMPRANDO</Link>
                                    <span className='reset' onClick={()=>dispatch(resetCart({goStrapi:true}))}>borrar articulos</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
          isOpen={showModal}
          style={customModalStyles}
          >
          <div className="contentModalExpi">
            { !ExpiredCart ?
              <>
              <div className="contador"> 
              <ContadorExp initialTime={tiempoExpiracionModal}/>
              </div>
              <div className="txtconfirmar">
                Tu carrito de compra esta por expirar, ¿deseas un poco más de tiempo para completar tu compra?
              </div>
              <div className="button">
                  <button type="button" onClick={() => HandleRespSesion("confirmar")} className='cerrar'>Deseo más tiempo</button>
                  <button type="button" onClick={() => HandleRespSesion("cancelar")} className='cerrar cerrar2'>Vaciar carrito</button>
              </div>
              </>:
              <>
              <div className="contador"> </div>
                <div className="txtconfirmar exp">
                  Se ha alcanzado el tiempo de expiración de tu carrito.
                </div>
                <div className="button">
                    <button type="button" onClick={()=>CloseModal()} className='cerrar'>Cerrar</button>
                </div>
              </>
            }
          </div>
          </Modal>
    </>
  )
}

export default CartComp
import { Navigate} from "react-router-dom";
  const PrivateRoute = ({ children }) => {
    const state = localStorage.getItem("Logged");
    return state ? children : <Navigate to="/login" />;
  };
  export const PrivateRouteCartAuth = ({ children }) => {
    const state = localStorage.getItem("Logged");
    return state ? children : <Navigate to="/CartAuth" />;
  };

  export default PrivateRoute
import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { resetCart } from '../../../redux/CartReducer';
import "../PagoProcesado/pago_procesado.scss"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

const PagoProcesado = () => {
    const dispatch = useDispatch();
    const { resp } = useParams();
    const [proccess , setProccess] = useState(false);
    const storedData = localStorage.getItem("InfoPay");
    const parsedData = JSON.parse(storedData);
    const [folio, setfolio] = useState(0);
    const [IDSeg , SetID] = useState(0);

    const closeModalInParent = () => {
        const data ={
            modal : resp === 'OK' ?'closeModalOK':'closeModalERR',
            folio : folio,
            IDSeg : IDSeg
        }

        window.parent.postMessage(data);
      };

      //console.log(parsedData);

    // se intenta hacer pedido 
    useEffect(()=>{
        setfolio(parsedData.folio);
        if(resp === "OK"){

            const order = {
                client: {
                  name: parsedData.name,
                  email: parsedData.email,
                  phone: ""
                },
                delivery_point: {
                  contact_name: parsedData.name,
                  postal_code: parsedData.dataAddress.postal_code,
                  state_id: parsedData.dataAddress.state,
                  city: parsedData.dataAddress.city,
                  reference: "",
                  address: parsedData.dataAddress.address
                },
                invoice_data: {
                  name: parsedData.name,
                  rfc: "",
                  email: "",
                  address: ""
                },
                products: parsedData.pedido
              };

            axios
            .post(process.env.REACT_APP_API_LINUM + '/api/orders', order, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                //console.log(response);
                const id = response.data.order;
                SetID(id);
            })
            .catch((error) => {
                console.error('Error al enviar pedido:', error);
                const jsonData = JSON.stringify(order);
                localStorage.setItem("InfoPay-Pending", jsonData);
            });

            dispatch(resetCart());
            setProccess(true);
        }
        
        localStorage.removeItem("InfoPay");
    },[resp, dispatch])

  return (
    <div className="WrapperPago">
        <div className="contentPago">
            {proccess ? 
            <>
            <div className='TituloPagoOK'>
                <div className="icon">
                    <CheckCircleIcon className='iconCheck'/>
                </div>
                <span>Pago aceptado</span>
            </div>
            <div className="link">
                <Link onClick={closeModalInParent} className='terminar'>Ir a tu pedido</Link>
            </div>
            </>:
            <>
            <div className='TituloPagoERR'>
                <div className="icon">
                    <CancelIcon className='iconCancel'/>
                </div>
                <span>Pago rechazado</span>
            </div>
            </>
            }
        </div>
    </div>
  )
}

export default PagoProcesado
import { useState, useEffect } from "react";
import "../../../pages/Home/Secciones/sn5/Seccion5.scss";
import axios from "axios";
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';

export const Sn5ContactoHome = () => {

    const [PopularesContactoHome, setData] = useState([]);
    const [PopularesImgContactoHome, setImg] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                //const PopularesDPrincipal = await axios.get(process.env.REACT_APP_API_URL + 'sn5-contactos?populate=*');
                const PopularesDPrincipal = await axios.get(process.env.REACT_APP_API_URL + 'contact-us?populate=*');
                const DataFull = PopularesDPrincipal.data.data.attributes;
                const Imge = DataFull.image.data.attributes.formats.large;
                setData(DataFull);
                setImg(Imge);
              };
              
              fetchData();
        }, []);

        return (
            <div className="wrapperSeccion5">
            <div className="contenedorSn5">
            <div className="contactoLeft"> 
                <div className="ImgContacto" key="">
                    <img 
                    src={process.env.REACT_APP_UPLOAD_URL + PopularesImgContactoHome.url} alt=""></img>
                    </div>
                <div className="direccionmaps">

                </div>
            </div>
            <div className="contactoRight">
                    <div className="textoContacto" key="">
                        <h3>{PopularesContactoHome.title}</h3>
                        <h1>{PopularesContactoHome.subTitle}</h1>
                        <span>{PopularesContactoHome.paragraph}</span>
                        <div className="boton">
                            <Link to="/Contacto/"><EastIcon className='iconCat'/></Link>
                        </div>
                    </div>
            </div>
            </div>

        </div>
        )
}

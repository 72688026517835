import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import PedidosCard from './PedidosCard';
import "../Pedidos/CompPedidos.scss";
import DetallePedido from './DetallePedido/DetallePedido';
import { set } from 'date-fns';
import DotLoader from "react-spinners/ClipLoader";


const CompPedidos = () => {

    const navigate = useNavigate();
    const state = localStorage.getItem("Logged");
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const [DataPedidos,setPedidos] = useState([]);
    const [OpenDetalle, SetOpenDetalle] = useState(false);
    const [DataDetallePedido, SetDetallePedido] = useState([]);
    const [IsNewFactura, setIsNewFactura] = useState(false);
    const [DataFactura,SetDataFactura] = useState("");
    const [ErrorRequestOrders,setErrorRequest] = useState(false);
    const [FilterPedidos,setFilterPedidos] = useState("mes");
    const currentDate = new Date();
    const [isMobile, setIsMobile] = useState(false);
    const [loading,setloading] = useState(true);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    const HandleOpenDetalle = (Pedido) => {
        if(Pedido)
        {
            SetDetallePedido(Pedido)
            SetOpenDetalle(true)
        }
    }
    const HandleOpenFactura = (Data) => {
        if(Data)
            SetDataFactura(Data)
    }
    const HandleGenerarFactura = (GenFactura) => {
        if(GenFactura)
            setIsNewFactura(true);
    }

    const CloseDetalle = (r) => {
        if(r)
            SetOpenDetalle(false)
    }
    
    useEffect(() => {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/orders', {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
            //console.log(response);
              setPedidos(response.data.orders);
              setIsNewFactura(false);
              setloading(false);
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error get orders:', error.code);
              setErrorRequest(true);

              setloading(false);
          });
    }, [IsNewFactura, FilterPedidos]);

    const handleFilterPedidos = (id)=>{setFilterPedidos(id);}

    const filterPedidosByDate = (pedidos, filterType, currentDate) => {
        return pedidos?.filter((pedido) => {
          const pedidoDate = new Date(pedido.order_date);
          const monthDiff = (currentDate.getFullYear() - pedidoDate.getFullYear()) * 12 + (currentDate.getMonth() - pedidoDate.getMonth());
      
          switch (filterType) {
            case 'mes':
              return monthDiff === 0;
            case '3mes':
              return monthDiff <= 3;
            case '6mes':
              return monthDiff <= 6;
            default:
              return true;
          }
        });
      };

    const FiltroChangeHandler = (event) =>{
        handleFilterPedidos(event.target.value);
    }

      let filteredPedidos = filterPedidosByDate(DataPedidos, FilterPedidos, currentDate);

  return (
    <div className="wrapperPedidos">
        <div className='tituloPedidos'>Mis Pedidos</div>
        <div className="ContentPedidos">
        { !OpenDetalle ?
        <>
            {!isMobile ?
            <div className="filtros">
                <div className="txtFiltro">
                    <div 
                    className={FilterPedidos === 'mes' ? 'item active' : 'item'}
                    onClick={()=>handleFilterPedidos("mes")}>
                        Mes actual
                    </div>
                    <div 
                    className={FilterPedidos === '3mes' ? 'item active' : 'item'}
                    onClick={()=>handleFilterPedidos("3mes")}>
                        Últimos 3 meses
                    </div>
                    <div 
                    className={FilterPedidos === '6mes' ? 'item active' : 'item'}
                    onClick={()=>handleFilterPedidos("6mes")}>
                        Últimos 6 meses
                    </div>
                    <div 
                    className={FilterPedidos === 'all' ? 'item active' : 'item'}
                    onClick={()=>handleFilterPedidos("all")}>
                        + 6 meses
                    </div>
                </div>
            </div>:
            <>
            <div className="filtros">
                <div className="txtFiltro">
                <label>Filtros: </label>
                    <select
                        onChange={FiltroChangeHandler}
                        className="FiltroPedidos"
                    >
                        <option  value="mes">
                            Mes actual
                        </option>
                        <option  value="3mes">
                            Últimos 3 meses
                        </option>
                        <option value="6mes">
                            Últimos 6 meses
                        </option>
                        <option value="all">
                            + 6 meses
                        </option>
                    </select>
                </div>
            </div>
            </>
            }
        <div className="InfoPedidos">
            {loading ? (
                <>
                <div className='errtxt'>
                    <div className="txt">Un momento , estamos buscando tus pedidos...<br /></div>
                    <DotLoader
                    color={"#000000"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                </div>
                </>
            ) : (
            <>
                {filteredPedidos.length > 0 ? (
                    filteredPedidos.sort((a, b) => b.id - a.id).map((pedido, index) => (
                        <PedidosCard
                            key={index}
                            Pedidos={pedido}
                            openDet={HandleOpenDetalle}
                            openDatFact={HandleOpenFactura}
                            onGenerarFactura={HandleGenerarFactura}
                        />
                    ))
                ) : (
                    !ErrorRequestOrders &&
                    <div className='errtxt'>Para este mes seleccionado no tienes pedidos</div>
                )}
                {ErrorRequestOrders && (
                    <div className='errtxt'>
                        Estamos teniendo problemas para obtener tus pedidos, por favor intenta más tarde. Si el problema persiste, comunícate a soporte.
                    </div>
                )}
                {!ErrorRequestOrders && DataPedidos.length === 0 && (
                    <div className='errtxt'>Aun no cuenta con Pedidos realizados</div>
                )}
            </>
            )}
        </div>
        </>:
            <div className="detallePedido">
                <DetallePedido 
                Detalle={DataDetallePedido} 
                DataFactura ={DataFactura}
                onReturn = {CloseDetalle}/>
            </div>
        }
        </div>

    </div>
    
  )
}

export default CompPedidos
import React,{useEffect, useRef, useState} from 'react'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import "../Delivery/Delivery.scss"
import { useSelector } from 'react-redux';
import AddressCard, { FacturacionCard } from './AddressCard';
import axios from 'axios';
import { useDispatch } from "react-redux";
import AddRegistroModal from './ModalAddRegistro';
import DotLoader from "react-spinners/ClipLoader";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddIcon from '@mui/icons-material/Add';

const Delivery = () => {

  const navigate = useNavigate();
  const products = useSelector(state=>state.cart.products);
  const priceTotal = useSelector(state=>state.cart.priceTotal);
  const previousDataRef = useRef(products);
  const [datoPago, setDatoPago] = useState(null);
  const location = useLocation();
  const [selectedDireccion, setSelectedDireccion] = useState(null)
  const [selectedFacuracion, setSelectedFacuracion] = useState(null)
  const [isFacturas, setisFacturas] = useState(false);
  const [DataAddress,setDataAddress] = useState("")
  const [DataFactura,setDataFactura] = useState("")
  const storedData = localStorage.getItem("SessionUser");
  const [dataCreditCard , SetData] = useState([]);
  const [DataDirFactura, SetDataDirFactura] = useState([]);
  const SessionUser = JSON.parse(storedData);
  const dispatch = useDispatch();
  const [Metodo,setMetodo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
  const [newAddress, SetNewAddress] = useState(false);
  const [TotalF, setTotalFull] = useState(0);
  const [Componente , setComponente] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [WaitShipping,setWaitShipping] = useState(false);
  const [ErrDelivery,setErrDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ErrorDir, setErrorDir] = useState(false);
  const [ErrorFac, setErrorFac] = useState(false);
  const [ErrMetodo, setErrMetodo] = useState(false);
  const [DivDireccionMobile, setDivDireccionMobile] = useState(true);
  const [DivMetodoMobile, setDivMetodoMobile] = useState(false);
  const [EstimatedDate, setEstimatedDate] = useState(new Date());

    useEffect(() => { // Obtener Direccion de envio
      const fetchData = () => {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/delivery_points', {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
              SetData(response.data.deliveries);
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/CartAuth")
            }else
              console.error('Error delivery_points:', error);
          });
        };
        fetchData();
    }, [newAddress]);

    useEffect(() => { // Obtener datos de facturacion
      const fetchData = () => {
      axios
        .get(process.env.REACT_APP_API_LINUM + '/api/invoice_data', {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            SetDataDirFactura(response.data.invoice_data);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/CartAuth")
          }else
            console.error('Error delivery_points:', error);
        });
      };
      fetchData();
    }, [newAddress]);

    useEffect(() => { // si no existe producto o se modificaron se redirecciona a orden
      if (!products || products !== previousDataRef.current) {
        // Se detectó un cambio en los datos
        navigate('/Orden');
        // Actualizar el estado anterior con el estado actual
        previousDataRef.current = products;
      }
    }, [products, navigate]);

    useEffect(() => { // si no tenemos el precio Total y no esta en el flujo del carrito redireccionamos a orden
      if (priceTotal !== null && location.state === "RutaDePago") {
          setDatoPago(priceTotal);
          } else {
              navigate('/Orden');
          }
      }, [priceTotal, navigate]);

    useEffect(()=>{ // hacemos la suma del precio total + el costo de envio 
      setTotalFull(Number(priceTotal) + Number(shippingCost));
    },[shippingCost, priceTotal])

    useEffect(() => {
      if (ErrorDir || ErrorFac) {
        const timer = setTimeout(() => {
          setErrorDir(false);
          setErrorFac(false);
        }, 3000); //
  
        return () => clearTimeout(timer);
      }
    }, [ErrorDir, ErrorFac]);

    useEffect(() => { //obtenemos si es mobil < 900 px
      const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const hancnleCheckFacturas = () => { //Validamos el check de facturas para presentar datos
      if(isFacturas){
        setisFacturas(false);
        setDataFactura("");
        setSelectedFacuracion("");
      }
      else
        setisFacturas(true);
    }

    const handleDelivery = (Address) =>{ // Buscamos el costo de envio 
      setDataAddress(Address);
      setSelectedDireccion(Address.id);
      setLoading(true);

      const Data = {
        zip_to : Address.postal_code
      }

      axios
    .post(process.env.REACT_APP_API_LINUM + '/api/quotations', Data, {
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 20000 // Establece el tiempo de espera en milisegundos
    })
    .then((response) => {
      const cost = response.data.amount_local;
      setShippingCost(cost);

      const newDate = new Date(EstimatedDate);
      newDate.setDate(newDate.getDate() + response.data?.days);
      setEstimatedDate(newDate);
      setLoading(false);
      setWaitShipping(false);
      setErrDelivery(false);
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.error('La solicitud fue cancelada debido al timeout:', error);
        setErrDelivery(true)
      } else {
        console.error('Error al cotizar el envío:', error);
        setErrDelivery(true)
      }
      setLoading(false);
      setShippingCost("");
      setWaitShipping(false);
    });

      
    }

    const handleFactura = (Facturacion) =>{ //Manejamos informacion de factura
      setDataFactura(Facturacion);
      setSelectedFacuracion(Facturacion.id);
    }

    const handleMetodo = (metodo) =>{ // selecionamos el metodo de pago
      setMetodo(metodo);
    }

    const FechaEstimadaFormato = (fecha) => {
      const dia = fecha.getDate();
      const mes = fecha.toLocaleString('default', { month: 'long' }); // 'long' para obtener el nombre completo del mes
      const fechaFormateada = `${dia} de ${mes}`;
      return fechaFormateada;
    }

    const HanldleValida = (event) =>{ // validamos y si todo esta ok redirecionamos a checkout
      event.preventDefault();
      setErrorDir(false);
      setErrorFac(false);


      if(!DataAddress)
      {
        setErrorDir(true);
        return;
      }

      if(loading){
        setWaitShipping(true);
        return
      }

      if(ErrDelivery){
        setErrDelivery(true);
        return
      }

      if((isFacturas && !DataFactura))
      {
        setErrorFac(true);
        return
      }

      //Formato de fecha estimada de entrega.
    

      const params = {
        ruta : "RutaDePago",
        shippingCost: shippingCost,
        PrecioTotal : TotalF,
        Delivery:DataAddress,
        Invoice:DataFactura,
        EstimatedDate: FechaEstimadaFormato(EstimatedDate)
      }

      navigate('/CheckOut', { state: params });
    }

    const OpenModal = () => { //Abrimos modal para agregar direccion de envio
        setShowModal(true);
        setComponente("Delivery");
    };

    const OpenModalFactura = () =>{ //abdimos modal para agregar datos de facturacion
        setShowModal(true);
        setComponente("Bill");
    }

    const closeModal = () => {
      setShowModal(false);
  };

    const PriceProduct = (price,cant) => { // retornamos precio de producto x la cantidad 
        const precio = parseFloat(price.replace('$', ''));
        const PricePr = cant * precio;
        return PricePr.toFixed(2);
    }

    const handleRegistroAgregado = (newAddress) => {  //Manejador para saber si se agrego un nuevo registro
      if(newAddress)
        SetNewAddress(true);
      closeModal();
    };

    const MostrarDivDireccionMobile = () =>{
      if(!DivDireccionMobile)
        setDivDireccionMobile(true);
      else
        setDivDireccionMobile(false);
    }
    const MostrarDivMetodoMobile = () =>{
      if(!DivMetodoMobile)
        setDivMetodoMobile(true);
      else
        setDivMetodoMobile(false);
    }
    const ElegirOtraDireccion =() =>
    {
      setDataAddress("");
      setSelectedDireccion("");
    }


    return (
      <div className="wrapperDelivery">
        <div className="contetnDelivery">
          <div className="rutaOrden">
            <Link to="/Orden" className='Link'> Carrito </Link>  
            <img src="/img/producto/arrow.svg" alt=""/> 
            <div className="t2">Delivery</div> 
          </div>
          <h1 className='titulo'>Dirección de entrega</h1>
          <div className="rutaDelivery">
              <Link to={{
                      pathname: "/Orden", 
                      state: "RutaDePago",
                      }} 
                className='Link'> 
                <div className="itemRutaPago Active InactiveMobile">
                <p className='circle'>1</p><h1>Carrito</h1>
                </div>
              </Link>
              <div className="itemRutaPago Active">
              <p className='circle circleActive'>2</p><h1>Datos de entrega</h1>
              </div>
              <div className="itemRutaPago InactiveMobile">
              <p className='circle'>3</p><h1>Metodo de pago</h1>
              </div>
              <div className="itemRutaPago InactiveMobile">
              <p className='circle'>4</p><h1>Resumen</h1>
              </div>
          </div>
          <div className="contentInfo">
            <div className="left">
                <div className="title">
                  <p>Selecciona una dirección de envio</p>
                  <hr/>
                </div>

                {!DataAddress &&
                <div className="SeleccionMobile">
                    <Link onClick={MostrarDivDireccionMobile} className='MostarDivLink'>
                      Selecciona dirección de entrega <DoubleArrowIcon/>
                    </Link>
                  </div>
                }

                <div className={DivDireccionMobile || DataAddress ? 'direcciones mostrar' : 'direcciones'}>
                  {isMobile ?
                  <>
                      {!DataAddress ?
                      <>
                        {DivDireccionMobile && 
                          <>
                            {dataCreditCard.map((Address,index)=>(
                              <AddressCard
                                Adrs = {Address} 
                                selected = {selectedDireccion} 
                                onClick={() => handleDelivery(Address)}
                                />
                            ))}
                            <div className="AddAddress">
                              <Link onClick={OpenModal} 
                              data-toggle="modal" 
                              className='Link'>
                                <div className="txtlink">
                                  <AddIcon/>
                                  Agregar Dirección
                                  </div>
                              </Link>
                            </div>
                          
                          </>
                        }
                      </>:
                      <div className="DireccionSeleccionada">
                        <div className="titleDireccion">
                          Dirección de entrega seleccionada
                        </div>
                        <div className="ItemsDireccion">
                          <div className='item'>{DataAddress.contact_name}</div>
                          <div className='item'>{DataAddress.address}</div>
                          <div className='item'>{DataAddress.city} , {DataAddress.postal_code}</div>
                        </div>
                        <Link onClick={ElegirOtraDireccion} className='MostarDivLink'>
                            Elegir otra dirección <DoubleArrowIcon/>
                        </Link>
                        
                      </div>
                      }
                  </>
                  :
                  <>
                    {dataCreditCard.map((Address,index)=>(
                        <AddressCard
                          Adrs = {Address} 
                          selected = {selectedDireccion} 
                          onClick={() => handleDelivery(Address)}
                          />
                      ))}

                    <div className="AddAddress">
                      <Link onClick={OpenModal} 
                      data-toggle="modal" 
                      className='Link'>
                        <div className="txtlink">Agregar Dirección</div>
                      </Link>
                    </div>
                  </>
                  }
                </div>
                
                  {/*
                <div className="title">
                  <p>Selecciona una dirección de facturación</p>
                  <hr/>
                </div>
                
                <div className="checkFacturas">
                  <input type='checkbox'
                  onClick={hancnleCheckFacturas}
                  />REQUIERO FACTURA
                </div>
                <div className={isFacturas ? 'direcciones mostrar' : 'direcciones'}>
                  {isFacturas &&
                    <>
                        {DataDirFactura.map((Facturacion,index)=>(
                          <FacturacionCard Fact = {Facturacion}
                            selected = {selectedFacuracion} 
                            onClick={() => handleFactura(Facturacion)}
                        />
                        ))}

                        <div className="AddAddress">
                        <Link onClick={OpenModalFactura} 
                          data-toggle="modal" 
                          className='Link'>
                            <div className="txtlink">
                            {isMobile&&<AddIcon/>}
                              Agregar datos de facturación
                            </div>
                          </Link>
                        </div>
                    </>
                  }
                </div>
                */}
            </div>
                

            <div className="right">
                <div className="title">
                  <p>Desglose de compra</p>
                  <hr/>
                </div>
                <div className="contentProductos">
                  <div className="TitleOrden">Productos de tu orden</div>
                  <div className="productos">
                      <div className="headersProd">
                          <div>Producto</div>
                          <div>Cantidad</div>
                          <div>Total</div>
                      </div>
                      {products.map((item) => (
                      <div className="itemproducto" key={item.id}>
                          <div className="cell">
                              <div className="imagen">
                                  <img src={item.img} alt="" />
                                  <div className="titleProd">{item.title}</div>
                              </div>
                          </div>          
                          <div className="cell ">
                              {item.quantity}
                          </div>
                          <div className="cell">
                              <div className="total">
                                  <p>
                                      {formatter.format(PriceProduct(item.priceD.length > 0 ? item.priceD : item.price,item.quantity))}
                                  </p>
                              </div>
                          </div>
                          
                      </div>
                      ))}
                  </div>
                </div>
                <div className="totalPrecio">
                    <div className="TotalEnvio">
                          <div className='title'>ENVÍO A DOMICILIO: </div>
                            {loading ? 
                              <DotLoader
                                color={"#000000"}
                                loading={loading}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                />:
                                <div className='precio'>
                                  {shippingCost ?
                                  formatter.format(shippingCost)
                                  :
                                  !ErrDelivery ?
                                  <div class="msjEnvio">Selecciona una dirección</div>:
                                  <>
                                  <div class="msjEnvio">
                                  Reintentar
                                  <AutorenewIcon onClick={()=>handleDelivery(DataAddress)} />
                                  </div>
                                  </>
                                  }
                                </div>
                            }
                      </div>
                    <div className="TotalEnvio">
                      <div className='title'>ENTREGA ESTIMADA: </div>
                      {loading ? 
                          <DotLoader
                            color={"#000000"}
                            loading={loading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            />:
                            <div className='precio letra'>
                              {shippingCost ?
                               FechaEstimadaFormato(EstimatedDate)
                              :
                              !ErrDelivery ?
                              <div class="msjEnvio">Selecciona una dirección</div>:
                              <>
                              <div class="msjEnvio">
                              Reintentar
                              <AutorenewIcon onClick={()=>handleDelivery(DataAddress)} />
                              </div>
                              </>
                              }
                            </div>
                          }
                      </div>
                    <div className="TotalFull">
                        <div className='totalName'>TOTAL DE COMPRA:</div>
                        {WaitShipping ? 
                        <div className="WaitShipping">
                          Estamos obteniendo tu costo de envio, espera un momento...
                        </div>
                        :
                        <div className='totalprice'>{formatter.format(TotalF)}</div>
                        }
                        
                    </div>
                </div>
                <div className="MsjeError">
                  {ErrorDir && "Selecciona una direccion de entrega"}
                  {isFacturas && ErrorFac && "Selecciona los datos de facturación"}
                  {ErrMetodo && "Selecciona un metodo de pago"}
                </div>
                <div className="continuar">
                  <Link onClick={HanldleValida} className='button'> Continuar</Link>
                </div>
            </div>
            
          </div>
                {/* Renderiza el modal */}
                <AddRegistroModal 
                isOpen={showModal} 
                onClose={closeModal} 
                onRegistroAgregado={handleRegistroAgregado}
                comp = {Componente}/>

        </div>
      </div>
    )
}

export default Delivery
import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from "axios";
import Modal from 'react-modal';
import FormFactura from '../../Facturacion/FormFactura/FormFactura';

const CompFactura = (props) => {
    const navigate = useNavigate();
    const storedData = localStorage.getItem("SessionUser");
    const state = localStorage.getItem("Logged");
    const SessionUser = JSON.parse(storedData);
    const [IsReFactura,setIsReFactura] = useState(false);
    const [IsloadingRFactura,setIsloadingRFactura] = useState(false);
    const [ErrRefactura,setErrRefactura] =useState(false);
    const [isSendFactura,setIsSendFactura] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [IsFacturacion, SetFacturacion] = useState(false);
    const [IsResFacturaOK, setIsResFacturaOK] = useState(false);
    const [IsResFacturaERR, setIsResFacturaERR] = useState(false);
    const [DataFactura, SetDataFactura] = useState("");
    const [EmailFactura,setEmailFactura] = useState("");
    const [OptionsRegimen,SetOptionsRegimen] = useState([]);
    const [OptionsUsoCFDI,SetOptionsUsoCFDI] = useState([]);
    const [newAddress, SetNewAddress] = useState(false);
    

    useEffect(() => { // Obtener datos de facturacion
        const fetchData = () => {
        axios
            .get(process.env.REACT_APP_API_LINUM + '/api/invoice_data', {
                headers: {
                    'Authorization': 'Bearer ' + SessionUser.access_token
                }
            })
            .then((response) => {
                SetDataFactura(response.data.invoice_data[0]);
            })
            .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
                console.error('Error delivery_points:', error);
            });
        };
        fetchData();
        }, [newAddress]);

    const handleReFacturar = (event) =>{
        setIsloadingRFactura(true);
        
        event.preventDefault();
        

        const Order = {
            order : props?.Pedidos?.id
        }
        axios
          .post(process.env.REACT_APP_API_LINUM + '/api/send_invoice', Order, {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
             //console.log(response);
             setIsSendFactura(true);
             
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error send_invoice refactura:', error);
              setErrRefactura(true);
          });
          
          setIsloadingRFactura(false);   
    }

    const handleResponseFactura = (event) =>{
        HandleGenerarFactura(event);
    }

    const HandleGenerarFactura = (event) =>{
        event.preventDefault();
        setLoading(true);
        const dataFactura = {
            order : props.Pedidos.id
        }

        axios
        .post(process.env.REACT_APP_API_LINUM + '/api/facturacion', dataFactura , {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            setIsResFacturaOK(true);
            setEmailFactura(response.data.email)
            props.onCreateFactura(true);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/login")
          }else
            console.error('Error orders:', error);
            setIsResFacturaERR(true);
        });
        SetFacturacion(true);
        setLoading(false);
    }

    const handleRegistroAgregado = () => {  //Manejador para saber si se agrego un nuevo registro
        SetNewAddress(true);
      }

    useEffect(() => { // Obtenemos listado json de UsoCFDI
        fetch('json/Facturas/DataUsoCFDI.json')
            .then(response => response.json())
            .then(data => {
                SetOptionsUsoCFDI(data);
                ////console.log(data);
            })
            .catch(error => console.error('Error al cargar el archivo DataUsoCFDI JSON:', error));
        }, []);

    useEffect(() => { //Obtenemos listado json del regimen fiscal 
        fetch('json/Facturas/DataRegimen.json')
            .then(response => response.json())
            .then(data => {
                SetOptionsRegimen(data);
                ////console.log(data);
            })
            .catch(error => console.error('Error al cargar el archivo DataRegimen JSON:', error));
    }, []);

    const closeModal = () =>
    {
        props?.CloseModal(true);
        SetFacturacion(false);
        setIsSendFactura(false);
    }


    const UsoCFDI = OptionsUsoCFDI.find(option => option.Value === DataFactura?.invoice_use);
    const Regimen = OptionsRegimen.find(option => option.Value === DataFactura?.tax_regime);


  return (
    <>
        {props?.IsReFactura && !IsFacturacion?
            <>
                {IsloadingRFactura ? 
                <>
                    <div className="generandoFactura">
                        Generando Factura ...
                    </div>:
                </>:
                <>
                    {isSendFactura ?
                        <div className="SndFacuraOk">
                            <p className='txt'>La factura ha sido re enviada con éxito al correo registrado {EmailFactura}</p>
                            <Link onClick={closeModal} className='btn'>Cerrar</Link>
                        </div>:
                        <div className="refactura">
                            <p className='txt'>Este pedido ya ha sido facturado, ¿Desea recuperar su factura? :</p>
                            <div className="btns">
                                <Link onClick={handleReFacturar} className='btn'>Confirmar</Link>
                                <Link onClick={closeModal} className='btn'>Cerrar</Link>
                            </div>
                            {ErrRefactura && "Ocurrio un error intente mas tarde"}
                        </div>
                    }
                </>
                }
            </>
            :
            <>                
                {Loading ? 
                    <div className="generandoFactura">
                        Generando Factura ...
                    </div>:
                <>
                    {!IsResFacturaERR ?
                    <>
                        {!IsResFacturaOK ?
                            <>
                            {DataFactura ?
                                <>
                                    <p className='txt'>Estas a punto de generar tu factura del pedido #{props.Pedidos?.id}, confirma que los siguientes datos sean correctos:</p>
                                    
                                    <div className="DataFactura">
                                        <div className="item">
                                            <div className="lable">Razon social:</div> 
                                            <div className="text">{DataFactura?.name} </div>
                                        </div>
                                        <div className="item">
                                            <div className="lable">RFC: </div>
                                            <div className="text"> {DataFactura?.tax_id}</div>
                                        </div>
                                        <div className="item">
                                            <div className="lable">Regimen fiscal: </div>
                                            <div className="text">{Regimen?.Name} </div>
                                        </div>
                                        <div className="item">
                                            <div className="lable">Uso CFDI: </div>
                                            <div className="text">{UsoCFDI?.Name} </div>
                                        </div>
                                        <div className="item">
                                            <div className="lable">CP: </div>
                                            <div className="text">{DataFactura?.cp}</div>
                                        </div>
                                    </div>
                                    <div className="btns">
                                        <Link onClick={handleResponseFactura} className='btn'>Confirmar</Link>
                                        <Link onClick={closeModal} className='btn'>Cerrar</Link>
                                    </div>
                                    <div className="re-facturar">
                                        <p>Nota: Una vez generada la factura no será posible re-facturar</p>
                                    </div>
                                </>:
                                <>
                                    <div className="txtNuevaFactura">
                                        No cuentas con datos de facturación, agrega tus datos en el siguiente formulario
                                    </div>
                                    
                                    <FormFactura
                                    onRegistroAgregado = {handleRegistroAgregado}
                                    />
                                </>
                            }
                            </>:
                            <>
                                <p className='txt'>La factura ha sido generada con éxito y enviada al correo</p>
                                <Link onClick={closeModal} className='btn'>Cerrar</Link>
                            </>
                        }
                    </>:
                    <>
                        <p className='txt'>Ocurrió un error en la operación , por favor revisa tus datos de facturación e intenta nuevamente</p>
                        <p>Si el problema persiste contactanos en administracion@linum.mx</p>
                        <Link onClick={closeModal} className='btn'>Cerrar</Link>
                    </>
                    }  
                </> 
                }
            </>
            }    
    </>
  )
}

export default CompFactura
import React,{useEffect, useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import "./DetallePedido.scss"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from "axios";
import Modal from 'react-modal';
import "../modalResponse.scss";
import ClearIcon from '@mui/icons-material/Clear';
import CompFactura from '../Actions/CompFactura';
import CompTracking from '../Actions/CompTracking';
import CompResumen from '../../../Cart/Resumen/CompResumen';

const DetallePedido = (props) => {
  const detalle = props.Detalle;
  const navigate = useNavigate();
  const storedData = localStorage.getItem("SessionUser");
  const SessionUser = JSON.parse(storedData);
  const [imageUrls, setImageUrls] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [modalSegPedidoIsOpen,SetmodalSegPedidoIsOpen] = useState(false);
  const [DataOrderID,setDataOrderID] = useState([]);

  const envioItem = detalle.products.find(item => item.name === "Envio");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

      axios
      .get(process.env.REACT_APP_API_LINUM + '/api/get_cart_shopping/' + detalle.id ,{
        headers: {
            'Authorization': 'Bearer ' + SessionUser.access_token
        }
      })     
      .then((response) => {
          const root = response.data.carrito.data;
          const DataP = {
            SubTotal : root.SubTotal,
            ShippingCost : root.Envio,
            Total : root.Total
          }
          const DataOrderFiltred = {
              Metodo : root.Metodo_Pago?.Banco? "spei":"tarjeta",
              MetodoPago : root.Metodo_Pago,
              DataPrecio:DataP,
              DataDeivery: root.Datos_Envio,
              products: root.Productos,
              EstimatedDate : root.Fecha_Estimada
          }

          setDataOrderID(DataOrderFiltred);
      })
      .catch((error) => {
          console.error('Error get_cart_shopping orders:', error);
      });

  }, [detalle.id])

  useEffect(() => {
    const loadImages = async () => {
      const urls = [];
      for (const pedido of props.Detalle.products) {
        if(pedido.id ){
          try {
            const response = await axios.get(
              process.env.REACT_APP_API_URL + 'product-catalogues/' + pedido.id + '?populate=*'
            );
            const DataFull = response.data.data;
            const urlImagen = DataFull.attributes.productImages.data[0].attributes.formats.thumbnail.url;
            const imgThumbnail = process.env.REACT_APP_UPLOAD_URL + urlImagen;
            urls.push(imgThumbnail);
          } catch (error) {
            if (error.response?.status === 401) {
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/login")
            } else {
              console.error('Error strapi Product-catalogues:', error);
            }
          }
        }
      }
      setImageUrls(urls);
    };

    loadImages();
  }, [props.Detalle.products]); // Ejecutar cuando props.Pedidos.products cambia


  const handlestatus = (status) =>{
      switch (status){
          case "Nuevo":
              return "Procesado Pedido";
          case "En Despacho":
              return "Procesando Pedido"
          case "En Transito":
              return "En transito"
          case "Entregado":
              return "Entregado"
          case "Cancelado":
              return "Cancelado"
          default :
              return "Procesado Pedido"
      }
      
  }

  const HandleReturn = () =>{
    props.onReturn(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
    SetmodalSegPedidoIsOpen(false);
  };
  const openModal = () => {
      setModalIsOpen(true);
  };

  const openModalSegPedido = (event) =>{
      SetmodalSegPedidoIsOpen(true);
  }

  const customModalStylesPedido = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '80%' : '50%', 
        height: '80%',
        background: 'white'
      }
};

  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '80%' : '50%', 
      height: '60%',
      background: 'white'
    }
  };

  return (
    <>
    <div className="contentDetallePedido">
        <div className="regresar">
          <Link onClick={HandleReturn} className='Link'><ArrowBackIosNewIcon/></Link> 
        </div>


        <div className="Pedido"> 
          {/*<div className="noPedido">Pedido No. {detalle.id}</div>*/}
          
          <div className="SeguirEnvio">
            {(detalle.status != 'Nuevo' && detalle.status != 'En Despacho' && detalle.status != 'Cancelado') ? 
              <>
                <div className="btn Cursor">
                    <Link 
                    className='Link' 
                  onClick={openModalSegPedido}>
                        SEGUIR PEDIDO
                    </Link>
                </div>
                  <div className="btn Cursor">
                    <Link 
                        className='Link' 
                        onClick={openModal}>
                          {props.Detalle.invoiced === "SI" ?
                            "FACTURADO":"FACTURAR"
                          }
                      </Link>
                  </div>
              </>:
              <>
                  <div className="btn inactivo">
                        SEGUIR PEDIDO
                  </div>
                  <div className="btn inactivo">
                            FACTURAR
                  </div>
              </>
              }
            
            {detalle.status === "Cancelado" &&
            <div className="statusPedido">
              <div className="cancelado">
                {handlestatus(detalle.status)}
              </div>
            </div>}

          </div>
          
        </div>

        {/*
        <div className="infoPedido">
            <div className="statusPedido">
              <h1>Estatus:</h1>
                {handlestatus(detalle.status)}
            </div>
            <div className="direccionEntrega">
              <h1>Direccion de entrega:</h1>
              <p>{detalle.delivery_point.contact_name}</p>
              {detalle.delivery_point.address}<br/>
            </div>
            <div className="FechaMetodo">
                <div className="fecha">
                <h1>Fecha del pedido:</h1>
                  {detalle.order_date}
                </div>
                <div className="metodoPago">
                <h1>Metodo de pago:</h1>
                  {detalle.payment_method}
                </div>
            </div>
            <div className="Totales">
                <h1>Resumen del pedido:</h1>
                <div className="resumenPedido">
                  <p>Productos: ${(detalle.total - envioItem.price).toFixed(2)}</p>
                  <p>Envio: ${envioItem.price.toFixed(2)}</p>
                  <p>TOTAL: ${detalle.total.toFixed(2)}</p>
                </div>
                <div className="totalPedido"></div>
            </div>
        </div>

        <div className="titleprod"><h1>Productos:</h1></div>
        <div className="productos">
          
          <div className="ProductImages">
                <div className="imagenPedido">
                        {imageUrls.map((imageUrl, index) => (
                            <div className="imagenPedido" key={index}>
                              <img src={imageUrl} alt="" />
                            </div>
                        ))}
                </div>
                <div className="itemPedido">
                {detalle.products
                  ?.filter(pedido => pedido.name !== "Envio")
                  .map((pedido, index) => (
                    <div className="items" key={index}>
                      <div className="titleProdPedido">
                        {pedido.name} <br />
                        cantidad {pedido.quantity}
                      </div>
                    </div>
                  ))}
                </div>
          </div>
        </div>
               */} 
        <div className="Resumen">
          {DataOrderID && <CompResumen data={DataOrderID} IDSeg = {detalle.id}/>}
        </div>   
              

    </div>

    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style = {customModalStyles}
    >
            <div className="modal-header">
                <p className='title'>Generación de factura</p>
                <Link onClick={closeModal} className='Link'><ClearIcon/></Link>
            </div>
            <div className="modal-body">

            <CompFactura 
                IsReFactura = {props.Detalle.invoiced === "SI" && true}
                CloseModal = {closeModal}
                Pedidos = {props.Detalle}
                />
            </div>
    </Modal>

    
    <Modal
        isOpen={modalSegPedidoIsOpen}
        onRequestClose={closeModal}
        style = {customModalStylesPedido}
    >
            <div className="modal-header">
                <p className='title'>Seguimiento de pedido</p>
                <Link onClick={closeModal} className='Link'><ClearIcon/></Link>
            </div>
            <div className="modal-body">
                  <CompTracking 
                    Detalle = {props.Detalle}/>
            </div>
    </Modal>

    </>
  )
}

export default DetallePedido
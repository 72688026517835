import React, { useState, useRef, useEffect } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import "./Navbar.scss";
import CartComp from '../Cart/CartComp';
import { useSelector } from 'react-redux';
import DropdownMenu from './MenuMobile';
import '../Navbar/MenuMobile.scss';
import { useLocation } from 'react-router-dom';
import axios from 'axios';


const Navbar = () => {
  const [open,SetOpen] = useState(false)
  const products = useSelector(state=>state.cart.products);
  const previousDataRef = useRef(products);
  const componentRef = useRef(null);
  const location = useLocation();
  const [OnDelete,setOnDelete] = useState(false);
  const [SocialMedia,SetSocialMedia] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!OnDelete && open && componentRef.current && !componentRef.current.contains(event.target)) {
        // Si se hace clic fuera del componente, llama a la función onClose para cerrarlo.
        SetOpen(false);
        setOnDelete(false);
        document.body.classList.remove('body-no-scroll');
      }
    }

    // Agrega un event listener para el clic en el documento.
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Limpia el event listener cuando el componente se desmonta.
      document.removeEventListener('click', handleClickOutside);
      setOnDelete(false);
    };
  }, [open,OnDelete]);

  useEffect(() => {
    const fetchData = async () => {
        const catalogoData = await axios.get(process.env.REACT_APP_API_URL + 'top-ribbon?populate=*');
        const DataFull = catalogoData.data.data;
        SetSocialMedia(DataFull);   
      };
      fetchData();
    }, []);

  useEffect(()=>{
    if (products !== previousDataRef.current && (location.pathname !== "/Orden" && location.pathname !== "/Pay") ) {
      document.body.classList.add('body-no-scroll');
      SetOpen(true)
    }
  }, [products])

  const cerrarC = (cerrar) => {
    if(cerrar){
      SetOpen(false);
      setOnDelete(false);
      document.body.classList.remove('body-no-scroll');
    }
  };

  const openCart = () =>{
    document.body.classList.add('body-no-scroll');
    SetOpen(true)
  }

  const HandleOnDelete = (del) => {
    if(del){
      setOnDelete(true)  
    }
  }

  return (
    <div className='navbar' ref={componentRef} >

        <div className="WrapperTop">
          <div className="ContenedorTop">
            <div className='top'>
              <div className="itemTop">
                <div className='left'>
                  <div className="fb">
                    <a href = {SocialMedia.attributes?.SocialMedia[0]?.SocialMediaLink} 
                    className='Link' target='blank'>
                      <FacebookIcon/>
                    </a>
                  </div>
                  <div className="ig">
                  <a href = {SocialMedia.attributes?.SocialMedia[1]?.SocialMediaLink} 
                    className='Link' target='blank'>
                      <InstagramIcon/>
                  </a>
                  </div>
                </div>
              </div>
              <div className="itemTop">
                <div className="center">
                  <span>{SocialMedia.attributes?.Ribbon_Text}</span>
                </div>
              </div>
              <div className="itemTop">
                <div className="right">
                  <div className="item">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WrapperMid">
          <div className="ContenedorMid">
                <div className='midMobile'>
                  <div className="midTop">
                    <div className="itemMidMenu">
                        <DropdownMenu/>
                    </div>
                    <div className="itemMidLogo">
                      <div className="logo">
                        <Link to="/">
                          <img src='/img/linum.jpg' alt='linum'/>
                        </Link>
                      </div>
                    </div>
                    <div className="itemMidCarrito">
                      <div className="carrito">
                        <div className="item">
                          <Link to="/Login" className='link'><PersonIcon/></Link>
                        </div>
                        <div className="carticon" onClick={openCart}>
                          <Link className='Link'><ShoppingCartIcon/></Link>
                          <span>{products.length}</span>
                        </div>
                      </div>
                      <div className={open ? "cart-open": ""}>
                        {isMobile && 
                        <CartComp
                          isOpen = {open}
                          cerrarC = {cerrarC}
                          OnDelete = {HandleOnDelete}
                        />
                        }
                      </div>
                    </div>
                  </div>
                  {/*<div className="itemMidSearch">
                    <div className="search">
                      <input
                      type='text'
                      placeloader = 'buscar'
                      className='textfield'
                      name='busqueda'
                      />
                      <button type='button' className='btnBuscar'>
                        <SearchIcon/>
                      </button>
                    </div>
                    </div>*/}
              </div>

              <div className='mid'>
                  <div className="itemMid">
                    {/*<div className="search">
                      <input
                      type='text'
                      placeloader = 'buscar'
                      className='textfield'
                      name='busqueda'
                      />
                      <button type='button' className='btnBuscar'>
                        <SearchIcon/>
                      </button>
                    </div>
                    */}
                  </div>
                  <div className="itemMid">
                    <div className="logo">
                      <Link to="/">
                        <img src='/img/linum.jpg' alt='linum'/>
                      </Link>
                    </div>
                  </div>
                  <div className="itemMid">
                    <div className="carrito">
                      <div className="item">
                        <Link to="/Login" className='link'><PersonIcon/></Link>
                      </div>
                      <div className="carticon" onClick={openCart}>
                        <ShoppingCartIcon/>
                        <span>{products.length}</span>
                      </div>
                    </div>
                    <div className={open ? "cart-open": ""}>
                    {!isMobile &&
                    <CartComp
                      isOpen = {open}
                      cerrarC = {cerrarC}
                      OnDelete = {HandleOnDelete}
                    />
                    }
                      </div>
                  </div>
                </div>
          </div>
        </div>
        <div className="WrapperBottom">
          <div className="ContenedorBottom">
          <div className="bottom">
              <div className="menu">
                <div className="item">
                  <Link className='Link' to="/Inicio">Inicio</Link>
                </div>
                <div className="item">
                  <Link className='Link' to="/Nosotros">Nosotros</Link>
                </div>
                <div className="item">
                  <Link className='Link' to="/Catalogo">Cátalogo</Link>
                </div>
                <div className="item">
                  <Link className='Link' to="/Ofertas">Ofertas</Link>
                </div>
                <div className="item">
                  <Link className='Link' to="/Contacto">Contacto</Link>
                </div>
              </div>
          </div>
          </div>
          </div>
            
          

      </div>
  )
}

export default Navbar
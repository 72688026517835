import React,{useEffect, useState} from 'react'
import {Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CardTC from '../../Cart/CheckOut/CardTC';
import "../MetodosPago/CompMetodos.scss";
import AddTC from './AddTarjeta/AddTC';


const CompMetodos = () => {
    const [isAddAdress , setisAddAdress] = useState(false);
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const navigate = useNavigate();
    const [DeleteCardTC,SetDeleteCardTC] = useState(false);
    const [newCardTC, SetNewCardTC] = useState(false);
    const [dataCreditCard , setData] = useState([]);
    const [SelectedIdTC,setSelectedIdTC] = useState(null);
    const [IsEditDireccion,setIsEditDireccion] = useState(null);

useEffect(() => {
    axios
        .get(process.env.REACT_APP_API_LINUM + '/api/credit_cards', {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            setData(response.data.credit_cards);
            SetDeleteCardTC(false);
        })
        .catch((error) => {
        if(error.response?.status === 401){
            localStorage.removeItem('SessionUser');
            localStorage.removeItem('Logged');
            navigate("/login")
        }else
            console.error('Error delivery_points:', error);
        });
}, [DeleteCardTC,newCardTC]);

const handleopenAddress = () => {
    if(!isAddAdress)
        setisAddAdress(true);
    else
    {
        setisAddAdress(false);
    }
}
const handleTC = (Data) =>{ }
const handleRegistroAgregado = (newTC) => {  //Manejador para saber si se agrego un nuevo registro
    if(newTC)
      SetNewCardTC(true);
  };

  const handleRegistroEliminado = (DeleteAddress) => {  //Manejador para saber si se elimino un registro
    if(DeleteAddress)
        SetDeleteCardTC(true);
  };

  const handleCloseAddDireccion = () =>{
    setisAddAdress(false);
    //console.log(isAddAdress);
  }


  return (
    <div className="wrapperMetodoPago">

        <div className="tituloMetodoPago">Métodos de pago</div>
       
            {!isAddAdress ?
                 <div className="ContentMetodoPago">
                    <div className="ContentBtn">
                        <Link className='add' onClick={handleopenAddress}>AGREGAR TARJETA</Link>
                    </div>
                    <div className="AccountMetodoPago">
                        {dataCreditCard.map((DataTC,index)=>(
                        <CardTC
                            Adrs = {DataTC} 
                            index = {index}
                            selected = {SelectedIdTC} 
                            onClick={() => handleTC(DataTC)}
                            onRegistroEliminado = {handleRegistroEliminado}
                            comp={"perfil"}
                        />
                        ))}
                    </div>
                </div>:
                <div className="ContentAddMetodoPago">
                    <Link className='Link' onClick={handleopenAddress}>
                            <ArrowBackIosNewIcon/>
                        </Link>
                    <div className="AddMetodoPago">
                        <AddTC
                        onRegistroAgregado = {handleRegistroAgregado} 
                        closeAddDireccion = {handleCloseAddDireccion}
                        Ref = "/Account" />
                    </div>
                </div>
            }
        </div>
    
  )
}

export default CompMetodos
import React, { useEffect, useState } from 'react'
import "../RecuperarPwd/CompRecuperarPwd.scss"
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import DotLoader from "react-spinners/ClipLoader";

const CompConfirmPwd = () => {
    const { exp } = useParams();
    const [pwd,SetPwd] = useState("");
    const [pwdConf,SetpwdConf] = useState("");
    const [isExpired,SetisExpired]= useState(false);
    const [IdCliente,setIdCliente] = useState("");
    const [ErrConfPwd,setErrConfPwd] = useState("");
    const [ErrConfirmPwd,setErrConfirmPwd] = useState("")
    const [ConfirmPwd,setConfirmPwd] = useState("");
    const [IsloadingSendPwd,setIsloadingSendPwd] = useState("");

    useEffect(() => {
      if (ErrConfPwd || ErrConfirmPwd) {
        const timer = setTimeout(() => {
          setErrConfPwd("");
        }, 4000); //
        return () => clearTimeout(timer);
      }
    }, [ErrConfPwd,ErrConfirmPwd]);

    useEffect(() => {
      const currentDate = new Date();
      const expArry = exp.split('_');

      setIdCliente(expArry[0]);

      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear().toString();
      const hour = currentDate.getHours().toString().padStart(2, '0');
      const minute = currentDate.getMinutes().toString().padStart(2, '0');
      const second = currentDate.getSeconds().toString().padStart(2, '0');

      const formattedDateTime = `${day}${month}${year}${hour}${minute}${second}`;

      SetisExpired(formattedDateTime < expArry[1]);
    }, []);
    

    const handleUpdatePwd = async (event) =>{
      event.preventDefault();

      if (pwd != pwdConf){
        setErrConfPwd(true);
        return;
      }

      setIsloadingSendPwd(true);  
    
      const Data = {
        client_id : IdCliente,
        password : pwd
      }
      await axios
        .put(process.env.REACT_APP_API_LINUM + '/api/update_user_password/', Data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          if(response.status === 200){
            setConfirmPwd(true);
          }else{
            setErrConfirmPwd(true);
          }
          //console.log(response);
        })
        .catch((error) => {
            console.error('Error update_user_password:', error);
            setErrConfirmPwd(true)
        });
        setIsloadingSendPwd(false);  

    }
    const handlePwd = (event) => {SetPwd(event.target.value)};
    const handleConfPwd = (event) => {SetpwdConf(event.target.value)};


  return (
    <div className="contentRecuperarPwd">
      {isExpired ?
      <>
        {!ConfirmPwd ? 
        <>
          <div className="infoRecuperarPwd">
              <div className="title">Actualizar Contraseña</div>
              <div className='txt'>Genera tu nueva contraseña</div>
          </div>
          <div className="FormCorreo">
            <form onSubmit={handleUpdatePwd}>
              <label className='label'>Contraseña nueva</label>
              <input className='input' 
              type="password" value={pwd} 
              placeholder="Debe tener al menos 8 caracteres" 
              minLength="8"
              onChange={handlePwd} 
              autoComplete="on"
              required />
              <label className='label'>Confirmar contraseña</label>
              <input className='input' 
              type="password" 
              value={pwdConf} 
              onChange={handleConfPwd} 
              placeholder="Confirmar contraseña" 
                minLength="8"
              autoComplete="on"
              required />
              <div className="ContentButton">
                {!IsloadingSendPwd ? 
                <button className="button">Actualizar</button>
                :
                <DotLoader
                  color={"#1d4c5a"}
                  loading={IsloadingSendPwd}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  />
                } 
              </div>
              </form>
              {ErrConfPwd &&
              <div className="txtError">Las contraseñas no coinciden</div>
              }
              {ErrConfirmPwd &&
              <div className="txtError">Ocurrio un error intente mas tarde</div>
              }
          </div>
        </>:
        <>
          <div className="infoRecuperarPwd">
            <div className="ConfirmPwd">
              <div className="title">Actualizar Contraseña</div>
              <div className='txt'>Se actializo con éxito tu contraseña </div>
              
              <div className="button2">
                <Link to = "/Login" className='button Link'>INICIAR SESIÓN</Link>
              </div>
            </div>
        </div>
        </>
        } 
    </>
    :<>
         <div className="infoRecuperarPwd">
            <div className="title">Actualizar Contraseña</div>
            <div className='txt'>Este link expiró</div>
        </div>
    </>
    }
</div>
  )
}

export default CompConfirmPwd
import React from 'react'
import { ComponentCinta } from '../../../../components/Datos/sn1/Sn1Cinta'
import "../sn1/Seccion1.scss"

const Seccion1 = () => {
  return (
    <ComponentCinta/>
  )
}

export default Seccion1
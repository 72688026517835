import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const CardFaq = (props) => {
    const item = props.question?.attributes;
    const limitTxt = 120;
    const [OptionReadmore,setOptionReadmore] = useState(false)

    useEffect(()=>{
      if(item?.answer.length > limitTxt)
        setOptionReadmore(true)
    },[])

    useEffect(() => {
      window.scrollTo(0, 0); // Haz que la ventana se desplace hacia la parte superior
    }, [OptionReadmore]);

    const truncateText = (text, limit) => {
        if (text.length <= limit) {
          return text;
        }
        return text.slice(0, limit) + '...';
      };

    const handleDetalleFaq = () =>{
        props.OnReadMore(item);
    }
  return (
    <div className={!props.color ? "cardFaqs": "cardFaqs color"}>
        <div className="Faq">
            <div className="titleCard">{item?.question}</div>
            <div className="txt">{truncateText(item?.answer, 120)}</div>
            {OptionReadmore && 
              <div className="readmore" >
                <Link onClick={handleDetalleFaq} className='Link'>
                  <div className="txtreadm">Ver más</div>
                </Link>
              </div>
            }
        </div>
    </div>
  )
}

export default CardFaq
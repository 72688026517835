import React,{useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import axios from "axios";

const CompTracking = (props) => {
    const navigate = useNavigate();
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const Detalle = props?.Detalle;
    const [DataTracking,SetDataTracking] = useState("");
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    useEffect(() => {
        const Order = {
            order : Detalle.id
        }
        axios
            .post(process.env.REACT_APP_API_LINUM + '/api/tracking', Order, {
                headers: {
                    'Authorization': 'Bearer ' + SessionUser.access_token
                }
            })
            .then((response) => {
                const Datafull = response.data.data.trackings[0];
                SetDataTracking(Datafull);
            })
            .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
                console.error('Error delivery_points:', error.response?.status);
            });
    }, []);

    const HandleDate = (date) =>{
        const fechaOriginal = new Date(date);
        const fechaFormateada = format(fechaOriginal, 'yyyy-MM-dd');
        return fechaFormateada;
    }
    const HandleHour = (date) =>{
        const fechaOriginal = new Date(date);
        const fechaFormateada = format(fechaOriginal, 'HH:mm');
        return fechaFormateada;
    }

  return (
    <div className="Seguimiento">
        <div className="infoSeguimiento">
            <div className="item">
                <div className="Title">Carrier:</div> 
                <div className="desc">{DataTracking.carrier}</div>
            </div>
            <div className="item">
                <div className="Title">{isMobile ? "#":"Numero de"} Guía:</div> 
                <div className="desc">{DataTracking.tracking_number}</div>
            </div>
            
            <div className="item">
                <div className="Title">Fecha:</div> 
                <div className="desc">{DataTracking?.created_at}</div>
            </div>
            <div className="item">
                <div className="Title">Estatus:</div> 
                <div className="desc">{DataTracking.status}</div>
            </div>

            {DataTracking.signed_by &&
            <div className="item">
                <div className="desc">{DataTracking.signed_by}</div>
            </div>
            }                   
        </div>

        <div className="ContetEventos">
            {!isMobile &&
            <div className="Eventos title">
                <div className="item">Dia</div>
                <div className="item">Hora</div>
                <div className="item">Descripcion</div>
                <div className="item">Ubicacion</div>
                <div className="item">Estatus</div>
            </div>
            }
            
            {DataTracking.events?.map((Tracking, index) => (
            
                <div className="Eventos" key={index}>
                    <div className="item">{HandleDate(Tracking?.date)}</div>
                    <div className="item">{HandleHour(Tracking?.date)}</div>
                    <div className="item">{Tracking.description}</div>
                    <div className="item">{Tracking.location}</div>
                    <div className="item">{Tracking.status}</div>
                </div>
            ))}

        </div>
    </div>
  )
}

export default CompTracking
import React,{useEffect, useState} from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import '../Pedido/pedido.scss'

const Pedido = () => {
    const [pedido, setPedido] = useState("");
    const [valida, setvalida] = useState(false);
    const PedidoPending = localStorage.getItem("InfoPay-Pending");
    const navigate = useNavigate();

    // si se tiene un pedido pendiente en localstorage se envia a "/pay" para realizar el pedido
    useEffect(()=>{
      if (PedidoPending && PedidoPending.length > 0 ) {
        navigate('/pay', { state: "PedidoPending" });
      }
    },[PedidoPending,navigate])
    
    
    useEffect(() => {
        if (valida) {
          const timer = setTimeout(() => {
            setvalida(false);
          }, 2500); //
    
          return () => clearTimeout(timer);
        }
      }, [valida]);

    const PedidoChangeHandler = event => setPedido(event.target.value);

    const handlePedido = ()=>{
        if(pedido.trim() === '')
        {
            setvalida(true)
            return;
        }
        else
        {
            setvalida(false);
            //console.log(pedido);
        }
        
    }

  return (
    <div className="WrapperPedido">
        <div className="contentPedido">
            <div className="titPedido"><p>Seguimiento de pedido</p></div>
            <div className="SegPedido">
                <input type="text" value={pedido} onChange={PedidoChangeHandler} className='input' />
                <Button className='button'
                onClick={handlePedido}>
                    Consultar
                </Button>
                {valida && <div className="ErrValida">Ingresa el # de pedido</div>}
             </div>
             
          </div>
      </div>
  )
}

export default Pedido
import React from 'react'

import "../Nosotros/Nosotros.scss"
import {CompNosotros,CompNosotrosBottom} from '../../components/Nosotros/CompNosotros';

const Nosotros = () => {

  return (
    <div className="wrapperNosotros">
        <div className="contenedorNosotros">
            <CompNosotros/>
        </div>
        <div className="ContenedorFeaturesNosotros">
          <CompNosotrosBottom/>
        </div>
    </div>
  )
}

export default Nosotros
import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";

const initialState = {
  products:[],
  priceTotal: 0
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state,action) => {

    const item = state.products.find((item)=> item.id === action.payload.id)

    if(item){
        item.quantity+=action.payload.quantity
    }else{
        state.products.push(action.payload)
    }
    const d ={data: {Existencias: action.payload.existencias - action.payload.quantity}}
    //console.log(d)
    try{
        axios
        .put(process.env.REACT_APP_API_URL + 'product-catalogues/' + action.payload.id, d,{
          headers: {
            'Authorization': 'Bearer 9cbd460b6cd32a3e23fa7ca849c89b0b51d93d9ca86e77307b87dacf6d691a051042c57fe1636c3d94766bde4ee96f8cb27bd7df3adbc1c58bd19ca03f63fc73a7c6c819146f51154fef2f953f52ed0a5bd1fde455b734fa0a12a82fc18e90ee221c2b8c4d17b23fb3be06d5eac2d443bb8ef01adeaf0d0da7e3d43cee78431f'
        }
        });
    } catch (error) {
      console.error(`Hubo un error al actualizar la existencia del producto con ID ${action.payload.id}:`, error);
    }
          
    },
    removeToCart: (state,action) => {

      const item = state.products.find((item)=> item.id === action.payload.id)
  
      if(item){
          item.quantity-=action.payload.quantity
      }else{
          state.products.push(action.payload)
      }

      axios
      .get(
        process.env.REACT_APP_API_URL + "product-catalogues/" + action.payload.id + "?populate=*"
      )
      .then((dataCatalogo) => {
        const dataFull = dataCatalogo.data.data.attributes;
        if (dataFull.Existencias >= 0){
         
          const d ={data: {Existencias: dataFull.Existencias + action.payload.quantity}}
          //console.log(d)
          try{
              axios
              .put(process.env.REACT_APP_API_URL + 'product-catalogues/' + action.payload.id, d,{
                headers: {
                  'Authorization': 'Bearer 9cbd460b6cd32a3e23fa7ca849c89b0b51d93d9ca86e77307b87dacf6d691a051042c57fe1636c3d94766bde4ee96f8cb27bd7df3adbc1c58bd19ca03f63fc73a7c6c819146f51154fef2f953f52ed0a5bd1fde455b734fa0a12a82fc18e90ee221c2b8c4d17b23fb3be06d5eac2d443bb8ef01adeaf0d0da7e3d43cee78431f'
              }
              });
          } catch (error) {
            console.error(`Hubo un error al actualizar la existencia del producto con ID ${action.payload.id}:`, error);
          }
        }
      })
      .catch((error) => {
        console.error("Hubo un error al obtener existencia removeToCart:", error);
      });
    },
    removeItem: (state,action) => {

      axios
      .get(
        process.env.REACT_APP_API_URL + "product-catalogues/" + action.payload.id + "?populate=*"
      )
      .then((dataCatalogo) => {
        const dataFull = dataCatalogo.data.data.attributes;
        if (dataFull.Existencias >= 0){
          const d ={data: {Existencias: dataFull.Existencias + action.payload.quantity}}
          //console.log(d)
          try{
              axios
              .put(process.env.REACT_APP_API_URL + 'product-catalogues/' + action.payload.id, d,{
                headers: {
                  'Authorization': 'Bearer 9cbd460b6cd32a3e23fa7ca849c89b0b51d93d9ca86e77307b87dacf6d691a051042c57fe1636c3d94766bde4ee96f8cb27bd7df3adbc1c58bd19ca03f63fc73a7c6c819146f51154fef2f953f52ed0a5bd1fde455b734fa0a12a82fc18e90ee221c2b8c4d17b23fb3be06d5eac2d443bb8ef01adeaf0d0da7e3d43cee78431f'
              }
              });
          } catch (error) {
            console.error(`Hubo un error al actualizar la existencia del producto con ID ${action.payload.id}:`, error);
          }
        }
      })
      .catch((error) => {
        console.error("Hubo un error al obtener existencia removeItem:", error);
      });

      
      state.products=state.products.filter(item=>item.id !== action.payload.id)

      },
    resetCart: (state,action) => {
      const products = state.products;

      if(action.payload)
      {
        // Itera sobre cada producto y realiza la petición a Strapi
        products.forEach(async (product) => {
          const { id, quantity } = product; // Ajusta esto según la estructura del producto

          await axios
          .get(
            process.env.REACT_APP_API_URL + "product-catalogues/" + id + "?populate=*"
          )
          .then(async(dataCatalogo) => {
            const dataFull = dataCatalogo.data.data.attributes;
            if (dataFull.Existencias >= 0){
              const d ={data: {Existencias:  dataFull.Existencias + quantity}}
              //console.log(d)
              try{
                  await axios
                  .put(process.env.REACT_APP_API_URL + 'product-catalogues/' + id, d,{
                    headers: {
                      'Authorization': 'Bearer 9cbd460b6cd32a3e23fa7ca849c89b0b51d93d9ca86e77307b87dacf6d691a051042c57fe1636c3d94766bde4ee96f8cb27bd7df3adbc1c58bd19ca03f63fc73a7c6c819146f51154fef2f953f52ed0a5bd1fde455b734fa0a12a82fc18e90ee221c2b8c4d17b23fb3be06d5eac2d443bb8ef01adeaf0d0da7e3d43cee78431f'
                  }
                  });
              } catch (error) {
                console.error(`Hubo un error al actualizar la existencia del producto con ID ${id}:`, error);
              }
            }
          })
          .catch((error) => {
            console.error("Hubo un error al obtener los datos product-catalogues resetCart:", error);
          });
          
        });
      }

      state.products = []
    },
    updatePriceTotal: (state, action) => {
      state.priceTotal = action.payload;
    },
    resetPriceTotal: (state) => {
      state.priceTotal = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeItem, resetCart, removeToCart, updatePriceTotal, resetPriceTotal } = cartSlice.actions

export default cartSlice.reducer;
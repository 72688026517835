import axios from 'axios';

export const fetchDataGen = async (props,encodedProps) => {
var data = null;

    if (!props.filMenu){
        const response = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?'+ encodedProps + '&populate=*&sort[0]=Existencias')
        data = response.data.data;
    }
    else
    {
        if(props.filMenu === "populares"){
            const response = await axios.get(process.env.REACT_APP_API_URL + 'popular-product?populate[product_catalogues][populate]=*');
            const DataFull = response.data.data.attributes;
            data = DataFull.product_catalogues.data.map(img => img)
        }
        else if(props.filMenu === "ofertas"){
            // const response = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?filters[discount][id][$null]=false&populate=*');
            // data = response.data.data;
            const response = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?filters[discount][$null]=false&populate=*');
            data = response.data.data;
        }
        else if(props.filMenu === "nuevas"){
            const response = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?sort=publishedAt&populate=*');
            data = response.data.data;
        }
    }

    return data;
    
};
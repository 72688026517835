import React, { useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "../CheckOut/checkOut.scss"
import RepeatIcon from '@mui/icons-material/Repeat';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {Button } from 'react-bootstrap';
import Modal from 'react-modal';
import {v4 as uuidv4} from 'uuid';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PlaceIcon from '@mui/icons-material/Place';
import axios from 'axios';
import CardTC from './CardTC';
import ModalAddTC from './ModalAddTC';



const OrdercheckOut = () => {
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const [Metodo,setMetodo] = useState("Tarjeta");
    const [ErrMetodo, setErrMetodo] = useState(false);
    const [DivMetodoMobile, setDivMetodoMobile] = useState(false);
    const [TCName, setTCName] = useState('');
    const [TCNumber, setTCNumber] = useState('');
    const [TCCvv, setTCCvv] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [is320, setis320] = useState(false);
    const [DataSelectTC, setDataSelectTC] = useState('');
    const [SelectedIdTC,setSelectedIdTC] = useState(null);
    const products = useSelector(state=>state.cart.products);
    const [DataPrecio, setDatoPago] = useState(null);
    const [DataIVA, SetDataIVA] = useState(null);
    const [shippingCost, setShippingCost] = useState(null);
    const [EstimatedDate,setEstimatedDate] = useState(null)
    const [DataDeivery, setDataDelivery] = useState(null);
    const [DataInvoice, setDataInvoice] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    const priceTotal = useSelector(state=>state.cart.priceTotal);
    const [dataTarjetas, setDataTC] = useState([]);
    const [selectedDireccion, setSelectedDireccion] = useState(null);
    const [showDivTC, setShowDiv] = useState(false);
    const [Componente , setComponente] = useState("");
    const [isCheckedTyc, setIsCheckedTyc] = useState(false);
    const [isCheckedSaveTC, setIsCheckedSaveTC] = useState(false);
    const previousDataRef = useRef(products);
    const [ErrSelectTC, setErrSelectTC] = useState(false);
    const [ErrFormulario, setErrFormulario] = useState(false);
    const [ErrTyC, setErrTyC] = useState(false);
    const [isLoading,SetisLoading] = useState(false);
    const [DataComplete , SetDataComplete] = useState(false);
    const [Spei , SetSpei] = useState(false);
    const [ErrPago , setErrPago] = useState("");
    const ivaRate = 0.16;
    const currentDate = new Date();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const optionsMes = [
        { value: "", label: "" },
        { value: "01", label: "Enero" },
        { value: "02", label: "Febrero" },
        { value: "03", label: "Marzo" },
        { value: "04", label: "Abril" },
        { value: "05", label: "Mayo" },
        { value: "06", label: "Junio" },
        { value: "07", label: "Julio" },
        { value: "08", label: "Agosto" },
        { value: "09", label: "Septiembre" },
        { value: "10", label: "Octubre" },
        { value: "11", label: "Noviembre" },
        { value: "12", label: "Diciembre" }
    ];

    const optionsAnio = [
        { value: "", label: "" },
        { value: "23", label: "2023" },
        { value: "24", label: "2024" },
        { value: "25", label: "2025" },
        { value: "26", label: "2026" },
        { value: "27", label: "2027" },
        { value: "28", label: "2028" }
    ];

  
    const [selectedMes, setselectedMes] = useState(
        optionsMes[0].value
    );
    const [selectedAnio, setselectedAnio] = useState(
        optionsAnio[0].value
    );

    
    useEffect(() => {
        if (ErrSelectTC || ErrFormulario || ErrTyC) {
          const timer = setTimeout(() => {
            setErrFormulario(false);
            setErrSelectTC(false);
            setErrTyC(false);
          }, 4000); //
    
          return () => clearTimeout(timer);
        }
      }, [ErrSelectTC,ErrFormulario,ErrTyC]);

    useEffect(() => {
        if (products !== previousDataRef.current) {
          // Se detectó un cambio en los datos
          navigate('/Orden');
          // Actualizar el estado anterior con el estado actual
          previousDataRef.current = products;
        }
      }, [products, navigate]);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
          setis320(window.innerWidth <= 320);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (location.state?.PrecioTotal !== null && location.state?.ruta === "RutaDePago"
            && location.state?.Delivery !== null) {

            setDataDelivery(location.state?.Delivery);
            setDataInvoice(location.state?.Invoice)
            setDatoPago(location.state?.PrecioTotal);
            setShippingCost(location.state?.shippingCost);
            setEstimatedDate(location.state?.EstimatedDate);
            } else {
                navigate('/Orden');
            }
        }, [priceTotal, navigate]);

    useEffect(()=>{
        axios
        .get(process.env.REACT_APP_API_LINUM + '/api/credit_cards', {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            setDataTC(response.data.credit_cards);
            if(response.data.credit_cards.length === 0)
                setShowDiv(true);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/CartAuth")
          }else
            console.error('Error delivery_points:', error);

            setShowDiv(true);
        });
    },[])

    useEffect(()=>{
        if (SelectedIdTC && !showDivTC ){
                SetDataComplete(true);
        }
        else if(showDivTC){
            if(TCName && TCNumber && TCCvv && selectedMes && selectedAnio)
                SetDataComplete(true);  
        }else
            SetDataComplete(false);

    },[showDivTC, SelectedIdTC, ErrTyC, TCName,TCNumber, TCCvv , selectedMes, selectedAnio])


    const PriceProduct = (price,cant) => { // retornamos precio de producto x la cantidad 
        const precio = parseFloat(price.replace('$', ''));
        const PricePr = cant * precio;
        return PricePr.toFixed(2);
    }
      
    const closeModal = () => {
        setModalIsOpen(false);
        SetisLoading(false);
      };
    
    
    const ElegirOtraMetodo =()=>{setMetodo("");}
     // selecionamos el metodo de pago
    const handleMetodo = (metodo) =>{
        if(metodo === "spei")
        {
            setDataSelectTC('');
            setSelectedIdTC(null);
        }
        
        setMetodo(metodo); 
    
    }

    const MostrarDivMetodoMobile = () =>{
    if(!DivMetodoMobile)
        setDivMetodoMobile(true);
    else
        setDivMetodoMobile(false);
    }
      

    const handleTCName = event => setTCName(event.target.value);
    const handleTCNumber = event => setTCNumber(event.target.value);
    const handleTCCvv = event => setTCCvv(event.target.value);
    const handleMes = event => setselectedMes(event.target.value);
    const handleAnio = event => setselectedAnio(event.target.value);
    const handleTyc = () =>{setIsCheckedTyc(!isCheckedTyc)};
    const handleSaveTC = () =>{setIsCheckedSaveTC(!isCheckedSaveTC)};

    const handleMethod = (event) =>{
        event.preventDefault();
        navigate('/Delivery', { state: "RutaDePago" });
    }
  
    const handleTC = (Data) =>{ //Manejamos informacion de factura
        setSelectedIdTC(Data[0]);
        setDataSelectTC(Data);
        setShowDiv(false);
    }

    const OpenDivTc = () => { //Abrimos modal para agregar nueva tarjeta
        if(showDivTC)
            setShowDiv(false);
        else{
            setSelectedIdTC(null);
            setDataSelectTC('');
            setShowDiv(true)
        }
        SetDataComplete(false);  
        setIsCheckedTyc(false);
    };

    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return hours + minutes + seconds;
      };
    
      const formatDate = (date) => {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return day + month + year;
      };

    const HanldleValida = (event) =>{
        event.preventDefault();

        setErrFormulario(false);
        setErrSelectTC(false);
        setErrTyC(false);

        if(Metodo === "Tarjeta")
        {
            const generateUUID = () => {
                const generatedFolio = uuidv4();
                return generatedFolio.substring(0, 11);
            };

            //validamos que seleccione una tarjeta
            if(SelectedIdTC === null && !showDivTC)
            {
                setErrSelectTC(true);
                return
            }
            // Validamos que tenga todos los datos de formulario y si tiene check en guardar datos de TC
            if(SelectedIdTC === null && (showDivTC || isCheckedSaveTC))
            {
                
                setDataSelectTC('');

                if((showDivTC) &&
                !TCName || !TCNumber || !TCCvv || !selectedMes || !selectedAnio){
                    setErrFormulario(true);
                    return
                }

                const DataTCSel = {
                    id:"",
                    credit_card_name: TCName,
                    credit_card: TCNumber,
                    fecha_expiration: selectedMes + selectedAnio,
                    security_code: TCCvv,
                    hora_local: formatTime(currentDate),
                    fecha_local: formatDate(currentDate),
                    folio: generateUUID()
                }

                dataPago = DataTCSel;
            }
            else
            {
                //Enviamos los datos de la tajeta seleccionada
                var dataPago = {
                    id: DataSelectTC[0],
                    credit_card: DataSelectTC[1],
                    fecha_expiration: "",
                    security_code: "",
                    credit_card_name: "",
                    hora_local: formatTime(currentDate),
                    fecha_local: formatDate(currentDate),
                    folio: generateUUID()
                };
            }
        }
        else{
            var sp = true;
        }
        
        if(dataPago || sp)
        {

            const DataP = {
                SubTotal : (DataPrecio-shippingCost).toFixed(2),
                ShippingCost : parseInt(shippingCost).toFixed(2),
                Total : (DataPrecio).toFixed(2),
            }
            const Data = {
                Metodo,
                DataPrecio:DataP,
                DataDeivery,
                DataInvoice,
                DataPago: dataPago,
                products,
                isCheckedSaveTC,
                EstimatedDate
            }

            const filteredData = Object.keys(Data).filter(key => {
                const value = Data[key];
                return value !== '' && value !== null;
              }).reduce((obj, key) => {
                obj[key] = Data[key];
                return obj;
              }, {});

            
              navigate("/Resumen" , {state: filteredData})
        }

        return
    }

    const CambiarDatos = ()=>{
        closeModal();
    }

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(227, 227, 227, 0.3)', // Aquí, 0.8 representa el 80% de opacidad
        },
        content: {
          overflow:"hidden",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '80%' : '25%', 
          height: isMobile ? is320 ? '40%':'30%' : '30%',
          background: 'white',
          padding:'0px',
        }
      };


    return (
        <div className="WrapperCheckOut">
            <div className="contentCheckOut">
                <div className="rutaCheckOut">
                <Link onClick={handleMethod} className='Link'> Datos de entrega </Link>  <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">Carrito</div> 
                </div>
                <h1 className='titulo'>Carrito de compras</h1>
                <div className="rutaPago">
                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>1</p>
                        <h1>Carrito</h1>
                    </div>
                    <Link onClick={handleMethod}
                    className='Link'> 
                        <div className="itemRutaPago Active InactiveMobile">
                            <p className='circle Active'>2</p><h1>Datos de entrega</h1>
                        </div>
                    </Link>
                    <div className="itemRutaPago Active">
                        <p className='circle circleActive'>3</p>
                        <h1>Metodo de pago</h1>
                    </div>
                    <div className="itemRutaPago InactiveMobile">
                        <p className='circle '>4</p>
                        <h1>Resumen</h1>
                    </div>
                </div>
                <div className="ContentInfoCkeck">
                <div className="left">
                    <div className="title">
                        <p>Método de pago</p>
                        <hr/>
                    </div>

                    {!Metodo &&
                    <div className="SeleccionMetodoMobile">
                        <Link onClick={MostrarDivMetodoMobile} className='MostarDivLink'>
                        Selecciona Método de pago <DoubleArrowIcon/>
                        </Link>
                    </div>
                    }

                    <div className={DivMetodoMobile || Metodo ? "FormaDePago mostrar" : "FormaDePago"}>
                    {isMobile ?
                        <>
                            <div className="btn" value="Tarjeta" onClick={()=>handleMetodo("Tarjeta")} >
                                <div className={Metodo === "Tarjeta" ? "btnContent Active":"btnContent"}>
                                    <div className="logoTC">
                                        <img src='/img/VisaMartercard.png' alt='linum'/>
                                    </div>
                                </div>
                            </div>
                            <div className="btn" value="spei" onClick={()=>handleMetodo("spei")} >
                                <div className={Metodo === "spei" ? "btnContent Active":"btnContent"}>
                                    <div className="logoSpei">
                                        <img src='/img/spei.png' alt='linum'/>
                                    </div>
                                </div>
                            </div>
                                
                            
                        </>:
                        <>
                            <div className="btn" value="Tarjeta" onClick={()=>handleMetodo("Tarjeta")} >
                                <div className={Metodo === "Tarjeta" ? "btnContent Active":"btnContent"}>
                                    <div className="logoTC">
                                        <img src='/img/VisaMartercard.png' alt='linum'/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="btn" value="spei" onClick={()=>handleMetodo("spei")} >
                                <div className={Metodo === "spei" ? "btnContent Active":"btnContent"}>
                                <div className="logoSpei">
                                    <img src='/img/spei.png' alt='linum'/>
                                </div>
                                </div>
                            </div>
                            
                        </>
                        }
                    </div>

                    { Metodo === "Tarjeta" ?
                        <>
                            {dataTarjetas.length > 0 && 
                            <>
                                <div className="direcciones">
                                <div className="txtSelTC">Selecciona una tarjeta</div>
                                    {
                                    dataTarjetas.map((DataTC,index)=>(
                                        <CardTC
                                        Adrs = {DataTC} 
                                        index = {index}
                                        selected = {SelectedIdTC} 
                                        onClick={() => handleTC(DataTC)}
                                        />
                                    ))}
                                    <div className="AddAddress">
                                        <Link onClick={OpenDivTc} className='Link'>
                                            <div className="txtlink">
                                                Utilizar otra tarjeta
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            }

                            {(showDivTC || dataTarjetas.length === 0) &&
                            <>
                            <div className="titlenewTC">Ingresa los datos de tu tarjeta</div>
                            
                                <div className="form-container">
                                    <div className="DataTarjeta">
                                        <div className="row">
                                            <div className="itemform form-box">
                                                <label>Alias de la tarjeta: </label>
                                                <input
                                                    type="text"
                                                    className={"form-input"}
                                                    value={TCName}
                                                    onChange={handleTCName}
                                                    required
                                                />
                                            </div>
                                            <div className="col2">
                                                <div className="itemform form-box">
                                                <label>Numero de tarjeta: </label>
                                                <input
                                                    type="text"
                                                    className={"form-input"}
                                                    value={TCNumber}
                                                    onChange={handleTCNumber}
                                                    pattern="[0-9]{16}"
                                                    title="Ingresa los 16 dígitos de tu tarjeta" 
                                                    maxLength="16"
                                                    inputMode="numeric"
                                                    required />
                                                </div>
                                                <div className="itemform form-box">
                                                <label>CVV: </label>
                                                <input
                                                    type="text"
                                                    className={"form-input"}
                                                    value={TCCvv}
                                                    onChange={handleTCCvv}
                                                    pattern="[0-9]{3,4}"
                                                    title="Ingresa los 3 o 4 dígitos al reverso de tu tarjeta" 
                                                    maxLength="4"
                                                    inputMode="numeric"
                                                    required
                                                />
                                                </div>
                                            </div>
                                            <div className="col2">

                                                <div className="itemform form-box">
                                                    <label>Mes: </label>
                                                    <select
                                                        value={selectedMes}
                                                        className="form-input"
                                                        onChange={handleMes}
                                                        required
                                                    >
                                                        {optionsMes.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="itemform form-box">
                                                    <label>Año: </label>
                                                    <select
                                                        value={selectedAnio}
                                                        className="form-input"
                                                        onChange={handleAnio}
                                                        required
                                                    >
                                                        {optionsAnio.map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="CheckTyC">
                                    <div className="Check">
                                        <input type='checkbox' 
                                        checked={isCheckedSaveTC}
                                        onClick={handleSaveTC}
                                        className ="CheckS"/>
                                        Guardar datos para futuras compras
                                    </div>
                                </div>
                            </>
                            }
                        </>
                        :
                        <>
                            <div className="DatosSpei">
                                <div className="TitleSpei">
                                    Instrucciones de pago
                                </div>
                                <div className="instruccionesSpei">
                                    <div className='PasoData'>
                                        <div className="punto">
                                            1. Registra la siguiente información de pago en tu banca electrónica
                                        </div>
                                        <div className="cuadroData">
                                            <ul>
                                                <li>Banco: Banregio</li>
                                                <li>Razón Social: LINUMCO S.A. DE C.V.</li>
                                                <li>RFC: LIN160712BK7</li>
                                                <li>Cuenta: 035033710010</li>
                                                <li>CLABE: 058580350337100109</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='Paso'>
                                        <div className="punto">
                                            2. Para garantizar tu pago, al momento de hacer la transferencia incluye el número de pedido y el
                                            total a pagar tal y como aparece en el comprobante generado.
                                        </div>
                                    </div>
                                    <div className='Paso'>
                                        <div className="punto">
                                            3. El pago se debe realizar en un lapso no mayor a 24 horas posteriores a la generación del pedido,
                                            de lo contrario esté será cancelado.
                                        </div>
                                    </div>

                                    <div className='Paso'>
                                        <div className="punto">
                                            * Podrás descargar esta información una vez completada la compra.
                                        </div>
                                    </div>


                                    
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="right">
                    <div className="title">
                        <p>Direccion de envío</p>
                        <hr/>
                    </div>
                    <div className="direcciones">
                        <div className="cardAddress selected">
                            <div className="iconCard"><PlaceIcon/></div>
                            <div className="dir">
                                <div className='item'><h1>{DataDeivery?.contact_name}</h1></div>
                                <div className='item'>{DataDeivery?.address}, {DataDeivery?.postal_code}, {DataDeivery?.city}</div>
                            </div>
                        </div>
                    </div>
                    <div className="title">
                        <p>Desglose de compra</p>
                        <hr/>
                    </div>
                    <div className="contentProductos">
                        <div className="TitleOrden">Productos de tu orden</div>
                            <div className="productos">
                                <div className="headersProd">
                                    <div>Producto</div>
                                    <div>Cantidad</div>
                                    <div>Total</div>
                                </div>
                                {products.map((item) => (
                                <div className="itemproducto" key={item.id}>
                                    <div className="cell">
                                        <div className="imagen">
                                            <img src={item.img} alt="" />
                                            <div className="titleProd">{item.title}</div>
                                        </div>
                                    </div>          
                                    <div className="cell ">
                                        {item.quantity}
                                    </div>
                                    <div className="cell">
                                        <div className="total">
                                            <p>
                                                {formatter.format(PriceProduct(item.priceD.length > 0 ? item.priceD : item.price,item.quantity))}
                                            </p>
                                        </div>
                                    </div>
                                    
                                </div>
                                ))}
                            </div>
                    </div>
                    <div className="totalPrecio">
                        <div className="TotalEnvio">
                            <div className='title'>ENVÍO A DOMICILIO: </div>
                            <div className='precio'>
                                {formatter.format(shippingCost)}
                            </div>
                        </div>
                        <div className="TotalEnvio">
                            <div className='title'>ENTREGA ESTIMADA: </div>
                            <div className='precio letra'>
                                {EstimatedDate}
                            </div>
                        </div>
                        <div className="TotalEnvio">
                            <div className='title bold'>TOTAL DE COMPRA:</div>
                            <div className='precio'>
                                {formatter.format(DataPrecio)}
                            </div>
                        </div>
                    </div>

                    <div className="MsjeError">
                    {Metodo === "Tarjeta" &&
                        ErrSelectTC && "* Selecciona una tarjeta para continuar"
                    }
                    {Metodo === "Tarjeta" &&
                        ErrFormulario && "* Por favor llena todos los datos de la tajeta"
                    }
                    {/*ErrTyC && "* Acepta los terminos y condiciones"*/}

                    </div>

                    <div className="continuar">
                        <Link onClick={HanldleValida} className={DataComplete || Metodo !== "Tarjeta" ? 'button' : 'button inactive'}> CONTINUAR </Link>
                    </div>
                </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                style = {customModalStyles}
                >
                <div className="modal-PagoErr">
                    <div className='HeaderPagoErr'>
                        {showDivTC ? 
                            "DATOS DE PAGO INCORRECTOS":
                            "PAGO NO PROCESADO"
                        }
                    </div>
                    <div className="mejerror">
                        
                        {ErrPago && 
                        
                        showDivTC ? 
                            "No ha sido posible procesar su pago, porfavor verifique que la información sea correcta e intente nuevamente":
                            "No ha sido posible procesar su pago en este momento, porfavor intente más tarde o ingrese un nuevo método de pago"
                        }
                        
                    </div>
                    <div className="btn">
                        <div className="txt">
                            <Link className='Link' onClick={CambiarDatos}>CORREGIR DATOS</Link>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default OrdercheckOut
import { useState, useEffect } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import '../../pages/Home/Inicio.scss'

export const ImageSlideTop = () => {

const [SlideD, setData] = useState([]);
const [DataText, setDataText] = useState([]);


useEffect(() => {
  const fetchData = async () => {
      await axios.get(process.env.REACT_APP_API_URL + 'top-banner?populate=*').then(response => {
        setDataText(response.data.data.attributes);
        const imgData = response.data.data.attributes.photos.data;
        setData(imgData);

      })
    };
    fetchData();
},[]);

const settings = {
  dots: true,
  fade: true,
  speed: 2000,
  slidesToShow: 1,
  className:"slide",
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false
};

const images = SlideD.map((image) => (
  <div key={image.attributes.url}>
    <img 
    src={process.env.REACT_APP_UPLOAD_URL + image.attributes.url}
    alt="" />
  </div>
  ));

  return (
    <div className="wrapperSlides">
        <div className="contenedorSlide">
          <div className="Texto-Cuadro">
            <div className="texto">
              <div className="titulo1">
                <h1 className='line'>{DataText.title}</h1>
              </div>
              <div className="titulo2">
              < h1 className='tit2'>{DataText.subTitle}</h1>
              </div>
              <div className="textoInfo">
                <span>{DataText.Description}</span>
              </div>
              <div className="botones">
                <Link to="/Catalogo" className='btn'>{DataText.Button} <EastIcon className='iconCat'/></Link>
                <Link to="/Contacto" className='btn2'>{DataText.Button2}</Link>
              </div>
            </div>
          </div>
          <div className="slideInicio">
            <div className='slider'>
              <Slider {...settings}>
                {images}
              </Slider>
           </div>
          </div>
        </div>
    </div>
  )
}
import React, { useState, useEffect } from 'react';

const ContadorExp = ({ initialTime }) => {
    const [time, setTime] = useState(initialTime);
  
    useEffect(() => {
      const timer =
        time > 0 &&
        setInterval(() => {
          setTime(time - 1);
        }, 1000);
  
      return () => clearInterval(timer);
    }, [time]);
  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
  
    return (
      <div>
        {formatTime(time)}
      </div>
    );
  };
export default ContadorExp
import React from 'react'
import "../sn5/Seccion5.scss";
import { Sn5ContactoHome } from '../../../../components/Datos/sn5/Sn5ContactoHome';

const Seccion5 = () => {
  return (
    <Sn5ContactoHome/>
  )
}

export default Seccion5
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import "./Footer.scss";

const Footer = () => {
  const [Email,SetEmail] = useState("");
  const [ERRmailChimp,setERRmailChimp] = useState(false);
  const [OKmailChimp,setOKmailChimp] = useState(false);

  useEffect(() => {
    if (ERRmailChimp || OKmailChimp) {
      const timer = setTimeout(() => {
        setERRmailChimp(false);
        setOKmailChimp(false);
      }, 3000); //

      return () => clearTimeout(timer);
    }
  }, [ERRmailChimp, OKmailChimp]);

  const HandleEmail = (event) => {
    SetEmail(event.target.value);
  };

  const HandleMailChimp = (event) => {
      event.preventDefault();

      const DataPromo = {
        email: Email
      }
      
      axios
      .post(process.env.REACT_APP_API_LINUM + '/api/mailchimp', DataPromo, {
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        setOKmailChimp(true);
      })
      .catch((error) => {
          console.error('Error mailchimp:', error);
          setERRmailChimp(true);
      });
  }

  return (
    <div className="footer">
      <div className="wrapper">
        <div className="top">
          <div className="item">
            <div className="logo">
              <img src="/img/linum.jpg" alt="linum" />
            </div>
            {/*<div className="info">
              <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus, neque sed dignissim mattis.</span>
            </div>
            <div className="redes">
              <InstagramIcon/>
              <FacebookIcon/>
            </div>*/}
          </div>
          <div className="item">
            <div className="item">
            <h1>Páginas</h1>
              <Link className='Link' to="/">Inicio</Link>
            </div>
            <div className="item">
              <Link className='Link' to="/Nosotros">Nosotros</Link>
            </div>
            <div className="item">
              <Link className='Link' to="/Catalogo">Cátalogo</Link>
            </div>
            <div className="item">
              <Link className='Link' to="/Ofertas">Ofertas</Link>
            </div>
            <div className="item">
              <Link className='Link' to="/Contacto">Contacto</Link>
            </div>
          </div>
          <div className="item">
            <h1>Soporte</h1>
              <span className='contacto'><LiveHelpIcon/><div className="txt"><Link to= "/faqs" className='Link'>Preguntas frecuentes</Link></div></span>
              <span className='contacto'><LocalPhoneIcon/><div className="txt">+52(81) 834886693</div></span>
              <span className='contacto'><EmailIcon/><div className="txt">contacto@linum.mx</div></span>
              <span className='contacto'>
              <WhatsAppIcon/>
              <div className="txt"><a href="https://wa.me/528120409989?text=Me+gustaría+obtener+mas+información+sobres+sus+productos" className="Link"
                target="blank">Whatsapp</a></div>
              </span>
          </div>
          <div className="item">
            <h1>Suscríbete</h1>
              <span>
              Al suscribirme, acepto que Linum me envíe noticias y ofertas y confirmo que estoy de acuerdo con la política de privacidad de LinumCo SA de CV.
              </span>
                <div className="suscribir">
                  <form onSubmit={HandleMailChimp}>
                    <input
                    type="email" 
                    className='textfield'
                    placeholder="Email"
                    onChange={HandleEmail}
                    required>
                    </input>
                    <input type="submit" className='submit' value='Suscribir'/>
                  </form>
                </div>
                <div className="err">{OKmailChimp&&"Se agrego con éxito tu correo"}</div>
                <div className="err">{ERRmailChimp&&"Intente mas tarde"}</div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="copyright">
            Copyright © 2023 Linum Co.
          </div>
        </div>
        <div className="right">
          <div className="terms">
            <Link to="/Tyc" className='Link'>TERMINOS Y CONDICIONES</Link> | <Link to="/Policy" className='Link'>POLITICAS DE PRIVACIDAD</Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer
import {React,useState} from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from "react-redux";
import axios from "axios";
import { addToCart, removeToCart } from "../../../redux/CartReducer";

export const CantidadDetalle = ({ onValorCambiado, isInactive }) => {
  const [contador, setContador] = useState(1);
  
  const sumar = () => {
    if (contador < 20 && !isInactive) {
      setContador(contador + 1);
      onValorCambiado(contador + 1);
    }
  };

  const restar = () => {
    if (contador > 1 && !isInactive) {
      setContador(contador - 1);
      onValorCambiado(contador - 1);
    }
  };

  const botonRestarDeshabilitado = contador === 1;

  return (
    <div className='cantidadContent'>
      <div className="LabelCantidad">Cantidad</div>
      <Link onClick={restar} disabled={botonRestarDeshabilitado} className='btn'>
          <div className={contador === 1 || isInactive ? "contentBtnDes" : "contentBtn"}>
              <RemoveIcon/>   
          </div>
      </Link>
      <div className="contador">{contador}</div>
      <Link onClick={sumar} className='btn'>
        <div className={contador === 20 || isInactive ? "contentBtnDes" : "contentBtn"}>
              <AddIcon/>
          </div>
      </Link>
    </div>
  );
};

export const Cantidad = (props) => {
  const [cantidad, setCantidad] = useState(props.quantity.quantity || 1);
  const dispatch = useDispatch();
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
  const [ErrorExistencias,setErrorExistencias] = useState(false);
  const [Loading, SetLoading] = useState(false);


  const aumentar = () => {
    if(Loading) return;

    SetLoading(true);  
    setTimeout(() => {
      if (cantidad < 20) {
          axios
          .get(
            process.env.REACT_APP_API_URL + "product-catalogues/" + props.quantity.id + "?populate=*"
          )
          .then((dataCatalogo) => {
            const dataFull = dataCatalogo.data.data.attributes;
            
            if (dataFull.Existencias > 0){
              setCantidad(cantidad + 1);

              dispatch(addToCart({
                id: props.quantity.id,
                title: props.quantity.title,
                priceD: props.quantity.priceD.length > 0 ? formatter.format(props.quantity.priceD) : "",
                price: formatter.format(props.quantity.price),
                img: props.quantity.img,
                quantity: 1,
                existencias : dataFull.Existencias
              }))
            }
            else{
              setErrorExistencias(true);
            }

            SetLoading(false);

          })
      }
      
    }, 500);
  };

  const disminuir = () => {
    if(Loading) return;

    SetLoading(true);  
    setTimeout(() => {
      if (cantidad > 1) {
        setCantidad(cantidad - 1);

        dispatch(removeToCart({
          id: props.quantity.id,
          title: props.quantity.title,
          priceD: props.quantity.priceD.length > 0 ? formatter.format(props.quantity.priceD) : "",
          price: formatter.format(props.quantity.price),
          img: props.quantity.img,
          quantity: 1
        }))

        if(ErrorExistencias)
          setErrorExistencias(false);
      }
      SetLoading(false);
    }, 500);
  };

  const botonRestarDeshabilitado = cantidad === 1;

  return (
    <div className='cantidadContent'>
      <div className="LabelCantidad">Cantidad</div>
      <Link onClick={!Loading && disminuir} disabled={botonRestarDeshabilitado} className='btn'>
        <div className={cantidad === 1 ? "contentBtnDes" : "contentBtn"}>
          <RemoveIcon />
        </div>
      </Link>
      <div className="contador">{cantidad}</div>
      <Link onClick={(!Loading && !ErrorExistencias) && aumentar} disabled={Loading} className='btn'>
        <div className={cantidad === 20 || ErrorExistencias ? "contentBtnDes" : "contentBtn"}>
          <AddIcon />
        </div>
      </Link>
    </div>
  );
}


export default CantidadDetalle
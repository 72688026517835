import {ImageSlideTop} from '../../components/HomeSlide/ImageSlide'
import Seccion1 from './Secciones/sn1/Seccion1';
import Seccion2 from './Secciones/sn2/Seccion2';
import Seccion3 from './Secciones/sn3/Seccion3';
import Seccion4 from './Secciones/sn4/Seccion4';
import Seccion5 from './Secciones/sn5/Seccion5';
import "../../components/HomeSlide/Slide.scss";

const Inicio = () => {

  return (
    <div className="home">
          <ImageSlideTop/>
          <Seccion1/>
          <Seccion2/>
          <Seccion3/>
          <Seccion4/>
          <Seccion5/>
    </div>
  )
}

export default Inicio
import React, { useEffect, useState } from 'react'
import { Link , useNavigate  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import "../Order/Order.scss";
import { removeItem, updatePriceTotal } from '../../../redux/CartReducer';
import {Cantidad} from '../../Catalogo/addit/CountCanitdad';
import axios from 'axios';
import DotLoader from "react-spinners/ClipLoader";

const Order = () => {
  const products = useSelector(state=>state.cart.products)
  const dispatch = useDispatch();
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
  const navigate = useNavigate();
  const [zip_to, setPostalCode] = useState('');
  const [shippingCost, setShippingCost] = useState(null);
  const [mjeCP, setMsjeCP]= useState(true);
  const [TotalF , setTotalFull] = useState(0)
  const [loading, setLoading] = useState(false);
  const [DataEstadoByCP, setDataEstado ] = useState([]);

  const TotalPrice = () => {
    let total = 0;
    products.forEach((item) => {
        const precio = parseFloat(item.priceD.length > 0 ? item.priceD.replace('$', '') : item.price.replace('$', ''));
        total += item.quantity * precio
    });
    return total.toFixed(2);
    };

    const PriceProduct = (price,cant) => {
        const precio = parseFloat(price.replace('$', ''));
        const PricePr = cant * precio;
        return PricePr.toFixed(2);
    }

    useEffect(()=>{
        setTotalFull(Number(TotalPrice()) + Number(shippingCost));
    },[shippingCost, TotalPrice()])



    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };

    const handleShippingQuote = () => {

        setShippingCost(null);

        if(!zip_to){
            setMsjeCP(false);
            return;
        }
        else{
            setMsjeCP(true);
            setLoading(true);
        }

        const Data = {
            zip_to
        }

        axios
        .post(process.env.REACT_APP_API_LINUM + '/api/quotations', Data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            const cost = response.data.amount_local;
            setShippingCost(cost);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error al cotizar el envío:', error);
        });

        axios
        .get('dipomex/v1/codigo_postal?cp=' + zip_to, {
            headers: {
                'APIKEY': 'b71fbb7e19904cb9139e7beea13cf09b43b4fcf3',
            }
        })
        .then((response) => {
            const data = response.data.codigo_postal;
            setDataEstado(data);
        })
        .catch((error) => {
            console.error('Error al extraer municipio/estado:', error);
        });
    };


    const handleContinuar =(event) =>{
        event.preventDefault();
        dispatch(updatePriceTotal(TotalPrice()));
        navigate('/Delivery', { state: "RutaDePago" });
    }

    const handleRemoveItem = (id) => {
        const foundProduct = products.find(product => product.id === id);
        dispatch(removeItem({id, quantity : foundProduct.quantity}))
    }

    

  return (
    <div className="WrapperOrden">
        <div className="contentOrden">
            <div className="rutaOrden">
            <Link to="/Catalogo" className='Link'> Catálogo </Link>  <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">Carrito</div> 
            </div>
            <h1 className='titulo'>Carrito de compras</h1>
            <div className="rutaPago">
                <div className="itemRutaPago Active">
                    <p className='circle circleActive'>1</p><h1>Carrito</h1>
                </div>
                <div className="itemRutaPago Inactive">
                    <p className='circle '>2</p><h1>Forma de pago</h1>
                </div>
                <div className="itemRutaPago Inactive">
                    <p className='circle'>2</p><h1>Metodo de pago</h1>
                </div>
                <div className="itemRutaPago Inactive">
                    <p className='circle'>3</p><h1>Resumen</h1>
                </div>
            </div>
            <div className="contentProductos">
                <div className="productos">
                    <div className="headersProd">
                        <div>Producto</div>
                        <div>Cantidad</div>
                        <div>Precio</div>
                        <div>Total</div>
                        
                    </div>
                    {products.map((item) => (
                    <div className="itemproducto" key={item.id}>
                        <div className="cell">
                            <div className="imagen">
                                <Link to={"/Catalogo/" + item.id}><img src={item.img} alt="" /></Link>
                                <div className="titleProd">{item.title}</div>
                            </div>
                        </div>          
                        <div className="cell ">
                            <Cantidad 
                            quantity = {item} />
                        </div>
                        <div className="cell">
                            <div className="precio">
                                <p>
                                    {item.priceD.length > 0 ? item.priceD : item.price}
                                </p>
                                { item.priceD.length > 0 ? 
                                <p className = 'Oferta'>Oferta</p>
                                :""
                            }
                            </div>
                        </div>
                        <div className="cell">
                            <div className="total">
                                <p>
                                    {formatter.format(PriceProduct(item.priceD.length > 0 ? item.priceD : item.price,item.quantity))}
                                </p>
                            </div>
                        </div>
                        <div className="cell" onClick={()=>handleRemoveItem(item.id)}>
                            <div className="delete"><p><ClearIcon/></p></div>
                        </div>
                    </div>
                    ))}
                </div>

                <div className="productos-movil">
                    {products.map((item) => (
                    <div className="ItemsMoviles" key={item.id}>
                        <div className="itemImagen">
                                <div className="imagen">
                                    <Link to={"/Catalogo/" + item.id}><img src={item.img} alt="" /></Link>
                                
                            </div> 
                        </div>
                        <div className="itemproducto">   
                            <div className="cell">
                                <div className="titleProd">{item.title}
                                </div>
                            </div>
                            
                            <div className="cell">
                                <div className="precio">
                                   
                                    <p>
                                        <label className='label'>Precio: </label>  {item.priceD.length > 0 ? item.priceD : item.price}
                                    </p>
                                    { item.priceD.length > 0 ? 
                                    <p className = 'Oferta'>Oferta</p>
                                    :""
                                }
                                </div>
                            </div>
                            <div className="cell ">
                                <Cantidad quantity = {item} />
                            </div>
                            <div className="cell">
                                <label className='label'>Total:</label>
                                <div className="total">
                                    {formatter.format(PriceProduct(item.priceD.length > 0 ? item.priceD : item.price,item.quantity))}
                                </div>
                                <div className="delete"onClick={()=>handleRemoveItem(item.id)}>
                                    <ClearIcon/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    ))}
                </div>
                <div className="productTotal">
                    <div className="codeDesc">
                        <input type="text" className='input' placeholder="Ingresar cupón"/>
                        <div className="btnAplicar">APLICAR</div>
                    </div>
                    <div className="total">
                        <div className="subTotal">
                            <p className='title'>SUBTOTAL: </p>
                            <p className='precio'>{formatter.format(TotalPrice())}</p>
                        </div>
                        {/*<div className="CpEnvio">
                            <input type="text"
                                value={zip_to}
                                onChange={handlePostalCodeChange}
                                placeholder="Código postal"
                                className='input-form'/>
                            <button 
                            onClick={handleShippingQuote}
                            className='button-form'>
                                {loading ? 
                               <DotLoader
                                color={"#FFFFFF"}
                                loading={loading}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                />:
                                "COTIZAR"}
                            </button>
                            
                        </div>

                        
                            {/* shippingCost ?
                            <>
                            <div className="EstadoByCP">
                                <p className='EstadoMunicipio'>{DataEstadoByCP.municipio}, {DataEstadoByCP.estado}</p>
                            </div>
                            <div className="TotalEnvio">
                                <p className='title'>TOTAL ENVIO: </p>
                                <p className='precio'>{formatter.format(shippingCost)}</p>
                            </div>
                            </>
                            :
                            <div className='TextEnvio'>
                                <span className={mjeCP ? 'textEnvio':'textEnvio Invalid'}>Agrega tu CP para cotizar el envio</span>
                                <p className='title'>TOTAL ENVIO: </p>
                            </div>
                            
                        <div className="TotalFull">
                            <p className='totalName'>TOTAL</p>
                            <p className='totalprice'>{formatter.format(TotalF)}</p>
                        </div>
                        */}
                    </div>
                </div>
                <div className="Checkout">
                    <Link to={"/Catalogo"} className="btnContinuar">
                        CONTINUAR COMPRANDO
                    </Link>
                    {products.length > 0 ?
                    <Link
                    to="/CartAuth"
                    className="btnChekout" 
                    onClick={handleContinuar}
                    >
                        CONTINUAR
                    </Link>:
                    <div className='NoProduts'>Agrega articulos a tu carrito! </div>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Order
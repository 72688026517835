import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const AddressCard = (props) => {
  const navigate = useNavigate();
  const PropAdrs = props.Adrs
  const isSelected = props.selected === PropAdrs.id;
  const storedData = localStorage.getItem("SessionUser");
  const SessionUser = JSON.parse(storedData);
  const [DataEstado,setDataEstado] = useState(null);
  const [IsDeleteDireccion,SetIsDeleteDireccion] = useState(false)
  
  useEffect(()=>{
    axios
    .get(process.env.REACT_APP_API_LINUM + '/api/cities', {
        headers: {
            'Authorization': 'Bearer ' + SessionUser.access_token
        }
    })
    .then((response) => {
        setDataEstado(response.data);
    })
    .catch((error) => {
      if(error.response?.status === 401){
          localStorage.removeItem('SessionUser');
          localStorage.removeItem('Logged');
          navigate("/CartAuth")
      }else
        console.error('Error delivery_points:', error);
    });
  },[])

  const EstadoName = () => {
    const estadoEncontrado = DataEstado?.find(estado => estado.id === PropAdrs.state_id);
    
    if (estadoEncontrado) {
      return estadoEncontrado.state;
    }
    
    return null; // o un valor predeterminado si no se encuentra ningún estado
  };

  const deleteDireccion = () => {
    SetIsDeleteDireccion(true)
  } 
  const ConfirmDeleteDireccion = (id, event) => {
    event.preventDefault();

      axios
        .delete(process.env.REACT_APP_API_LINUM + '/api/delivery_points/' + id, {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            if(response.status === 204)
              props.onRegistroEliminado(true);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              navigate("/login")
          }else
            console.error('Error delivery_points:', error);
        });
  }

  const handleDeleteClick = (event) => {
    ConfirmDeleteDireccion(PropAdrs.id, event);
  };

  const CancelDeleteDireccion = () => {
    SetIsDeleteDireccion(false);
  }

  const EditarDireccion = () => {
     props.onEditDireccion(PropAdrs.id);
  }


  return (
    props.comp !== "perfil" ?
    <div className={`cardAddress ${isSelected ? 'selected' : ''}`} onClick={props.onClick}>
      <div className="iconCard"><PlaceIcon/></div>
      <div className="dir">
        <div className='item'><h1>{PropAdrs.contact_name}</h1></div>
        <div className='item'>{PropAdrs.address}, {PropAdrs.city}, {EstadoName()} , {PropAdrs.postal_code}</div>
      </div>
    </div>:
    <div className="cardAddressPerfil" onClick={props.onClick}>
    <div className="dir">
      {props.predeterminado &&
      <div className='Itempredeterminado'>
        <div className="item"> Predeterminada </div>
      </div>
      }
      <div className="itemsDir">
        <div className='item title'>{PropAdrs.contact_name}</div>
        <div className='item'>{PropAdrs.address} #{PropAdrs.street_number}</div>
        <div className='item'>{PropAdrs.city}</div>
        <div className='item'>{EstadoName()} , {PropAdrs.postal_code}</div>
      </div>
      
      <div className="acciones">
        <div className="itemAccion"><Link className='Link' onClick={EditarDireccion}> Editar Dirección </Link> </div>
        { !IsDeleteDireccion ? 
        <>
          <div className="itemAccion delete">
            <Link className='Link' onClick={deleteDireccion}> 
              Eliminar 
            </Link>
          </div>
        </>:
        <>
          <div className="itemAccion delete">
            <Link className='Link' onClick={handleDeleteClick}> 
              <CheckCircleOutlineIcon/>
            </Link>
            <Link className='Link' onClick={CancelDeleteDireccion}> 
              <HighlightOffIcon/>
            </Link>
          </div>
        </>
        }
      </div>
    </div>
  </div>
  )
}

export const FacturacionCard = (props) => {
  const navigate = useNavigate();
  const PropAdrs = props.Fact
  const storedData = localStorage.getItem("SessionUser");
  const SessionUser = JSON.parse(storedData);
  const [IsDeleteDireccion,SetIsDeleteDireccion] = useState(false)
  const PropFact = props.Fact
  const isSelected = props.selected === PropFact.id;
  

  const deleteDireccion = () => {
    SetIsDeleteDireccion(true)
  } 

  const ConfirmDeleteDireccion = (id, event) => {
    event.preventDefault();

      axios
        .delete(process.env.REACT_APP_API_LINUM + '/api/invoice_data/' + id, {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            if(response.status === 204)
              props.onRegistroEliminado(true);
        })
        .catch((error) => {
          if(error.response?.status === 401){
              localStorage.removeItem('SessionUser');
              localStorage.removeItem('Logged');
              if(props.Ref)
                navigate(props.Ref, { state: "RutaDePago" })
              else
                navigate("/Login")
          }else
            console.error('Error delivery_points:', error);
        });
    }

  const handleDeleteClick = (event) => {
    ConfirmDeleteDireccion(PropAdrs.id, event);
  };

  const CancelDeleteDireccion = () => {
    SetIsDeleteDireccion(false);
  }

  const EditarDireccion = () => {
    props.onEditDireccion(PropAdrs.id);
  }


  return (
    props.comp !== "perfil" ?
    <div className={`cardAddress ${isSelected ? 'selected' : ''}`} onClick={props.onClick}>
      <div className="iconCard"><PlaceIcon/></div>
      <div className="dir">
        <div className='item'><h1>{PropFact.name}</h1></div>
        <div className='item'>{PropFact.tax_id}, {PropFact.address}</div>
      </div>
    </div>:
    <div className="cardAddressPerfil" onClick={props.onClick}>
    <div className="dir">
      <div className='item'><h1>{PropFact.tax_id}</h1></div>
      <div className='item'>
        {PropFact.name}<br/>
        {PropFact.email} 
      </div>
      
      <div className="acciones">
        <div className="itemAccion"><Link className='Link' onClick={EditarDireccion}> Editar </Link> </div>
        { !IsDeleteDireccion ? 
        <>
          <div className="itemAccion delete">
            <Link className='Link' onClick={deleteDireccion}> 
              Eliminar 
            </Link>
          </div>
        </>:
        <>
          <div className="itemAccion delete">
            <Link className='Link' onClick={handleDeleteClick}> 
              <CheckCircleOutlineIcon/>
            </Link>
            <Link className='Link' onClick={CancelDeleteDireccion}> 
              <HighlightOffIcon/>
            </Link>
          </div>
        </>
        }
      </div>
    </div>
    
  </div>
  )
}

export default AddressCard
import React, { useState, Fragment, useEffect, useRef } from "react";
import "../Contacto/Contacto.scss";
import axios from "axios";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Modal from 'react-modal';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Link } from "react-router-dom";
import DotLoader from "react-spinners/ClipLoader";
import ReCAPTCHA from "react-google-recaptcha";

const valueIsValid = (value) => value.trim() !== "";
const checkEmailIsValid = (email) => email.includes("@");


const Contacto = () => {
  const recaptchaRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isCheckedPromos, setIsCheckedPromos] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [EnvioEmail,setEnvioEmail] = useState(false);
  const [Loading,setLoading] = useState(false);
  const storedData = localStorage.getItem("SessionUser");
  const SessionUser = JSON.parse(storedData);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [NoCaptcha,setNoCaptcha] = useState(false);
  const KeyCaptcha = process.env.REACT_APP_KEY_PROD;

    const handleRecaptchaChange = (value) => {
      setRecaptchaValue(value);
    };

  useEffect(() => {
    if (modalIsOpen || NoCaptcha) {
      let temporizador = setTimeout(() => {
        setModalIsOpen(false);
        setEnvioEmail(false);
        setNoCaptcha(false);
      }, 5000); //

      return () => clearTimeout(temporizador);
    }
  }, [modalIsOpen , NoCaptcha]);

  const closeModal = () => {
    setModalIsOpen(false);
    setEnvioEmail(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    content: {
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '80%' : '35%', 
      height: isMobile ? '30%' : '150px',
      background: 'white',
      zIndex: 1001,
    }
  };

  const optionsGiro = [
    { value: "", label: "" },
    { value: "Particular", label: "Particular" },
    { value: "Negocio", label: "Negocio" },
    { value: "Estudiante", label: "Estudiante" },
    { value: "Otro", label: "Otro" },
  ];
  const optionsTipoSolicitud = [
    { value: "Info", label: "Quiero información" }
    //{ value: "Muestrario", label: "Quiero un muestrario" },
  ];

  const estadosMx = [
    { value: "", label: "" },
    { value: "Aguascalientes", label: "Aguascalientes" },
    { value: "Baja California Norte", label: "Baja California Norte" },
    { value: "Baja California Sur", label: "Baja California Sur" },
    { value: "Campeche", label: "Campeche" },
    { value: "Colima", label: "Colima" },
    { value: "Chiapas", label: "Chiapas" },
    { value: "Chihuahua", label: "Chihuahua" },
    { value: "Cd México", label: "Cd México" },
    { value: "Durango", label: "Durango" },
    { value: "Guanajuato", label: "Guanajuato" },
    { value: "Guerrero", label: "Guerrero" },
    { value: "Hidalgo", label: "Hidalgo" },
    { value: "Jalisco", label: "Jalisco" },
    { value: "Edo de México", label: "Edo de México" },
    { value: "Michoacán", label: "Michoacán" },
    { value: "Morelos", label: "Morelos" },
    { value: "Nayarit", label: "Nayarit" },
    { value: "Nuevo Leon", label: "Nuevo Leon" },
    { value: "Oaxaca", label: "Oaxaca" },
    { value: "Puebla", label: "Puebla" },
    { value: "Querétaro", label: "Querétaro" },
    { value: "Quintana Roo", label: "Quintana Roo" },
    { value: "San Luis Potosí", label: "San Luis Potosí" },
    { value: "Sinaloa", label: "Sinaloa" },
    { value: "Sonora", label: "Sonora" },
    { value: "Tabasco", label: "Tabasco" },
    { value: "Tamaulipas", label: "Tamaulipas" },
    { value: "Tlaxcala", label: "Tlaxcala" },
    { value: "Veracruz", label: "Veracruz" },
    { value: "Yucatán", label: "Yucatán" },
    { value: "Zacatecas", label: "Zacatecas" },
  ];

  const [name, setName] = useState("");
  const [nameIsValid, setNameIsValid] = useState(false);

  const [apellidos, setapellido] = useState("");
  const [apellidosIsvalid, setApellidosIsValid] = useState(false);

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);

  const [selectedGiro, setselectedGiro] = useState(optionsGiro[0].value);
  const [selectedGiroIsValid, setSelectedGiroIsValid] = useState(false);

  const [selectedTipoSolicitud, setselectedTipoSolicitud] = useState(
    optionsTipoSolicitud[0].value
  );

  const [nombreNegocio, setNombreNegocio] = useState("");
  const [NombreNegocioIsvalid, setNombreNegocioIsValid] = useState(false);

  const [calle, setCalle] = useState("");
  const [calleIsvalid, setCalleIsvalid] = useState(false);

  const [numeroExt, setNumeroExt] = useState("");
  const [numeroExtIsvalid, setNumeroExtIsvalid] = useState(false);

  const [numeroInterior, setNumeroInterior] = useState("");
  // const [numeroInteriorIsvalid, setNumeroInteriorIsvalid] = useState("");

  const [colonia, setColonia] = useState("");
  const [coloniaIsvalid, setColoniaIsvalid] = useState(false);

  const [ciudad, setCiudad] = useState("");
  const [ciudadIsvalid, setCiudadIsvalid] = useState(false);

  const [estado, setEstado] = useState("");
  const [estadoIsvalid, setEstadoIsvalid] = useState(false);

  const [cPostal, setCPostal] = useState("");
  const [cPostalIsvalid, setCPostalIsvalid] = useState(false);

  const [telefono, setTelefono] = useState("");
  // const [telefonoIsvalid, setTelefonoIsvalid] = useState("");

  const [message, setMessage] = useState("");
  const [messageIsvalid, setMessageIsvalid] = useState(false);

  const [formIsValid, setFormIsValid] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const checkFormIsValid = () => {
    setNameIsValid(valueIsValid(name));
    setApellidosIsValid(valueIsValid(apellidos));
    setEmailIsValid(checkEmailIsValid(email));
    setSelectedGiroIsValid(valueIsValid(selectedGiro));

    selectedGiro === "Negocio"
      ? setNombreNegocioIsValid(valueIsValid(nombreNegocio))
      : setNombreNegocioIsValid(true);

    if (selectedTipoSolicitud === "Muestrario") {
      setCalleIsvalid(valueIsValid(calle));
      setNumeroExtIsvalid(valueIsValid(numeroExt));
      // setNumeroInteriorIsvalid(valueIsValid(numeroInterior));
      setColoniaIsvalid(valueIsValid(colonia));
      setCiudadIsvalid(valueIsValid(ciudad));
      setEstadoIsvalid(valueIsValid(estado));
      setCPostalIsvalid(valueIsValid(cPostal));
      // setTelefonoIsvalid(valueIsValid(telefono));

      setFormIsValid(
        nameIsValid &&
          apellidosIsvalid &&
          emailIsValid &&
          selectedGiroIsValid &&
          NombreNegocioIsvalid &&
          calleIsvalid &&
          numeroExtIsvalid &&
          coloniaIsvalid &&
          ciudadIsvalid &&
          estadoIsvalid &&
          cPostalIsvalid
      );

      return formIsValid;

    } else if (selectedTipoSolicitud === "Info") {
      setMessageIsvalid(valueIsValid(message));

      setFormIsValid(
        nameIsValid &&
          apellidosIsvalid &&
          emailIsValid &&
          selectedGiroIsValid &&
          NombreNegocioIsvalid &&
          messageIsvalid
      );

      return formIsValid;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitButtonClicked(true);
    //console.log(formIsValid)

    if (!formIsValid) {
      return;
    }

    if(!recaptchaValue){
      setNoCaptcha(true)
      return;
    }

    const formData = { 
      name: name,
      last_name: apellidos,
      email: email,
      type_of_contact: selectedGiro,
      business_name: nombreNegocio,
      requierement_type: selectedTipoSolicitud,
      street: calle,
      number: numeroExt,
      appartment: numeroInterior,
      neighborhood: colonia,
      city: ciudad,
      state: estado,
      zip_code: cPostal,
      phone: telefono,
      message: message,
      newsletter_subscription: isCheckedPromos
    };

    //console.log(formData);

    setLoading(true);
    
    await axios
    .post(process.env.REACT_APP_API_LINUM + '/api/contact_form', formData,{
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      if(response.status === 201)
        setEnvioEmail(true);
    })
    .catch ((error) => {
      console.error(`Hubo una excepcion al enviar formulario`, error);
    })

    setLoading(false);
    setModalIsOpen(true);

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }        

    //console.log(formData);

    setName("");
    setNameIsValid(false);
    setEmail("");
    setEmailIsValid(false);
    setMessage("");
    setMessageIsvalid(false)
    setapellido("");
    setApellidosIsValid(false);
    setCalle("");
    setCalleIsvalid(false);
    setNumeroExt("");
    setNumeroExtIsvalid(false);
    setNombreNegocio("");
    setNombreNegocioIsValid(false);
    setselectedGiro("");
    setSelectedGiroIsValid(false);
    setNumeroInterior("");
    setColonia("");
    setColoniaIsvalid(false);
    setCiudad("");
    setCiudadIsvalid(false);
    setEstado("");
    setEstadoIsvalid(false);
    setCPostal("");
    setCPostalIsvalid(false);
    setTelefono("");
    setFormIsValid(false);
    setSubmitButtonClicked(false);

    
  };

  const nameChangeHandler = (event) => {
    setName(event.target.value);
    setFormIsValid(checkFormIsValid)
  };

  const nameBlurHandler = () => {
    if (submitButtonClicked) {
      setNameIsValid(valueIsValid(name));
    }
  };

  const lastNameChangeHandler = (event) => {
    setapellido(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const lastNameBlurHandler = () => {
    if (submitButtonClicked) {
      setApellidosIsValid(valueIsValid(apellidos));
    }
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const emailBlurHandler = () => {
    if (submitButtonClicked) {
      setEmailIsValid(checkEmailIsValid(email));
    }
  };

  const giroChangeHandler = (event) => {
    setselectedGiro(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const giroBlurHandler = () => {
    if (submitButtonClicked) {
      setSelectedGiroIsValid(valueIsValid(selectedGiro));
    }
  };

  const nombreNegocioChangeHandler = (event) => {
    setNombreNegocio(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const nombreNegocioBlurHandler = () => {
    if (submitButtonClicked) {
      setNombreNegocioIsValid(valueIsValid(nombreNegocio));
    }
  };

  const calleChangeHandler = (event) => {
    setCalle(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const calleBlurHandler = () => {
    if (submitButtonClicked) {
      setCalleIsvalid(valueIsValid(calle));
    }
  };

  const noExtChangeHandler = (event) => {
    setNumeroExt(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const noExtBlurHandler = () => {
    if (submitButtonClicked) {
      setNumeroExtIsvalid(valueIsValid(numeroExt));
    }
  };

  const noIntChangeHandler = (event) => {
    setNumeroInterior(event.target.value);
  };

  const noIntBlurHandler = () => {
    if (submitButtonClicked) {
      // setNumeroInteriorIsvalid(valueIsValid(numeroInterior))
    }
  };

  const coloniaChangeHandler = (event) => {
    setColonia(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const coloniaBlurHandler = () => {
    if (submitButtonClicked) {
      setColoniaIsvalid(valueIsValid(colonia));
    }
  };

  const ciudadChangeHandler = (event) => {
    setCiudad(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const ciudadBlurHandler = () => {
    if (submitButtonClicked) {
      setCiudadIsvalid(valueIsValid(ciudad));
    }
  };

  const estadoChangeHandler = (event) => {
    setEstado(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const estadoBlurHandler = () => {
    if (submitButtonClicked) {
      setEstadoIsvalid(valueIsValid(estado));
    }
  };

  const cPostalChangeHandler = (event) => {
    setCPostal(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const cPostalBlurHandler = () => {
    if (submitButtonClicked) {
      setCPostalIsvalid(valueIsValid(cPostal));
    }
  };

  const telChangeHandler = (event) => {
    setTelefono(event.target.value);
  };

  const messageChangeHandler = (event) => {
    setMessage(event.target.value);
    setFormIsValid(checkFormIsValid);
  };

  const messageBlurHandler = () => {
    if (submitButtonClicked) {
      setMessageIsvalid(valueIsValid(message));
    }
  };

  const handlePromos = () =>{
    setIsCheckedPromos(!isCheckedPromos)
  };

  const nameclass =
    submitButtonClicked && !nameIsValid ? "form-input invalid" : "form-input";

  const lastNameclass =
    submitButtonClicked && !apellidosIsvalid
      ? "form-input invalid"
      : "form-input";

  const emailclass =
    submitButtonClicked && !emailIsValid ? "form-input invalid" : "form-input";

  const selectedGiroclass =
    submitButtonClicked && !selectedGiroIsValid
      ? "form-input invalid"
      : "form-input";

  const negocioClass =
    submitButtonClicked && !NombreNegocioIsvalid
      ? "form-input invalid"
      : "form-input";

  const calleClass =
    submitButtonClicked && !calleIsvalid ? "form-input invalid" : "form-input";

  const noExtClass =
    submitButtonClicked && !numeroExtIsvalid
      ? "form-input invalid"
      : "form-input";

  const coloniaClass =
    submitButtonClicked && !coloniaIsvalid
      ? "form-input invalid"
      : "form-input";

  const ciudadClass =
    submitButtonClicked && !ciudadIsvalid ? "form-input invalid" : "form-input";

  const estadoClass =
    submitButtonClicked && !estadoIsvalid ? "form-input invalid" : "form-input";

  const cPostalClass =
    submitButtonClicked && !cPostalIsvalid
      ? "form-input invalid"
      : "form-input";
      
  const messageClass =
    submitButtonClicked && !messageIsvalid
      ? "form-textarea invalid"
      : "form-textarea";

  let address = (
    <Fragment>
      <div className="row">
        <h2 className="form-title">Dirección de envio</h2>
      </div>
      {!isMobile ? 
      <div className="row">
        <div className="itemform">
          <label>Calle: </label>
          <input
            type="text"
            className={calleClass}
            value={calle}
            onChange={calleChangeHandler}
            onBlur={calleBlurHandler}
          />
        </div>
        <div className="itemform">
          <label>Numero Exterior: </label>
          <input
            type="number"
            className={noExtClass}
            value={numeroExt}
            onChange={noExtChangeHandler}
            onBlur={noExtBlurHandler}
          />
        </div>
        <div className="itemform">
          <label>Numero Interior: </label>
          <input
            type="number"
            className="form-input"
            value={numeroInterior}
            onChange={noIntChangeHandler}
            onBlur={noIntBlurHandler}
          />
        </div>
      </div>:
      <>
        <div className="row">
          <div className="itemform">
            <label>Calle: </label>
            <input
              type="text"
              className={calleClass}
              value={calle}
              onChange={calleChangeHandler}
              onBlur={calleBlurHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="itemform">
            <label>Numero Exterior: </label>
            <input
              type="number"
              className={noExtClass}
              value={numeroExt}
              onChange={noExtChangeHandler}
              onBlur={noExtBlurHandler}
            />
          </div>
          <div className="itemform">
            <label>Numero Interior: </label>
            <input
              type="number"
              className="form-input"
              value={numeroInterior}
              onChange={noIntChangeHandler}
              onBlur={noIntBlurHandler}
            />
          </div>
        </div>
      </>
      }

      <div className="row">
        <div className="itemform">
          <label>Colonia: </label>
          <input
            type="text"
            className={coloniaClass}
            value={colonia}
            onChange={coloniaChangeHandler}
            onBlur={coloniaBlurHandler}
          />
        </div>
        <div className="itemform">
          <label>Ciudad: </label>
          <input
            type="text"
            className={ciudadClass}
            value={ciudad}
            onChange={ciudadChangeHandler}
            onBlur={ciudadBlurHandler}
          />
        </div>
      </div>

      {!isMobile ? 
      <div className="row">
        <div className="itemform">
          <label>Estado: </label>
          <select
            value={estado}
            onChange={estadoChangeHandler}
            onBlur={estadoBlurHandler}
            className={estadoClass}
          >
            {estadosMx.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="itemform">
          <label>Codigo Postal: </label>
          <input
            type="number"
            className={cPostalClass}
            value={cPostal}
            onChange={cPostalChangeHandler}
            onBlur={cPostalBlurHandler}
          />
        </div>
        <div className="itemform">
          <label>Telefono: </label>
          <input
            type="tel"
            pattern="[0-9]{10}"
            className="form-input"
            value={telefono}
            onChange={telChangeHandler}
          />
        </div>
      </div>:
      <>
      <div className="row">
        <div className="itemform">
          <label>Estado: </label>
          <select
            value={estado}
            onChange={estadoChangeHandler}
            onBlur={estadoBlurHandler}
            className={estadoClass}
          >
            {estadosMx.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
      <div className="itemform">
          <label>Codigo Postal: </label>
          <input
            type="number"
            className={cPostalClass}
            value={cPostal}
            onChange={cPostalChangeHandler}
            onBlur={cPostalBlurHandler}
          />
        </div>
        <div className="itemform">
          <label>Telefono: </label>
          <input
            type="tel"
            pattern="[0-9]{10}"
            className="form-input"
            value={telefono}
            onChange={telChangeHandler}
          />
        </div>
      </div>
      </>
            }
    </Fragment>
  );

  let msg = (
    <Fragment>
      <div className="row">
        <div className="itemform">
          <label>Mensaje: </label>
          <textarea
            className={messageClass}
            value={message}
            onChange={messageChangeHandler}
            onBlur={messageBlurHandler}
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <div className="wrapperContacto">
      <div className="contenedorContacto">
        <div className="ContentTitulo">
          <div className="tituloContacto">
            <h1>¡Ponte en contacto!</h1>
            <span>
            Si tienes alguna pregunta acerca de nuestros productos o deseas ponerte en contacto con nosotros, 
            por favor, completa el siguiente formulario y nos comunicaremos contigo a la brevedad posible. 
            ¡Gracias!
            </span>
          </div>
        </div>
        <div className="ContentForm">
          <div className="FormLeft">
            <div className="Title">
              <h1>
                Información<hr/>
              </h1>
              
              </div>
            <div className="itemContacto">            
              <div className="item">
                <img src="img/phone.svg" alt="" className="item_immage" />
                <div className="txt">+52 (81) 83486693</div>
              </div>
              <div className="item">
                <img src="img/mail.svg" alt="" className="item_immage" />
                <div className="txt">contacto@linum.mx</div>
              </div>
              <div className="item">
                  <WhatsAppIcon className="img"/>
                <div className="txt">
                <a href="https://wa.me/528120409989?text=Me+gustaría+obtener+mas+información+sobres+sus+productos" className="Link"
                target="blank">Envíanos un Whatsapp </a></div>
              </div>
              <div className='item'>
                  <LiveHelpIcon className= "img"/>
                <div className="txt">
                <Link to= "/faqs" className='Link'>
                  Preguntas frecuentes
                  </Link>
                </div>
                
              </div>
            </div>

          </div>
          <div className="FormRight">
            <form className="form-container" onSubmit={handleSubmit}>
              {!formIsValid && submitButtonClicked ? <p>Favor de llenar todos los campos señalados</p> : ""}
              <h2 className="form-title">Envianos un mensaje</h2>
              {!isMobile ? 
              <div className="row">
                <div className="itemform">
                  <label>Nombre: </label>
                  <input
                    type="text"
                    className={nameclass}
                    value={name}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                  />
                </div>
                <div className="itemform">
                  <label>Apellidos: </label>
                  <input
                    type="text"
                    className={lastNameclass}
                    value={apellidos}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                  />
                </div>
                <div className="itemform">
                  <label>Email: </label>
                  <input
                    type="email"
                    className={emailclass}
                    value={email}
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                  />
                </div>
              </div>:
              <>
              <div className="row">
                <div className="itemform">
                  <label>Nombre: </label>
                  <input
                    type="text"
                    className={nameclass}
                    value={name}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                  />
                </div>
                <div className="itemform">
                  <label>Apellidos: </label>
                  <input
                    type="text"
                    className={lastNameclass}
                    value={apellidos}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                  />
                </div>
              </div>
              <div className="row">
                <div className="itemform">
                    <label>Email: </label>
                    <input
                      type="email"
                      className={emailclass}
                      value={email}
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                    />
                  </div>
              </div>
              </>
              }

              <div className="row">
                <div className="itemform">
                  <label>Enfoque o giro: </label>
                  <select
                    value={selectedGiro}
                    onChange={giroChangeHandler}
                    onBlur={giroBlurHandler}
                    className={selectedGiroclass}
                  >
                    {optionsGiro.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="itemform">
                  <label className={selectedGiro === "Negocio" ? "" : "hidden"}>
                    Nombre del Negocio:
                  </label>
                  <input
                    className={
                      selectedGiro !== "Negocio" ? "hidden" : negocioClass
                    }
                    type="text"
                    value={nombreNegocio}
                    onChange={nombreNegocioChangeHandler}
                    onBlur={nombreNegocioBlurHandler}
                  />
                </div>
              </div>

              <div className="row">
                <div className="itemform">
                  <label>Tipo de solicitud: </label>
                  <select
                    value={selectedTipoSolicitud}
                    onChange={(e) => setselectedTipoSolicitud(e.target.value)}
                    className="form-input"
                  >
                    {optionsTipoSolicitud.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {selectedTipoSolicitud === "Muestrario" && address}
              {selectedTipoSolicitud === "Info" && msg}

              <div className="Check">
                <input type='checkbox' 
                checked={isCheckedPromos}
                onClick={handlePromos}/>
                Deseo estar enterado de ofertas y promociones
              </div>
              <div className="captcha"> 
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={KeyCaptcha}
                  onChange={handleRecaptchaChange}
                />
                {NoCaptcha && 
                  <div className="txtCaptcha">
                    Utiliza el captcha para continuar.
                  </div> 
                }
              </div>
              <div className="rowButton">
                {!Loading ?
                <button type="submit" className="form-submit-button">
                  Enviar
                </button>
                :<DotLoader
                color={"#1d4c5a"}
                loading={Loading}
                aria-label="Loading Spinner"
                data-testid="loader"
                />
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style = {customModalStyles}
        >
          <div className="modal-Contacto">
          {EnvioEmail ?
            <div className="mejeEnvio">
                Hemos recibido tu información y en breve nos pondremos en contacto.
            </div>:
            <div className="mejerror">
                Ocurrio un error por favor intente más tarde.
            </div>
          }

          </div>

      </Modal>
    </div>
  );
};

export default Contacto;

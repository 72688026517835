import React, { useState, useEffect } from 'react';
import "../../pages/Nosotros/Nosotros.scss"
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';

export const CompNosotros = () =>{

    const [Nosotros, setData] = useState([]);
    const [PopularesImg, setImg] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
            const nosotrosData = await axios.get(process.env.REACT_APP_API_URL + 'about?populate=*');
            const DataFull = nosotrosData.data.data.attributes;
            const Imge = DataFull.Image.data.attributes.formats.medium;
            setData(DataFull);
            setImg(Imge);
            
          };
          fetchData();
    }, []);

    //const description = Nosotrosmap.attributes.description.split('\n');

    const answer = Nosotros.paragraph1?.split('\n');

    return (
        <div className="nosotrosContent">
            <div className="NosotrosLeft">
           
                <div className="contentTxt">
                    <h1>{Nosotros.Title1}</h1>
                <span>
                  {answer?.map((texto, index) => (
                      <>
                      {texto}
                      <br />
                      </>
                    ))}
                </span>
                </div>
            </div>
            <div className="NosotrosImg">
                <div className="imagen">
                    <img 
                    src={process.env.REACT_APP_UPLOAD_URL + PopularesImg.url} alt=''></img>
                </div>
            </div>
        </div>
        
    );
}

export const CompNosotrosBottom = () =>{

    const [Nosotros, setData] = useState([]);
  
    useEffect(() => {
        const fetchData = async () => {
            const nosotrosData = await axios.get(process.env.REACT_APP_API_URL + 'about?populate=*');
            const DataFull = nosotrosData.data.data.attributes;
            setData(DataFull);
          };
          fetchData();
    }, []);

    const answer = Nosotros.paragraph2?.split('\n');

    return(
        
            <div className="featuresNosotros">
              <div className="features01">
                <div className="contentTxt">
                    <h1>{Nosotros.title2}</h1>
                    <span>
                      {answer?.map((texto, index) => (
                        <>
                        {texto}
                        <br />
                        </>
                      ))}
                    </span>
                </div>
              </div>
              <div className="features02">
                <div className="ContentItems">
                  <div className="Items">
                    <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem1} </div>
                    </div>
                  </div>
                  <div className="Items">
                  <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem2} </div>
                    </div>
                  </div>
                  <div className="Items">
                  <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem3} </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="features02">
                <div className="ContentItems">
                  <div className="Items">
                  <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem4} </div>
                    </div>
                    </div>
                    <div className="Items">
                    <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem5} </div>
                    </div>
                  </div>
                  <div className="Items">
                  <div className="ItemTexto"> 
                        <div className="check"><CheckIcon className='check' /></div>
                        <div className="texto">{Nosotros.ListItem6} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    );
}
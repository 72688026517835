import { useState, useEffect } from "react";
import "../../../pages/Home/Secciones/sn4/Seccion4.scss";
import axios from "axios";
import { Link } from 'react-router-dom';


export const Sn4Populares = () => {
    const [DataPopularesImg, setDataImg] = useState({
        images: [],
        currentIndex: 0
      });
    const [DataPopulares, setData] = useState([]);
    const [DataImagen, setDataImgAttr] = useState([]);
    const [IdProduct, setID] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                const response = await axios.get(process.env.REACT_APP_API_URL + 'popular-product?populate[product_catalogues][populate]=*');
                const DataAttributes = response.data.data.attributes;
                const ProdCatalog = DataAttributes.product_catalogues.data.map(img => img.attributes)
                const IdProducts = DataAttributes.product_catalogues.data.map(img => img.id)
                const ProductImages = ProdCatalog.map(item => item.productImages.data);
                //const Attributes = ProductImages.map(item => item[0]);
                
                //const idProducts = data.map(id => id.id); 
                setID(IdProducts);
                setData(DataAttributes);
                setDataImgAttr(ProdCatalog);
                setDataImg({
                    images: ProductImages,
                    currentIndex: 0
                });
            };
            fetchData();
        }, []);

          const getCurrentImages2 =() =>{
            const { images, currentIndex } = DataPopularesImg;
            const sliceDataImg = DataImagen.slice(currentIndex, currentIndex + 5);
            const slice = images.slice(currentIndex, currentIndex + 5);
            return { ...slice, Images: slice, DataImg: sliceDataImg};
          }

          const getPrincipal = () =>{
           const {currentIndex } = DataPopularesImg;
           const Imgs =  getCurrentImages2().Images;
           const sliceDataImg = DataImagen.slice(currentIndex, currentIndex + 1);
           const slicePrinc = Imgs.slice(currentIndex,currentIndex + 1);
           return { ...Imgs, Images: slicePrinc, DataImg: sliceDataImg};
          }


        return(
            <div className="contenedorSn4">
                <div className="txt">
                    <div className="tituloh1">
                        <h1>{DataPopulares.title}</h1>
                    </div>
                    <span>{DataPopulares.description}</span>
                </div>

                <div className="Products">
                    <div className="ImagePrincipal">
                        {getPrincipal().Images.map((mapPopulares,index)=>(
                        <div className="imgBig" key={index}>
                            <Link to ={"/Catalogo/" + IdProduct[index] + "#Catalogo"} className="Link"> 
                            <div className="imgB">
                                <div className="images">
                                        <img 
                                        src={process.env.REACT_APP_UPLOAD_URL +  mapPopulares[0]?.attributes.formats.large.url} 
                                        alt='' className="mainImg"/> 
                                        <img 
                                        src={process.env.REACT_APP_UPLOAD_URL +  mapPopulares[1]?.attributes.formats.large.url} 
                                        alt='' className="SecondImg"/> 
                                        <div className="nombretelaBig">
                                        <h1>{getPrincipal().DataImg[index].Name}</h1>
                                        </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))}
                    </div>
                    <div className="ImagesRight">
                        <div className="contentImgTop">
                            {getCurrentImages2().Images.slice(1).map((mapPopulares,index)=>{
                            if(index % 2 == 0)
                                return(
                                <div className="img2top" key={index+1}>
                                        <Link to ={"/Catalogo/" + IdProduct[index+1]  + "#Catalogo"} className="Link">
                                        <div className="imgtop">
                                            <div className="images">
                                                <img 
                                                src={process.env.REACT_APP_UPLOAD_URL + mapPopulares[0]?.attributes.formats.medium.url} 
                                                alt='' className="mainImg"/>
                                                <img 
                                                src={process.env.REACT_APP_UPLOAD_URL + mapPopulares[1]?.attributes.formats.medium.url} 
                                                alt='' className="SecondImg" />

                                                <div className="nombretela">
                                                <h1>{getCurrentImages2().DataImg[index+1].Name}</h1>
                                                </div>
                                            </div>  
                                        </div>
                                        </Link>
                                    </div>
                                
                                )
                            })}
                        </div>
                        <div className="ContentImgBottom"> 
                        {getCurrentImages2().Images.slice(1).map((mapPopulares,index)=>{
                            if(index % 2 !== 0)
                                return(
                                <div className="img2Bottom" key={index+1}>
                                    <Link to ={"/Catalogo/" + IdProduct[index+1]  + "#Catalogo"} className="Link">
                                    <div className="imgBtn">
                                        <div className="images">
                                            <img 
                                                src={process.env.REACT_APP_UPLOAD_URL + mapPopulares[0]?.attributes.formats.medium.url} 
                                                alt='' className="mainImg"/>
                                                <img 
                                                src={process.env.REACT_APP_UPLOAD_URL + mapPopulares[1]?.attributes.formats.medium.url} 
                                                alt='' className="SecondImg" />
                                            <div className="nombretela">
                                            <h1>{getCurrentImages2().DataImg[index+1].Name}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
        );
};
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../RecuperarPwd/CompRecuperarPwd.scss"
import axios from 'axios';
import DotLoader from "react-spinners/ClipLoader";
import ReCAPTCHA from "react-google-recaptcha";

const CompRecuperarPwd = () => {
    const [email, setEmail] = useState("");
    const [SendRecPwd,setSendRecPwd] = useState(false)
    const [IsloadingSendPwd,setIsloadingSendPwd] = useState(false)
    const [ErrRecPwd,setErrRecPwd] = useState(false);
    const [ErrorUserNotFound,setErrorUserNotFound] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [NoCaptcha,setNoCaptcha] = useState(false);
    const KeyCaptcha = process.env.REACT_APP_KEY_PROD;


    const handleRecaptchaChange = (value) => {
      setRecaptchaValue(value);
    };

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    useEffect(() => {
      if (ErrRecPwd || ErrorUserNotFound || NoCaptcha) {
        const timer = setTimeout(() => {
          setErrRecPwd(false);
          setErrorUserNotFound(false);
          setNoCaptcha(false);
        }, 4000); //
        return () => clearTimeout(timer);
      }
    }, [ErrRecPwd, ErrorUserNotFound, NoCaptcha]);

    const handleEmailRecup = async (event)  =>{
      event.preventDefault();

      if(!recaptchaValue){
        setNoCaptcha(true)
        return;
      }

      setIsloadingSendPwd(true);   

      const Data = {
        email : email
      }
      await axios
        .post(process.env.REACT_APP_API_LINUM + '/api/send_email_recovery_password', Data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          if(response.status === 200){
            setSendRecPwd(true);
          }
          else{
            setErrRecPwd(true);
          }
          //console.log(response);
        })
        .catch((error) => {
          //console.log(error.response?.data?.detail);
          if(error.response?.data?.detail === "User doesnt exist"){
            setErrorUserNotFound(true)
          }
          else{
            setErrRecPwd(true);
          }
          console.error('Error send_email_recovery_password:', error);
        });
        setIsloadingSendPwd(false);   
    }
  return (
    <div className="contentRecuperarPwd">
      {!SendRecPwd ? 
        <>
          <div className="infoRecuperarPwd">
              <div className="title">Recuperar Contraseña</div>
              <div className='txt'>Escribe la dirección de correo electrónico asociado a tu cuenta de Linum.</div>
          </div>
          <div className="FormCorreo">
            <form onSubmit={handleEmailRecup}>
              <label className='label'>Dirección de correo electrónico</label>
              <input className='input' 
                type="email" 
                value={email} 
                onChange={handleEmailChange} 
                placeholder="Email" 
                autoComplete="on"
                required />
              <div className="captcha"> 
                <ReCAPTCHA
                  sitekey={KeyCaptcha}
                  onChange={handleRecaptchaChange}
                />
                {NoCaptcha && 
                  <div className="txtCaptcha">
                    Utiliza el captcha para continuar.
                  </div> 
                }
              </div>
              <div className="ContentButton">
              {!IsloadingSendPwd ? 
                <button type="submit">Enviar</button>
                :
                <DotLoader
                  color={"#1d4c5a"}
                  loading={IsloadingSendPwd}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  />
              }
              </div>
            </form>
          </div>
          {ErrRecPwd && <div className="txtError">Ocurrio un error intente mas</div>}
          {ErrorUserNotFound && <div className="txtError">El correo ingresado no coincide con ninguno de nuestros registros</div>}
        </>
        :
        <div className="SendEmailTxt">
          <div className="infoRecuperarPwd">
              <div className="title">Recuperar Contraseña</div>
          </div>
          <div className="txt">
            Se le ha enviado un correo a <b>{email}</b> con instrucciones para continuar el proceso de recuperación de contraseña.
          </div>
          <div className="button2">
              <Link to = "/Login" className='button Link'>INICIAR SESIÓN</Link>
          </div>
        </div>
      }
        
    </div>
  )
}

export default CompRecuperarPwd
import React from 'react'
import "../sn2/Seccion2.scss";
import {ComponentOfertas} from '../../../../components/Datos/sn2/Sn2Ofertas';

  const Seccion2 = () => {
    
    return (
      <div className="wrapperSeccion2">
        <ComponentOfertas/>
      </div>
    )
}

export default Seccion2
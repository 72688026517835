import {React, useState, useEffect } from "react";
import axios from "axios";
import "../../pages/Catalogo/Catalogo.scss";
import { Link, NavLink } from 'react-router-dom';
import CardProduct from "./CardProduct";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const ComponenteCatalogo = () => {
    const { idFam } = useParams();
    const [familiasSeleccionadas, setFamiliasSeleccionadas] = useState([]);
    const [PriceSeleccionados, setPriceSeleccionado] = useState([]);
    const [Family,setFamily] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const filMenu = searchParams.get('filMenu');
    const [selected, setSelected] = useState(null);
    const [CantProducts,SetCantProducts] = useState(null);
    const [LimiteBajaExistencia, SetLimiteBajaExistencia] = useState("5"); //default 5
  
    useEffect(() => {
        const fetchData = async () => {
            const catalogoData = await axios.get(process.env.REACT_APP_API_LINUM + '/api/parameters');
            const DataFull = catalogoData.data;

            const foundTime = DataFull.find(BajaExistencia => BajaExistencia.parameter === "reorder_point");
            SetLimiteBajaExistencia(foundTime.value);   
        };
        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const catalogoData = await axios.get(process.env.REACT_APP_API_URL + 'product-families?populate=*');
            const DataFull = catalogoData.data.data;
            setFamily(DataFull);   
          };
          fetchData();
    }, []);

    function handleCheckboxChange(event) {
        const familiaSeleccionada = event.target.value;
        const isChecked = event.target.checked;

        setFamiliasSeleccionadas(
            isChecked
            ?[...familiasSeleccionadas, familiaSeleccionada]
            : familiasSeleccionadas.filter((familia) => familia !== familiaSeleccionada)
            );

            navigate('/catalogo');
            setSelected('Inicio');
    };

    function handleCheckboxPriceChange(event) {
        const PriceSeleccionado = event.target.value;
        if (event.target.checked) {
            setPriceSeleccionado([...PriceSeleccionados, PriceSeleccionado]);
        } else {
            setPriceSeleccionado(
                PriceSeleccionados.filter((price) => price !== PriceSeleccionado)
        );
        }
    }

    const handleMenuItemClick = (item) => {setSelected(item);}
    const HandleCantProducts = (prod)=>{ SetCantProducts(prod);}

    return (
    <div className="contentCatalogo">
        <div className="rutaCatalogo">
        <Link to="/" className="Link"> Home </Link>  <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">Catálogo</div> 
        </div>
        <div className="FiltrosTop">
            <div className="title">
                <h1>Catálogo</h1>
            </div>
            <div className="Filtro">
                <div className="FiltrosItems">
                    <Link className={selected === 'Populares' ? 'selected' : 'Link'} onClick={() => handleMenuItemClick('Populares')}  to="/Catalogo?filMenu=populares">
                        <div className="ContentItem">
                            <div className="item">Populares</div>
                        </div>
                    </Link>
                    <Link className={selected === 'Ofertas' ? 'selected' : 'Link'} onClick={() => handleMenuItemClick('Ofertas')}  to="/Catalogo?filMenu=ofertas">
                        <div className="ContentItem">
                            <div className="item">Ofertas</div>
                        </div>
                    </Link>
                    <Link className={selected === 'Nuevas' ? 'selected' : 'Link'} onClick={() => handleMenuItemClick('Nuevas')}  to="/Catalogo?filMenu=nuevas">
                        <div className="ContentItem">
                            <div className="item">Nuevas</div>
                        </div>
                    </Link>
                    <Link className={selected === null ? 'selected' : 'Link'} onClick={() => handleMenuItemClick(null) } to="/Catalogo">
                        <div className="ContentItem">
                            <div className="item">Todas</div>   
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <div className="ProductosContent">
            <div className="ProductoLeft">
                <div className="Filtros">
                    <h1>Familia:</h1>
                    {Family.map((family,index)=>(
                        
                        <div className="inputItem" key={index}>
                            <input 
                            type="checkbox"
                            id={family.id}
                            value={family.id}
                            defaultChecked={parseInt(idFam)===family.id}
                            onChange={handleCheckboxChange} />
                            <label htmlFor={family.id}> {family.attributes.Family}</label>
                        </div>
                    ))}
                </div>
                <div className="Filtros">
                <h1>Precio:</h1>
                    <div className="inputItem">
                        <input type="checkbox"
                        value="price100"
                        checked={PriceSeleccionados.includes('price100')}
                        onChange={handleCheckboxPriceChange} />
                            <label>$100 - $250</label>
                    </div>
                    <div className="inputItem">
                        <input type="checkbox"
                        value="price250"
                        checked={PriceSeleccionados.includes('price250')}
                        onChange={handleCheckboxPriceChange} />
                            <label>$250 - $500</label>
                    </div>
                    <div className="inputItem">
                        <input type="checkbox"
                        value="price750"
                        checked={PriceSeleccionados.includes('price750')}
                        onChange={handleCheckboxPriceChange} />
                            <label>$750 - $1000</label>
                    </div>
                </div>
            </div>
            <div className="ProductoRight">
                <div className="ProductoOrden">
                    <div className="CantProductos">
                        <span> Viendo {CantProducts} productos</span>
                    </div>
                    {/*
                    <div className="OrdenProductos">
                        <label>Ordenar por:</label><input type="text"></input>
                    </div>
                    */}
                </div>
                <CardProduct 
                    Family={idFam ? [idFam]:familiasSeleccionadas} 
                    price={PriceSeleccionados} 
                    filMenu={filMenu}
                    LimiteBajaExistencia = {LimiteBajaExistencia}
                    CantProducts = {HandleCantProducts}/>
            </div>
        </div>
      </div>
    );
  }

import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import GradeIcon from "@mui/icons-material/Grade";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Link, useLocation } from "react-router-dom";
import {CantidadDetalle} from "./addit/CountCanitdad";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { addToCart } from "../../redux/CartReducer";
import CheckIcon from '@mui/icons-material/Check';
import { map } from "jquery";
import CartComp, { useReduxProducts } from "../Cart/CartComp";
import DotLoader from "react-spinners/ClipLoader";

export const ImageSlider = (props) => {
  const [Catalogo, setData] = useState([]);
  const [IDProduct, setIdProduct] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedCurrent, setcurrent] = useState([]);
  const [defaultImage, setDefaultImage] = useState([]);
  const [ImagesData, setImage] = useState([]);
  const [PriceProduct, setPrice] = useState('');
  const [Discount, setDiscount] = useState('');
  const dispatch = useDispatch();
  const refComponente = useRef();
  const [Applications, setApplications] = useState([]);
  const [Characteristics, setCharacteristics] = useState([]);
  const [LinumProductId, setLinumProductId] = useState([]);
  const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
  const [contador, setContador] = useState(1);
  const [isNewProduct,SetisNewProduct] = useState(false);
  const [RevProducts,SetRevProducts] = useState(false);
  const [Existencias, setExistencias] = useState("");
  const [ErrorExistencias,setErrorExistencias] = useState(false);
  const [ExistenciaCurrent, setExistenciaCurrent] = useState("")
  const [ChangeProducts,setChangeProducts] = useState(false);
  const [Loading,SetLoading] = useState(false);
  const [LimiteBajaExistencia, SetLimiteBajaExistencia] = useState("5"); //default 5

  useEffect(() => {
      const fetchData = async () => {
          const catalogoData = await axios.get(process.env.REACT_APP_API_LINUM + '/api/parameters');
          const DataFull = catalogoData.data;

          const foundTime = DataFull.find(BajaExistencia => BajaExistencia.parameter === "reorder_point");
          SetLimiteBajaExistencia(foundTime.value);   
      };
      fetchData();
  }, []);

  const iconsOK = [];
  for (let i = 0; i < 4; i++) {
    iconsOK.push(<GradeIcon key={i} />);
  }
  const iconsNOK = [];
  for (let i = 0; i < 1; i++) {
    iconsNOK.push(<StarBorderIcon key={i} />);
  }

  useReduxProducts(() => {
    setChangeProducts(true);
  });

  useEffect(() => {
    if (isNewProduct || ErrorExistencias || ChangeProducts) {
      const timer = setTimeout(() => {
        SetisNewProduct(false);
        setErrorExistencias(false)
        setChangeProducts(false);
      }, 3000); //

      return () => clearTimeout(timer);
    }
  }, [isNewProduct,ErrorExistencias, ChangeProducts]);

  useEffect(() => {
    reqProductos();
  },[props.id, isNewProduct, ChangeProducts, ErrorExistencias]);

  const reqProductos = () => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + "product-catalogues/" + props.id + "?populate=*"
      )
      .then((dataCatalogo) => {
        const dataFull = dataCatalogo.data.data.attributes;
        const dataIdProduct = dataCatalogo.data.data.id;
        const dataImg = dataFull.productImages.data;
        const defaultImg = dataFull.productImages.data[0].attributes.formats.large.url;
        const applications = dataFull.main_usages.data;
        const characteristics = dataFull.charactristics.data;
        const linumProductId = dataFull.LinumProductId;
        const priceProduct = dataFull.price;
        const discount = dataFull.discount === null ? null : dataFull.discount;
  
        setExistencias(dataFull.Existencias);
        setDiscount(discount);
        setIdProduct(dataIdProduct);
        setData(dataFull);
        setPrice(priceProduct);
        setImage(dataImg);
        setDefaultImage(process.env.REACT_APP_UPLOAD_URL + defaultImg);
        setApplications(applications);
        setCharacteristics(characteristics);
        setLinumProductId(linumProductId);
      })
      .catch((error) => {
        // Maneja los errores si es necesario
        console.error("Hubo un error al obtener los datos:", error);
        throw error; // Puedes lanzar el error nuevamente si es necesario
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const relatedProdsData = await axios.get(
        process.env.REACT_APP_API_URL +
          "product-catalogues/" +
          props.id +
          // "?http://164.92.93.185:1337/api/product-catalogues/1?http://164.92.93.185:1337/api/product-catalogues/1?populate[0]=related_products&populate[1]=related_products.ProductImages&populate[2]=related_products.price_catalogue&populate[3]=related_products.discount"
          "?http://164.92.93.185:1337/api/product-catalogues/1?http://164.92.93.185:1337/api/product-catalogues/1?populate[0]=related_products&populate[1]=related_products.productImages"
      );
      const relProducts = relatedProdsData.data.data.attributes.related_products.data
      let relProductsArray = [];
      relProductsArray = relProducts.map(prd => ({
        'id':prd.id,
        'LinumProductId':prd.attributes.LinumProductId,
        'name':prd.attributes.Name,
        // 'price':prd.attributes.price_catalogue.data.attributes.Price,
        'price':prd.attributes.price,
        // 'discount':prd.attributes.discount.data ? prd.attributes.discount.data.attributes.PercentDiscount : 0,
        'discount':prd.attributes.discount ? prd.attributes.discount : 0,
        'img': prd.attributes.productImages.data ? prd.attributes.productImages.data[0].attributes.formats.medium.url : ""
      }))
    // setRelatedProducts(relProductsArray)
    props.onRelatedProds(relProductsArray)
    };
    fetchData();
  },[props.id])

  useEffect(() =>{
    document.title = "Detalle Producto - Linum";
  },[Catalogo])

  // props.onRelatedProds(relatedProducts);
  
  const handleImageClick = (url, index) => {
    setSelectedImage(url);
    setcurrent(index);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slider",
  };
    
    //const ImagesCatalog = Catalogo.attributes.ImgUrl.data;
    const imageSlides = ImagesData.map((image) => (
        <div key={image.attributes.formats.large.url}>
          <img 
          src={selectedImage}
          alt="" />
        </div>
      ));
    
    const handleClick = () => {

      var cantidad = contador;
      if(!Catalogo.Existencias)
        return

      SetLoading(true);

      setTimeout(() => {
        axios
        .get(
          process.env.REACT_APP_API_URL + "product-catalogues/" + IDProduct + "?populate=*"
        )
        .then((dataCatalogo) => {
          const dataFull = dataCatalogo.data.data.attributes;
          if (dataFull.Existencias >= cantidad){
            dispatch(
              addToCart({
                id: IDProduct,
                LinumProductId,
                title: Catalogo.Name,
                priceD: Discount != null ? formatter.format(PriceProduct * [1 - Discount]) : "",
                price: formatter.format(PriceProduct),
                img: defaultImage,
                quantity: cantidad,
                existencias : dataFull.Existencias
              })
            );
            SetisNewProduct(true);
          }
          else{
            setErrorExistencias(true);
            setExistenciaCurrent(dataFull.Existencias);
          }
        })
        .catch((error) => {
          console.error("Hubo un error al obtener existencia addToCart:", error);
        });

        SetLoading(false);
      }, 500);
      
    }

    const CompCantidad = (CantidadAct) => {
      setContador(CantidadAct);
    };

    return (
    <div  className="contentCatalogo">
        <div className="rutaCatalogo">
          <Link to ="/Catalogo" className="Link">  Catálogo  </Link> <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">{Catalogo.Name}</div> 
        </div>
    <div className="topCatalogo">
        <div className="tituloMovil">
              <h1>{Catalogo.Name}</h1>
        </div>
        <div className="textoCatalogo">
                <div className="titulo">
                    <h1>{Catalogo.Name}</h1>
                </div>
                
                {!Catalogo.Existencias ? 
                <div className="existencias">
                  AGOTADO
                </div>
                  : 
                <>
                  {Catalogo.Existencias <= LimiteBajaExistencia &&
                    <div className="existencias Baja">
                      A PUNTO DE AGOTARSE
                    </div>
                  }
                </>
                }

                {/*<div className="rate">
                    <div className="icons">{iconsOK}{iconsNOK}</div>
              </div>*/}
                <div className="preciosContent">
                    {!Discount &&(
                    <div className="precio">
                        <div className="price">
                           {/* {formatter.format(PriceProduct.Price) + "/m"} */}
                           {formatter.format(PriceProduct) + "/m"}
                           </div>
                    </div>
                    )}
                    {Discount && (
                        <div className="precioDescuento">
                            <div className="priceD">
                              {/* {formatter.format(PriceProduct.Price * [1-Discount.PercentDiscount/100])+ "/m"} */}
                              {formatter.format(PriceProduct * [1-Discount])}
                              </div>
                            <div className = "priceT">
                              {/* {formatter.format(PriceProduct.Price)} */}
                              {formatter.format(PriceProduct)}
                              </div>
                        </div>
                    )}
                </div>
                <div className="cantidad">
                    <CantidadDetalle 
                      onValorCambiado={CompCantidad}
                      isInactive={!Catalogo.Existencias && true}/>
                </div>
                <Link onClick={!Loading && handleClick} className="Link">
                <div className={Catalogo.Existencias?"btnAgregar":"btnAgregar inactivo"} >
                    <div className="contentAgregar">
                      {!Loading ?
                      <>
                        AGREGAR
                        <div className={Catalogo.Existencias?"img":"img inactivo"}>
                          <img src="/img/producto/Cart.svg" className="icono"
                          alt=""/>
                        </div>
                      </>:
                      <DotLoader
                        color={"#1d4c5a"}
                        loading={Loading}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        />
                      }
                        
                    </div>
                </div>
                </Link>
                
                
                <div className="agregado">
                  {isNewProduct && <div className="txt">¡Se agregó a tu carrito de compra!</div>}
                  {ErrorExistencias && <div className="txt err">Lo Sentimos no contamos con los metros seleccionados. La existencia actual es de {ExistenciaCurrent}</div>}
                </div>
        </div>
        
        <div className="slideCatalogo">
            <Slider {...settings}>
            {
            selectedCurrent.length === 0 ?
            <div>
                <img
                src={defaultImage}
                alt="" />
            </div>
            :
            imageSlides
            }
            </Slider>
            <div className='thumbnail-container'>
                {ImagesData.map((image,index) => ( 
                <img
                    key={image.id} 
                    src={process.env.REACT_APP_UPLOAD_URL + image.attributes.formats.small.url}
                    alt=""
                    onClick={() => handleImageClick(process.env.REACT_APP_UPLOAD_URL + image.attributes.formats.large.url,index)}
                    />
                ))}
            </div>
        </div>
      </div>

      <div className="bottomCatalogo">
        <div className="bottomCatalogo__col">
          <h1>Caracteristicas</h1>
          <div className="descripcionCatalogo">
            <ul>
              {Characteristics.map((crh) => (
                <li key={crh.id}><div className="list_item"><CheckIcon /></div>{crh.attributes.Characteristic}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bottomCatalogo__col">
          <h1>Usos sugeridos</h1>
          <div className="descripcionCatalogo">
            <ul>
              {Applications.map((app) => (
                <li key={app.id}><div className="list_item"><CheckIcon /></div>{app.attributes.mainUsages}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
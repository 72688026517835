import {ComponenteCatalogo} from '../../components/Catalogo/CompCatalogo.jsx';
import "../Catalogo/Catalogo.scss";

const Catalogo = () => {

  return (
    <div className="wrapperCatalogo">
      <ComponenteCatalogo/>
    </div>
  )
}

export default Catalogo
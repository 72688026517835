import { useState, useEffect } from "react";
import axios from 'axios';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";

export const OfertasTop = () => {
    const [OfertaPrincipal, setDataPrinc] = useState([]);
    const [Price, setPrice] = useState([]);
    const [Discount, setDiscount] = useState([]);
    const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    
    useEffect(() => {
        const fetchData = async () => {
            // const Oferta = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?filters[discount][id][$null]=false&populate=*');
            const Oferta = await axios.get(process.env.REACT_APP_API_URL + 'product-catalogues?filters[discount][$null]=false&populate=*');
            const imgData = Oferta.data.data;
            const filteredDataOferta = imgData.map((Ofertas) => {
                const filteredOfertas = Ofertas.attributes.productImages.data
                  ? Ofertas.attributes.productImages.data.filter((Images) => Images !== null)
                  : null;

                  const filteredDiscount = Ofertas.attributes.discount
                  return { ...Ofertas, Images: filteredOfertas , discount: filteredDiscount };

              }).filter((Ofertas) => Ofertas.Images !== null);

            const PriceProduct = filteredDataOferta.map(price =>price.attributes.price);
            const Discount =filteredDataOferta.map(discount =>discount.discount);

            setDiscount(Discount);
            setPrice(PriceProduct);
            setDataPrinc(filteredDataOferta)
        };
        fetchData();
    }, []);
    
    return(
        <div className="contentOfertasTop">
            {OfertaPrincipal.map((OfertaPrin, index)=> (
            <div className={
                index % 2 === 0
                  ? "itemOferta"
                  : "itemOferta itemOferta-reverse"
              } key={index}>
                <div className="imagen">
                <Link to={"/Catalogo/" + OfertaPrin.id} className="btn">
                    <img 
                    src={process.env.REACT_APP_UPLOAD_URL + OfertaPrin.Images[0].attributes.formats.large.url} alt=""/>
                </Link>
                </div>
                <div className="contentTexto">
                    <div className="textoOferta">
                        <div className="titulo">
                            {OfertaPrin.attributes.Name}
                        </div>
                        <div className="descuento">
                        {/* {formatter.format(Price[index].Price * [1-Discount[index].PercentDiscount/100])} */}
                        {formatter.format(Price[index] * [1-Discount[index]])}
                        </div>
                        <div className="descripcion">
                        {formatter.format(Price[index])}
                        </div>
                        <div className="botones">
                            <Link to={"/Catalogo/" + OfertaPrin.id} className="btn">PRODUCTO<EastIcon className='icon'/></Link>
                        </div>
                    </div>
                </div>
            </div>
            ))}
        </div>
    )

}

export const OfertasBottom = () => {
    const [OfertaSecundaria, setDataPrinc] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            const OfertaDSecundaria = await axios.get(process.env.REACT_APP_API_URL + 'mofertas?filters[Nivel][Nivel][$eq]=Secundaria&populate=*');
            setDataPrinc(OfertaDSecundaria.data.data)
        };
        fetchData();
    }, []);

    return(
        <div className="contentOfertasBottom">
            {OfertaSecundaria.map((OfertaSec,index) =>{
                const descuento = OfertaSec.attributes.descuento.split('%');
                return(
                    <div className="itemOferta" key={index}>
                        <div className="imagen">
                            <img 
                            src={process.env.REACT_APP_UPLOAD_URL + OfertaSec.attributes.ImgUrl.data.attributes.url} alt=""></img>
                            
                        </div>
                        <div className="descuento">
                                <h1>{descuento[0]}%</h1>
                                <h2>{descuento[1]}</h2>
                            </div>
                            <div className="botones"><Link to="/Catalogo" className="btn"><h1>PRODUCTO</h1><EastIcon className='icon'/></Link></div>
                    </div>
                    )
                })}
        </div>
    )

}
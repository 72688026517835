import {React, useState, useEffect } from "react";
import axios from "axios";
import "./Policy.scss"

const Policy = () => {
const [Data,setData] = useState("");

  useEffect(()=>{
    const fetchData = async () => {
      const catalogoData = await axios.get(process.env.REACT_APP_API_URL + 'privacy-policy');
      const DataFull = catalogoData.data.data;
      setData(DataFull.attributes);   
    };
    fetchData();
  },[])
  

  const textos = Data.Body?.split('\n');

  return (
    <div className="wrapperPolicy">
      <div className="contentPolicy">
        <div className="title">{Data.Title}</div>
        <div className="contetntxt">
        {textos?.map((texto, index) => (
            <>
            {texto}
            <br />
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Policy
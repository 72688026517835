import React from 'react'

const CompResumen = (props) => {

    const formatter = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    const data = props.data;

    const PriceProduct = (price,cant) => { // retornamos precio de producto x la cantidad 
        const precio = parseFloat(price.replace('$', ''));
        const PricePr = cant * precio;
        return PricePr.toFixed(2);
    }

    const dataMetodoSpei = () =>{
    return{
        "Banco": "Banregio",
        "Razón Social": "LINUMCO S.A. DE C.V.",
        "RFC": "LIN160712BK7",
        "Cuenta": "035033710010",
        "CLABE": "058580350337100109",
        }
            
    }
  return (
    <>
        {props.IDSeg &&
            <div className="IdPedido">
                PEDIDO : {props.IDSeg}
            </div>
        }
        <div className="ItemResumen">
            <div className="title">
                DIRECCIÓN DE ENVÍO
                <hr/>
            </div>
            
            <div className="info">
                {data?.DataDeivery?.address ?   
                data?.DataDeivery?.address + data?.DataDeivery?.street_number + "," + data?.DataDeivery?.neighborhood + "," + data?.DataDeivery?.city
                + "," + data?.DataDeivery?.country+ "," +data?.DataDeivery?.postal_code
                :
                data?.DataDeivery
                }
            </div>               
        </div>
        <div className="ItemResumen">
            <div className="title">
                MÉTODO DE PAGO
                <hr/>
            </div>
            <div className="info">
                {data?.Metodo === "spei" ?
                        <div className="cuadroData">
                        <ul>
                            {Object.entries(data.MetodoPago? data.MetodoPago : dataMetodoSpei()).map(([key, value]) => (
                                <li key={key}>{key}: {value}</li>
                            ))}
                        </ul>
                    </div>
                    :
                    <>
                    <div className="titleTC">Tarjeta de crédito</div>
                    {data?.MetodoPago?.DatosTC}
                    {data?.DataPago?.credit_card}
                    </>
                }
            </div>
        </div>
        <div className="ItemResumen">
            <div className="title">
                DETALLE DE PRODUCTOS
                <hr/>
            </div>
            <div className="contentProductos">
                    <div className="productos">
                        <div className="headersProd">
                            <div>Producto</div>
                            <div>Cantidad</div>
                            <div>Precio</div>
                            <div>Total</div>
                        </div>
                        {data?.products?.map((item) => (
                        <div className="itemproducto" key={item.id}>
                            <div className="cell">
                                <div className="imagen">
                                    <img src={item.img} alt="" />
                                    <div className="titleProd">{item.title}</div>
                                </div>
                            </div>          
                            <div className="cell ">
                                {item.quantity}
                            </div>
                            <div className="cell ">
                                {item.priceD.length > 0 ? item.priceD : item.price}
                            </div>
                            <div className="cell">
                                <div className="total">
                                    <p>
                                        {formatter.format(PriceProduct(item.priceD.length > 0 ? item.priceD : item.price,item.quantity))}
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                        ))}
                    </div>
            </div>
            <div className="totalPrecio">
                <div className="TotalEnvio">
                    <div className='title'>Sub Total: </div>
                    <div className='precio'>
                        {formatter.format(data?.DataPrecio?.SubTotal)}
                    </div>
                </div>
                <div className="TotalEnvio">
                    <div className='title'>Envio: </div>
                    <div className='precio letra'>
                        {data?.DataPrecio?.ShippingCost}
                    </div>
                </div>
                <div className="TotalEnvio">
                    <div className='title bold'>Total:</div>
                    <div className='precio'>
                        {formatter.format(data?.DataPrecio?.Total)}
                    </div>
                </div>
                <div className="TotalEnvio">
                    <div className="txt">
                        Entrega estimada {data?.EstimatedDate}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}


export default CompResumen
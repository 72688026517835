import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import CompLogin from '../../components/Login/CompLogin'
import "../Login/Login.scss";

const Login = () => {
    const navigate = useNavigate();
    const state = localStorage.getItem("Logged");
  
    useEffect(()=>{
      if(state){
          navigate('/Account');
      }
    },[])
    
    return (
        <div className="wrapperLogin">
          <CompLogin/>
        </div>
      )
}

export default Login

import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "../Login/CompLogin.scss";
import axios from 'axios';
import Cookies from 'js-cookie';

const CompLogin = (props) => {
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ErrorLogin, setErrorLogin] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    if (ErrorLogin) {
      const timer = setTimeout(() => {
        setErrorLogin(false);
      }, 3000); //

      return () => clearTimeout(timer);
    }
  }, [ErrorLogin]);

  

  const handleEmailChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const LoginData = {
      email: email,
      password: password,
    }

    axios
        .post(process.env.REACT_APP_API_LINUM + '/api/login', LoginData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if(response.data.status)
            {
              localStorage.setItem("Logged", true);
              const jsonData = JSON.stringify(response.data);
              localStorage.setItem("SessionUser", jsonData);
              if(props.Ref)
                navigate(props.Ref, { state: "RutaDePago" })
              else
                navigate("/Account");
            }
        })
        .catch((error) => {
          if(error.response?.data.detail){
            setErrorLogin(error.response?.data.detail)
            
          }else{
            setErrorLogin("Ocurrio un error, intente mas tarde")
          }
            
            console.error('Error al iniciar sesión:', error);
        });

    
  };

  const HandleRegister = (event) => {
    event.preventDefault();
    navigate("/Register", {state: props.Ref})
  }

  return (
      <>
      <div className="contentLogin">
        <h2 className='titulo'>Iniciar sesión</h2>
        <form onSubmit={handleLogin} className='form'>
          <label className='label'>Email</label>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" required />
          <label className='label'>Contraseña</label>
          <input type="password" value={password} 
          onChange={handlePasswordChange} 
          placeholder="Contraseña" 
          minLength="8"
          required />
          <button type="submit">INICIAR SESIÓN</button>
        </form>
        <div className="Links">
            <Link onClick={HandleRegister} className='Link'>Crear cuenta</Link>
            <Link to="/RecuperarPwd" className='Link'>¿Olvidaste tu contraseña?</Link>
          </div>
          <div className="errorlogin">{ErrorLogin && ErrorLogin}</div>
      </div>
      </>
  );
};

export default CompLogin;
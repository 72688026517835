import React from 'react'
import "../sn3/Seccion3.scss";
import {Sn3Calidad} from '../../../../components/Datos/sn3/sn3Calidad';

const Seccion3 = () => {

  return (
    <div className="wrapperSeccion3">
          <Sn3Calidad/>
      </div>
  )
}

export default Seccion3
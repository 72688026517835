import React,{useEffect, useState} from 'react'
import "./FormDirecciones.scss"
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const FormDirecciones = (props) => {
    const navigate = useNavigate();
    const [calle, setcalle] = useState('');
    const [NumInterior, setNumInterior] = useState('');
    const [NumExterior, setNumExterior] = useState('');
    const [Estado, setEstado] = useState('');
    const [nombre, setnombre] = useState('');
    const [Ciudad, setCiudad] = useState('');
    const [Colonia, setColonia] = useState('');
    const [CodigoP, setCodigoP] = useState("");
    const [Pais , setPais] = useState("");
    const [telefono , setTelefono] = useState("");
    const [Referencia, setReferencia] = useState('');
    const [ErrorRegister, setErrorRegister] = useState("");
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const [response, SetData] = useState();
    const [DireccionCreate, SetDireccionCreate] = useState(false);
    const [DataEstados, setDataEstados] = useState();
    const [DataUpdate,setDataUpdate] = useState('');
    const currentDate = new Date();


    useEffect(()=>{ // traemos listado de estados
      axios
      .get(process.env.REACT_APP_API_LINUM + '/api/cities', {
          headers: {
              'Authorization': 'Bearer ' + SessionUser.access_token
          }
      })
      .then((response) => {
          setDataEstados(response.data);
      })
      .catch((error) => {
        if(error.response?.status === 401){
            localStorage.removeItem('SessionUser');
            localStorage.removeItem('Logged');
            navigate("/CartAuth")
        }else
          console.error('Error delivery_points:', error);
      });
    },[])

    useEffect(()=>{ // buscamos datos en caso de  editar
      if(props.EditDireccion !== null)
      {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/delivery_points/' + props.EditDireccion, {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
            ////console.log(response.data)
              setDataUpdate(response.data.delivery_point);
              setcalle(response.data.delivery_point.address)
              setEstado(response.data.delivery_point.state_id)
              setnombre(response.data.delivery_point.contact_name)
              setCiudad(response.data.delivery_point.city)
              setCodigoP(response.data.delivery_point.postal_code)
              setColonia(response.data.delivery_point.neighborhood)
              setPais(response.data.delivery_point.country)
              setTelefono(response.data.delivery_point.phone)
              setNumExterior(response.data.delivery_point.street_number);
              setNumInterior(response.data.delivery_point.suite_number);
              setReferencia(response.data.delivery_point.reference);

          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error delivery_points:', error);
          });
      }
    },[DireccionCreate])

    const handleNombreChange = (event) => {setnombre(event.target.value);};
    const handlecalleChange = (event) => {setcalle(event.target.value);};
    const handleNumInteriorChange = (event) => {setNumInterior(event.target.value);};
    const handleNumExteriorChange = (event) => {setNumExterior(event.target.value);};
    const handleReferenciaChange = (event) => {setReferencia(event.target.value);};
    const handleEstadoChange = (event) => {setEstado(event.target.value);};
    const handleCiudadChange = (event) => {setCiudad(event.target.value);};
    const handleColoniaChange = (event) => {setColonia(event.target.value);};
    const handleCodigoPChange = (event) => {setCodigoP(event.target.value);};
    const handlePaisPChange = (event) => {setPais(event.target.value);};
    const handleTelefonoChange = (event) => {setTelefono(event.target.value);};
        
    const formatDate = (date) => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Agregar 1 al mes, ya que está indexado desde 0.
      const day = currentDate.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      return formattedDate;
    };


    const handleRegister = (event) => {
        event.preventDefault();

        const Data =
          {
            address: calle,
            reference: Referencia,
            entry_date : formatDate(currentDate),
            city: Ciudad,
            state_id: Estado,
            postal_code: CodigoP,
            contact_name: nombre,
            neighborhood : Colonia,
            country : Pais,
            phone: telefono,
            street_number: NumExterior,
            suite_number: NumInterior
          }

        axios
        .post(process.env.REACT_APP_API_LINUM + '/api/delivery_points', Data, {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            if(response.data?.status === "success")
            {
                SetDireccionCreate(true);
                props?.onRegistroAgregado(true);
            }

        })
        .catch((error) => {
          if(error.response?.status === 401){
            localStorage.removeItem('SessionUser');
            localStorage.removeItem('Logged');
            if(props.Ref)
              navigate(props.Ref, { state: "RutaDePago" })
            else
            navigate("/CartAuth")
          }else{
            console.error('Error delivery_points_add:', error);
          }
          setErrorRegister(error.response.data.detail);
        });

      }

    const handleUpdate = (event) =>{
      event.preventDefault();
      event.preventDefault();

        const Data =
          {
            reference: Referencia,
            state_id: Estado,
            id: props.EditDireccion,
            contact_name: nombre,
            entry_date : formatDate(currentDate),
            city: Ciudad,
            address: calle,
            postal_code: CodigoP,
            neighborhood : Colonia,
            country : Pais,
            phone: telefono,
            street_number: NumExterior,
            suite_number: NumInterior,
            deleted: false
          }

        ////console.log(Data);

        axios
        .put(process.env.REACT_APP_API_LINUM + '/api/delivery_points/' + props.EditDireccion,  Data, {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            ////console.log(response);
            if(response.data?.status === "success")
            {
                SetDireccionCreate(true);
                props?.onRegistroAgregado(true);
            }

        })
        .catch((error) => {
          if(error.response?.status === 401){
            localStorage.removeItem('SessionUser');
            localStorage.removeItem('Logged');
            if(props.Ref)
              navigate(props.Ref, { state: "RutaDePago" })
            else
            navigate("/CartAuth")
          }else{
            console.error('Error delivery_points_edit:', error);
          }
          setErrorRegister(error.response.data.detail);
        });
    }

    const handleCloseComp = () => {
      props.closeAddDireccion(true);
    }

    

  return (
    <div className="contentAddDireccion">
        
        {props.EditDireccion ? <h2 className='titulo'> Editar Dirección </h2>:
         <h2 className='titulo'>Agregar Direccion </h2>}
          

        { !DireccionCreate ?
          <form onSubmit={props.EditDireccion? handleUpdate : handleRegister } className='form'>
            <div className="row">
              <div className='FormRow'>
                <label className='label'>Nombre de contácto</label>
                <input 
                  type="text" 
                  value={nombre} 
                  onChange={handleNombreChange} 
                  placeholder="Nombre" required />
              </div>
              <div className='FormRow'>
                <label className='label'>Calle</label>
                <input 
                  type="text" 
                  value={calle}
                  onChange={handlecalleChange} 
                  placeholder="Calle" 
                  required 
                  />
              </div>
            </div>
            <div className="row">
              <div className='FormRow'>
                <label className='label'>Nº Exterior</label>
                <input 
                  type="text" 
                  value={NumExterior}
                  onChange={handleNumExteriorChange} 
                  placeholder="Numero Exterior" 
                  required 
                  />
              </div>
              <div className='FormRow'>
                <label className='label'>Nº interior/Depto</label>
                <input 
                  type="text" 
                  value={NumInterior} 
                  onChange={handleNumInteriorChange} 
                  placeholder="Numero Interior" />
              </div>
            </div>

            <div className="row">
            <div className='FormRow'>
                <label className='label'>Estado/Provincia/Region</label>
                <select
                onChange={handleEstadoChange} 
                value={Estado}
                required>
                  <option>- Selecciona una opción -</option>
                  {DataEstados?.map(state => (
                    <option key={state.id} value={state.id}>{state.state}</option>
                  ))}
                </select>
              </div>
              <div className='FormRow'>
                <label className='label'>Ciudad </label>
                <input 
                  type="text" 
                  value={Ciudad}
                  onChange={handleCiudadChange} 
                  placeholder="Ciudad" 
                  required 
                  />
               </div>
            </div>

            <div className="row">
            <div className='FormRow'>
                <label className='label'>Colonia</label>
                <input 
                  type="text" 
                  value={Colonia}
                  onChange={handleColoniaChange} 
                  placeholder="Colonia" 
                  required 
                  />
                </div>
                <div className='FormRow'>
                  <label className='label'>Codigo Postal</label>
                  <input 
                    type="text" 
                    value={CodigoP}
                    onChange={handleCodigoPChange} 
                    placeholder="Codigo Postal" 
                    required 
                    />
                  </div>
              </div>

              <div className="row">
              <div className='FormRow'>
                <label className='label'>Pais o región</label>
                <input 
                  type="text" 
                  value={Pais}
                  onChange={handlePaisPChange} 
                  placeholder="Pais o región" 
                  required 
                  />
                </div>
                <div className='FormRow'>
                <label className='label'>Teléfono</label>
                <input 
                  type="text" 
                  value={telefono}
                  onChange={handleTelefonoChange} 
                  placeholder="Teléfono a 10 digitos" 
                  title="Ingresa los 10 digitos de tu télefono"
                  pattern="[0-9]{10}"
                  maxLength="10"
                  inputMode="numeric"
                  required 
                 />
                 </div>
              </div>
              <div className="row rowUno">
              <div className='FormRow'>
                <label className='label'>Referencia</label>
                <input 
                  type="text" 
                  value={Referencia}
                  onChange={handleReferenciaChange} 
                  placeholder="Indicaciones adicionales"  
                  />
                </div>
              </div>
              <div className="ContentBtn">
                {!props.EditDireccion?
                <button type="submit">AGREGAR</button>:
                <button type="submit">ACTUALIZAR</button>
                }
              </div>
            <div className="mejerror">
              {ErrorRegister && ErrorRegister}
            </div>
          </form> :
          
          <div className="usuariocreado">
            <p>Actualización con éxito</p>
            {
            props.Ref === "/Account" &&
              <Link onClick={handleCloseComp}>Mis direcciones</Link>
            }
          </div>
        }
    </div>
  )
}

export default FormDirecciones
import React,{useEffect, useState} from 'react'
import {Link, useNavigate } from 'react-router-dom';
import "./FormFactura.scss"
import axios from 'axios';

const FormFactura = (props) => {
    const navigate = useNavigate();
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [nombre, setnombre] = useState('');
    const [RFC, setRFC] = useState('');
    const [Regimen, setRegimen] = useState('');
    const [UsoCFDI, setUsoCFDI] = useState('');
    const [CP, setCP] = useState('');
    const [ErrorRegister, setErrorRegister] = useState("");
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const [DireccionCreate, SetDireccionCreate] = useState(false);
    const currentDate = new Date();
    const [OptionsRegimen,SetOptionsRegimen] = useState([]);
    const [OptionsUsoCFDI,SetOptionsUsoCFDI] = useState([]);


    useEffect(()=>{ // buscamos datos en caso de  editar
        if(props.EditDireccion !== null)
        {
          axios
            .get(process.env.REACT_APP_API_LINUM + '/api/invoice_data/' + props.EditDireccion, {
                headers: {
                    'Authorization': 'Bearer ' + SessionUser.access_token
                }
            })
            .then((response) => {
               const data_invoice = response.data.invoice_data
               setAddress(data_invoice.address);
               setEmail(data_invoice.email);
               setRFC(data_invoice.tax_id);
               setnombre(data_invoice.name);
               setCP(data_invoice.cp);
               setRegimen(data_invoice.tax_regime);
               setUsoCFDI(data_invoice.invoice_use);
            })
            .catch((error) => {
              if(error.response?.status === 401){
                  localStorage.removeItem('SessionUser');
                  localStorage.removeItem('Logged');
                  navigate("/login")
              }else
                console.error('Error delivery_points:', error);
            });
        }
    },[DireccionCreate])

    useEffect(() => { //Obtenemos listado json del regimen fiscal 
        fetch('json/Facturas/DataRegimen.json')
            .then(response => response.json())
            .then(data => {
              SetOptionsRegimen(data);
              ////console.log(data);
            })
            .catch(error => console.error('Error al cargar el archivo DataRegimen JSON:', error));
    }, []);

    useEffect(() => { // Obtenemos listado json de UsoCFDI
      fetch('json/Facturas/DataUsoCFDI.json')
          .then(response => response.json())
          .then(data => {
            SetOptionsUsoCFDI(data);
              ////console.log(data);
          })
          .catch(error => console.error('Error al cargar el archivo DataUsoCFDI JSON:', error));
    }, []);

    const handleNombreChange = (event) => {setnombre(event.target.value);};
    const handleEmailChange = (event) => {setEmail(event.target.value);};
    const handleRFCChange = (event) => {
      const newValue = event.target.value.toUpperCase();
      setRFC(newValue);
    };
    const handleAddressChange = (event) => {setAddress(event.target.value);};
    const handleRegimenChange = (event) => {setRegimen(event.target.value);};
    const handleUsoCFDIChange = (event) => {setUsoCFDI(event.target.value);};
    const handleCPChange = (event) => {setCP(event.target.value);};

    const handleCloseComp = () => {
      props.closeAddDireccion(true);
    }

    const formatDate = (date) => {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Agregar 1 al mes, ya que está indexado desde 0.
      const day = currentDate.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      return formattedDate;
    };

      
    const handleRegister = (event) => {
      event.preventDefault();

      const Data =
        {
          name: nombre,
          tax_id: RFC,
          email: email,
          address: address,
          invoice_use : UsoCFDI,
          tax_regime : Regimen,
          cp: CP
        }

      axios
      .post(process.env.REACT_APP_API_LINUM + '/api/invoice_data', Data, {
          headers: {
              'Authorization': 'Bearer ' + SessionUser.access_token
          }
      })
      .then((response) => {
          if(response.data?.status === "success")
          {
              SetDireccionCreate(true);
              props?.onRegistroAgregado(true);
          }

      })
      .catch((error) => {
        if(error.response?.status === 401){
          localStorage.removeItem('SessionUser');
          localStorage.removeItem('Logged');
          if(props.Ref)
            navigate(props.Ref, { state: "RutaDePago" })
          else
            navigate("/Login")
        }else{
          console.error('Error delivery_points_add:', error);
        }
        setErrorRegister(error.response.data.detail);
      });
    }

    const handleUpdate = (event)=>{
      event.preventDefault();

      const Data =
        {
          name: nombre,
          email: email,
          address: address,
          entry_date : formatDate(currentDate),
          tax_id: RFC,
          id : props.EditDireccion,
          invoice_use : UsoCFDI,
          tax_regime : Regimen,
          cp: CP,
          deleted: false
        }

      axios
      .put(process.env.REACT_APP_API_LINUM + '/api/invoice_data/' + props.EditDireccion, Data, {
          headers: {
              'Authorization': 'Bearer ' + SessionUser.access_token
          }
      })
      .then((response) => {
          if(response.data?.status === "success")
          {
              SetDireccionCreate(true);
              props?.onRegistroAgregado(true);
          }

      })
      .catch((error) => {
        if(error.response?.status === 401){
          localStorage.removeItem('SessionUser');
          localStorage.removeItem('Logged');
          if(props.Ref)
            navigate(props.Ref, { state: "RutaDePago" })
          else
            navigate("/Login")
        }else{
          console.error('Error delivery_points_add:', error);
        }
        setErrorRegister(error.response.data.detail);
      });
    }

      const handleNewDireccion = ()=>{
        SetDireccionCreate(false);
      }



  return (
    <div className="contentFacturacion">

        {props.EditDireccion ? <h2 className='titulo'> Editar datos de facturación </h2>:
        <h2 className='titulo'>Agregar datos de facturación</h2>}

        { !DireccionCreate ?
          <form onSubmit={props.EditDireccion? handleUpdate:handleRegister  } className='form'>
            <div className="row rowUno">
              <div className='FormRow '>
                <label className='label'>Razon Social o Nombre</label>
                <input 
                  type="text" 
                  value={nombre} 
                  onChange={handleNombreChange} 
                  placeholder="Nombre" required />
              </div>
            </div>

            <div className="row">
            <div className='FormRow'>
                <label className='label'>RFC</label>
                <input 
                  type="text" 
                  value={RFC}
                  onChange={handleRFCChange} 
                  placeholder="RFC" 
                  required 
                  />
              </div>
              <div className='FormRow'>
                <label className='label'>Correo electronico</label>
                <input 
                  type="text" 
                  value={email}
                  onChange={handleEmailChange} 
                  placeholder="email" 
                  required 
                  />
              </div>
            </div>
            <div className="row">
              <div className='FormRow'>
                <label className='label'>Regimen Fiscal </label>
                  <select
                  onChange={handleRegimenChange} 
                  value={Regimen}
                  required>
                    <option value="">- Selecciona una opción -</option>
                    {OptionsRegimen?.map(state => (
                    <option key={state.Value} value={state.Value}>{state.Name}</option>
                  ))}
                  </select>               
               </div>
               <div className='FormRow'>
                <label className='label'>Uso del CFDI</label>
                <select
                  onChange={handleUsoCFDIChange} 
                  value={UsoCFDI}
                  required>
                    <option value="">- Selecciona una opción -</option>
                    {OptionsUsoCFDI?.map(state => (
                      <option key={state.Value} value={state.Value}>{state.Name}</option>
                    ))}
                  </select>   
              </div>
            </div>
            <div className="row">      
              <div className='FormRow'>
                <label className='label'>Codigo Postal</label>
                <input 
                  type="text" 
                  value={CP}
                  onChange={handleCPChange} 
                  placeholder="CP" 
                  required 
                  />
              </div>
              <div className='FormRow'>
                <label className='label'>Dirección (opcional) </label>
                <input 
                  type="text" 
                  value={address}
                  onChange={handleAddressChange} 
                  placeholder="Dirección" 
                  />
               </div>
            </div>
              <div className="ContentBtn">
               {!props.EditDireccion?
                <button type="submit">AGREGAR</button>:
                <button type="submit">ACTUALIZAR</button>
                }
              </div>
            <div className="mejerror">
              {ErrorRegister && ErrorRegister}
            </div>
          </form> :
          
          <div className="usuariocreado">
            <p>Actualización con éxito</p>
            {
            props.Ref === "/Account" &&
              <Link onClick={handleCloseComp}>Datos de facturacion</Link>
            }
          </div>
        }
    </div>
  )
}

export default FormFactura
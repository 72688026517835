import React from 'react'
import {useLocation} from 'react-router-dom'
import CompRegister from '../../components/Login/Register/CompRegister'
import "../Login/Login.scss";

const Register = () => {
  const location = useLocation();
  const metodo = location.state;
  
  return (
    <div className="wrapperLogin">
      <CompRegister Ref = {metodo}/>
    </div>
  )
}

export default Register
import axios from 'axios';
import React,{useEffect, useState} from 'react'
import {Link, useNavigate } from 'react-router-dom'
import "../Direcciones/CompDirecciones.scss"
import AddressCard from '../../Cart/Delivery/AddressCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FormDirecciones from './FormDirecciones/FormDirecciones';

const CompDirecciones = () => {
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const navigate = useNavigate();
    const [selectedDireccion, setSelectedDireccion] = useState(null)
    const [isAddAdress , setisAddAdress] = useState(false);
    const [DeleteAddress,SetDeleteAddress] = useState(false);
    const [newAddress, SetNewAddress] = useState(false);
    const [IsEditDireccion,setIsEditDireccion] = useState(null);
    const [dataCreditCard , SetData] = useState([]);


    useEffect(() => {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/delivery_points', {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
              SetData(response.data.deliveries);
              SetNewAddress(false);
              SetDeleteAddress(false);

          })
          .catch((error) => {
            if(error.response?.status === 401 || error.code === 'ERR_NETWORK'){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error delivery_points:', error);
          });
    }, [DeleteAddress,newAddress]);

    const handleAddressCard = (Address) =>{
        ////console.log(Address);
    }

    const handleopenAddress = () => {
        if(!isAddAdress)
            setisAddAdress(true);
        else
        {
            setisAddAdress(false);
            setIsEditDireccion(null);
        }
    }
    const handleRegistroEliminado = (DeleteAddress) => {  //Manejador para saber si se elimino un registro
        if(DeleteAddress)
          SetDeleteAddress(true);
      };

      const handleRegistroAgregado = (newAddress) => {  //Manejador para saber si se agrego un nuevo registro
        if(newAddress)
          SetNewAddress(true);
      };

      const handleCloseAddDireccion = () =>{
        setisAddAdress(false);
      }

      const handleEditDireccion = (EditDireccion) =>{
        if(EditDireccion){
            setIsEditDireccion(EditDireccion);
            setisAddAdress(true);
        }
      }


  return (
    <div className="wrapperDirecciones">

        <div className="tituloDirecciones">Mis Direcciones</div>
            {!isAddAdress ?
                <div className="ContentDirecciones">
                    <div className="ContentBtn">
                        <Link className='add' onClick={handleopenAddress}>AGREGAR DIRECCIÓN</Link>
                    </div>
                    <div className="AccountDirecciones">
                        {dataCreditCard.map((Address,index)=>(
                        <AddressCard
                            Adrs = {Address} 
                            selected = {selectedDireccion} 
                            onClick={() => handleAddressCard(Address)}
                            onRegistroEliminado = {handleRegistroEliminado}
                            onEditDireccion = {handleEditDireccion}
                            comp = "perfil"
                            />
                        ))}
                    </div>
                </div>:
                <div className="ContentAddAddress">
                    <Link className='Link' onClick={handleopenAddress}>
                        <ArrowBackIosNewIcon/>
                    </Link>
                    <div className="AddAddress">
                        <FormDirecciones  
                        onRegistroAgregado = {handleRegistroAgregado} 
                        closeAddDireccion = {handleCloseAddDireccion}
                        EditDireccion = {IsEditDireccion}
                        Ref = "/Account" />
                    </div>
                </div>
            }
        </div>
    


  )
}

export default CompDirecciones
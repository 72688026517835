import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../Resumen/Resumen.scss"
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import CompResumen from './CompResumen';
import DotLoader from "react-spinners/ClipLoader";

const Resumen = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const data = location.state;
    const products = useSelector(state=>state.cart.products);
    const [isToggled, setIsToggled] = useState(false);
    const [isLoading,SetisLoading] = useState(false);
    const previousDataRef = useRef(products);
    const [ErrPago , setErrPago] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [is320, setis320] = useState(false);
    const secretKey = 'JvsZoZ4Zd83gGROprT8n4VjeANBvEJV7Mox4Un5q4sI='; // La misma clave usada en el servidor


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 900);
          setis320(window.innerWidth <= 320);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (products.length === 0 || products !== previousDataRef.current || data === null) {
          // Se detectó un cambio en los datos
          navigate('/Orden');
          // Actualizar el estado anterior con el estado actual
          previousDataRef.current = products;
        }
      }, [products, navigate, data]);

    const handleMethod = (event) =>{
        event.preventDefault();
        
        const params = {
            ruta : "RutaDePago",
            shippingCost: data.DataPrecio.ShippingCost,
            PrecioTotal : parseInt(data.DataPrecio.Total),
            Delivery: data.DataDeivery,
            EstimatedDate:  data.EstimatedDate
        }

          navigate('/CheckOut', { state: params });
    }      
      
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    const CloseModal = () => { 
        setModalIsOpen(false);
        SetisLoading(false);
    }

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(227, 227, 227, 0.3)', // Aquí, 0.8 representa el 80% de opacidad
        },
        content: {
          overflow:"hidden",
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '80%' : '25%', 
          height: isMobile ? is320 ? '40%':'30%' : '30%',
          background: 'white',
          padding:'0px',
        }
    };

    const dataMetodoSpei = () =>{
        return{
            "Banco": "Banregio",
            "Razón Social": "LINUMCO S.A. DE C.V.",
            "RFC": "LIN160712BK7",
            "Cuenta": "035033710010",
            "CLABE": "058580350337100109",
            }
            
    }
    
    const encriptar = (obj) => {        
        const objString = JSON.stringify(obj);

        const secret = new window.fernet.Secret(secretKey);
        const token = new window.fernet.Token({ secret });
        const mess_encode = token.encode(objString);
        
        return mess_encode;
    }

    const HandleCheckOut = (event) =>{
        event.preventDefault();

        SetisLoading(true);

        if(!isToggled){
            setModalIsOpen(true);
            return;
        }

        const Data = location.state;

        if(Data.products.length === 0)
        {
            navigate('/Orden');
            previousDataRef.current = Data.products;
        }

        axios
        .post(process.env.REACT_APP_API_LINUM + '/api/payment', { data: encriptar(Data) } , {
            headers: {
                'Authorization': 'Bearer ' + SessionUser.access_token
            }
        })
        .then((response) => {
            if(response.data.status === "success")
            {

                const dataOrder ={
                    User : response.data.user,
                    IDSeg : response.data.order,
                    Data
                }

                if(Data.isCheckedSaveTC){
                    
                    // revisamos si esta check guardar tarjeta de credito y la enviamos
                    axios
                    .post(process.env.REACT_APP_API_LINUM + '/api/credit_card', { data: encriptar(Data.DataPago) }  , {
                        headers: {
                            'Authorization': 'Bearer ' + SessionUser.access_token
                        }
                    })
                    .then((response) => {
                        //console.log("credit_card success");
                    })
                    .catch((error) => {
                        console.error('Error credit_card:', error);
                    });
                }

                if(response.data.order)
                {
                    //Creamos un registro en update_cart_shopping con los datos de pago

                    const DataOrderBD = 
                    {
                        order:  `${response?.data?.order}`,
                        carrito: {
                            data: {
                                NoPedido : `${response?.data?.order}`,
                                Nombre_Cliente : data?.DataDeivery?.contact_name,
                                Datos_Envio :  data?.DataDeivery?.address + " " + data?.DataDeivery?.street_number + " " + data?.DataDeivery?.neighborhood + " " + data?.DataDeivery?.city + " " + data?.DataDeivery?.country + " " + data?.DataDeivery?.postal_code,
                                Productos :  data?.products,
                                Metodo_Pago: data?.Metodo === "spei" ? dataMetodoSpei() : {DatosTC: data?.DataPago?.credit_card},
                                SubTotal: data?.DataPrecio?.SubTotal,
                                Envio : data?.DataPrecio?.ShippingCost,
                                Total: data?.DataPrecio?.Total,
                                Fecha_Estimada : data?.EstimatedDate
                            }
                        }
                    }

                    axios
                    .put(process.env.REACT_APP_API_LINUM + '/api/update_cart_shopping', { data: encriptar(DataOrderBD) }  ,{
                        headers: {
                            'Authorization': 'Bearer ' + SessionUser.access_token
                        }
                    })     
                    .then((response) => {
                        //console.log("credit_card success");
                    })
                    .catch((error) => {
                        console.error('Error update_cart_shopping orders:', error);
                    });
                        
                }

                SetisLoading(false);
                navigate("/Pay" , {state: dataOrder})
            }
        })
        .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/CartAuth")
            }
            else if(error.response?.status === 409){
                setErrPago(error.response.data?.detail);
                setModalIsOpen(true);
            }
            else{
                setErrPago(error.response.data?.detail);
                setModalIsOpen(true);
            }
        });
    }
  return (
    <div className='WrapperResumen'>
        <div className="contentResumen">
                <div className="rutaResumen">
                    <Link onClick={handleMethod} className='Link'> Método de pago </Link>  <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">Carrito</div> 
                </div>
                <div className="rutaPago">
                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>1</p>
                        <h1>Carrito</h1>
                    </div>
                    <Link onClick={handleMethod}
                    className='Link'> 
                        <div className="itemRutaPago Active InactiveMobile">
                            <p className='circle Active'>2</p><h1>Datos de entrega</h1>
                        </div>
                    </Link>
                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>3</p>
                        <h1>Metodo de pago</h1>
                    </div>
                    <div className="itemRutaPago Active">
                        <p className='circle circleActive'>4</p>
                        <h1>Resumen</h1>
                    </div>
                </div> 

                <div className="Resumen">
                    <CompResumen 
                    data = {data}
                    />
                    <div className="TerminosYCondiciones">
                        <hr/>
                        <div className="top">
                            <div className="contentToggle">
                                <div className="toggle-container">
                                    <button className={`toggle-button ${isToggled ? 'toggled-on' : 'toggled-off'}`} onClick={handleToggle}>
                                        <span className="slider"></span>
                                    </button>
                                </div>
                                <div className="txtToggle">
                                    <Link to = "/tyc" target='blank' className='Link tyc'>
                                        <u>Acepto términos y condiciones</u>
                                    </Link>
                                </div>
                            </div>

                            <div className="btns">
                                <div className="atras">
                                    <Link onClick={handleMethod} className='button'> ATRAS </Link>
                                </div>
                                <div className="continuar">
                                    {!isLoading ?
                                    <Link onClick={HandleCheckOut} className='button'> CHECKOUT </Link>
                                    :
                                    <Link className='button inactive'> 
                                        PROCESANDO  
                                        <DotLoader
                                            color={"#FFFFFF"}
                                            loading={isLoading}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                            />
                                    </Link>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="txtFactura">
                                * En caso de requerir factura recuerda que podrás generarla desde tu perfil de cliente en la sección de historial de pedidos.
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <Modal
                isOpen={modalIsOpen}
                style = {customModalStyles}
                >
                <div className="modal-PagoErr">
                    {isToggled ? 
                    <>
                    <div className='HeaderPagoErr'>
                        PAGO NO PROCESADO
                    </div>
                    <div className="mejerror">
                    {data?.Metodo  === "Tarjeta" ? 
                        "No ha sido posible procesar su pago, porfavor verifique que la información sea correcta e intente nuevamente":
                        "Ocurrio un error al intentar crear su orden , por favor intente de nuevo."
                    }
                    </div>
                    <div className="btn">
                        <div className="txt">
                        {data?.Metodo  === "Tarjeta" ? 
                            <Link className='Link btnclose' onClick={handleMethod} >CORREGIR DATOS</Link> :
                            <div onClick={CloseModal} className='btnclose'>INTENTAR NUEVAMENTE</div>
                        }
                        </div>
                    </div>
                    </>
                    :
                    <>
                    <div className='HeaderPagoErr'>
                        ¡CASI LO TIENES!
                    </div>
                    
                    <div className="mejerror">Acepta los terminos y condiciones.</div>
                    <div className="btn">
                        <div className="txt">
                            <div onClick={CloseModal} className='btnclose'>CERRAR</div>
                        </div>
                    </div>
                    </>
                    }
                </div>

            </Modal>
    </div>
  )
}

export default Resumen
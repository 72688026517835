import React from 'react';
// import { OfertasBottom } from '../../components/Ofertas/CompOfertas';
import { OfertasTop } from '../../components/Ofertas/CompOfertas';
import "../Ofertas/Ofertas.scss";

const Ofertas = () => {
  return (
    <div className="wrapperOfertas">
      <div className='contentOfertas'>
        <OfertasTop/>
        {/* <OfertasBottom/> */}
      </div>
    </div>
  )
}

export default Ofertas
import React from 'react'
import "../sn4/Seccion4.scss";
import {Sn4Populares} from '../../../../components/Datos/sn4/sn4Populares';
const Seccion4 = () => {
  return (
    <div className="wrapperSeccion4">
      <Sn4Populares/>
    </div>
  )
}

export default Seccion4
import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate} from 'react-router-dom'
import "../UserAccount/Account.scss"
import CompDirecciones from '../../components/Account/Direcciones/CompDirecciones'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlaceIcon from '@mui/icons-material/Place';
import PaidIcon from '@mui/icons-material/Paid';
import StarIcon from '@mui/icons-material/Star';
import CompInfo from '../../components/Account/Info/CompInfo'
import CompPedidos from '../../components/Account/Pedidos/CompPedidos'
import CompMetodos from '../../components/Account/MetodosPago/CompMetodos'
import CompFacturacion from '../../components/Account/Facturacion/CompFacturacion'
import "./MenuPerfilMov.scss"
import MenuPerfilMov from './MenuPerfilMov'
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

const Account = () => {
  const navigate = useNavigate()
  const [selectedMenu, setSelectedMenu] = useState("info");

  const Logout = () =>{
    localStorage.removeItem("Logged");
    localStorage.removeItem("SessionUser");
    navigate("/login")
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const handleMenuSeleccionado = (menu) =>{
    setSelectedMenu(menu);
  }


  const renderContent = () => {
    switch (selectedMenu) {
      case "info":
        return <CompInfo/>;
      case "pedidos":
        return <CompPedidos/>;
      case "direcciones":
        return <CompDirecciones/>;
      case "MetodosPago":
        return <CompMetodos/>;
      case "Factura":
      return <CompFacturacion/>
      case "CerrarSesion":
        return Logout();
      default:
        return null;
    }
    
}
  return (
    <>
    <div className='WrapperAccount'>
      <div className='contentPerfil'>
      <div className="left">
        <div className="MenuMovPerfil">
          <div className="titPerfil">Mi Perfil</div>
          <div className='midMobile'>
            <div className="midTop">
              <div className="itemMidMenu">
                <MenuPerfilMov onMenuSeleccionado={handleMenuSeleccionado} />
              </div>
            </div>
          </div>
        </div>
        <div className="MenuPerfil">
        <div className="titPerfil">Mi Perfil</div>
          <div className='menu'>
            <ul>
                <li onClick={() => handleMenuClick("info")}><VerifiedUserIcon className='icon'/>Información</li>
                <li onClick={() => handleMenuClick("pedidos")}><LocalShippingIcon className='icon'/>Mis Pedidos</li>
                <li onClick={() => handleMenuClick("direcciones")}><PlaceIcon className='icon'/>Mis direcciones</li>
                <li onClick={() => handleMenuClick("MetodosPago")}><PaidIcon className='icon'/>Métodos de pago</li>
                <li onClick={() => handleMenuClick("Factura")}><StarIcon className='icon'/>Facturación</li>
                
              </ul>
          </div>
          <Link onClick = {() => handleMenuClick("CerrarSesion")} className='Link BtnOut'><LogoutIcon className='icon'/> Cerrar sesión</Link>
        </div>
      </div>
      <div className="right">
          {renderContent()}
      </div>
  
    </div>
    </div>
    </>
  )
}

export default Account
import React, {useEffect, useState, useRef } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import '../Pay/PayOrder.scss'
import DotLoader from "react-spinners/BounceLoader";
import { resetCart } from '../../../redux/CartReducer';
import { useDispatch } from "react-redux";
import CompResumen from '../Resumen/CompResumen';
import { DialerSip } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';

const PayOrder = () => {
  const PedidoPending = localStorage.getItem("InfoPay-Pending");
  const location = useLocation();
  const [error, setError] = useState(null);
  const [retryInterval, setRetryInterval] = useState(null);
  const [loading , setLoading] = useState(true);
  const dispatch = useDispatch();
  const [IDSeg , SetID] = useState(location.state?.IDSeg ? location.state.IDSeg : 0);
  const navigate = useNavigate();
  const Data = location.state?.Data;
  const MetodoPago = location.state?.Data?.Metodo;

  const componentRef = useRef();

  const dataOrder ={
    User : location.state?.User,
    IDSeg : IDSeg,
    Data
  }
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass : "Resumen, IdPedido",
    copyStyles : true,
    documentTitle: "LinumOrden" + {IDSeg},
    onAfterPrint : ()=> navigate("/Pay" , {state: dataOrder}),
    onBeforePrint : ()=> navigate("/Pay" , {state: dataOrder})
  });


  useEffect(() => {
    if (IDSeg == 0 || !IDSeg ) {
      navigate("/");
    }
  }, IDSeg);

  //hace el reset del carrito en icono de cart
  useEffect(() => {
    if (location.state !== "PedidoPending") {
      dispatch(resetCart());
    }
  }, [dispatch],location);
  
  const imprimirResumen = () => {
    const contenido = document.getElementById('divResumen');
    const ventanaImpresion = window.open('', '_blank');
    ventanaImpresion.document.write(contenido.innerHTML);
    ventanaImpresion.document.close();
    ventanaImpresion.print();
    ventanaImpresion.close();
  };

  return (
    <div className="WrapperPay">
        <div className="contentPay">
            <div className="rutaPay">
            <Link to="/"> Home </Link>  <img src="/img/producto/arrow.svg" alt=""/> <div className="t2">Carrito</div> 
            </div>
            <h1 className='titulo'>Carrito de compras</h1>
            <div className="rutaPago">
                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>1</p>
                        <h1>Carrito</h1>
                    </div>
                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>2</p><h1>Datos de entrega</h1>
                    </div>

                    <div className="itemRutaPago Active InactiveMobile">
                        <p className='circle Active'>3</p>
                        <h1>Metodo de pago</h1>
                    </div>
                    <div className="itemRutaPago Active">
                        <p className='circle circleActive'>4</p>
                        <h1>Resumen</h1>
                    </div>
                </div>
         
            <div ref={componentRef}>
              <div className="Resumen">
                <CompResumen
                  data = {Data}
                  IDSeg = {IDSeg}
                />
                 <div className="Acciones">
                    <div className="item" onClick={handlePrint}><Link className='Link'>Imprimir</Link></div>
                </div>
              </div>
            </div>
            {/*
              <div className="contentPaySeguimiento">
                <div className="seguimiento">
                {IDSeg ?
                  <>
                    <div className="titlePago">¡Pago completado!</div>
                    <span className='TxtSeg'># de orden : 
                      <div className='idseg'> {IDSeg} </div>
                    </span>
                  </>
                  :
                  <>
                    {loading ? 
                    <div className='spinner'>
                      <DotLoader
                      color={"#1D4C5A"}
                      loading={loading}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      size={80}/>
                    </div>:""
                    }
                    <span className='TxtSeg'>Estamos generando tu # de seguimiento...</span>
                  </>
                }
                </div>
              </div>
              */}
             <div className="Acciones">
                
                <div className="item"><Link className='Link' to="/Catalogo">Seguir comprando</Link></div>
                <div className="item"><Link className='Link' to="/Account">Ir a mi cuenta</Link></div>
              </div>
          </div>  
      </div>
  )
}

export default PayOrder
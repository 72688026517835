import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlaceIcon from '@mui/icons-material/Place';
import PaidIcon from '@mui/icons-material/Paid';
import StarIcon from '@mui/icons-material/Star';
import LogoutIcon from '@mui/icons-material/Logout';

function MenuPerfilMov(props) {
    const [showMenuMov, setShowMenuMov] = useState(false);

  function toggleMenu() {
    setShowMenuMov(!showMenuMov);
  }

  const handleMClick = (menu) => {
    toggleMenu();
    props.onMenuSeleccionado(menu); // Llama a la función del padre con el menú seleccionado
  };

  return (
    <>
    <div className="mobile-menu">
      <button className="menu-button" onClick={toggleMenu}>
      <MenuIcon/>
      </button>
        <div className={showMenuMov ? "":"menu-close"}>
            <ul className="menu-list">
                <li>
                    <Link className='Link' onClick={() => handleMClick("info")}>
                        <VerifiedUserIcon className='icon'/>
                        Información
                    </Link>
                </li>
                <li>
                    <Link className='Link' onClick={() => handleMClick("pedidos")}>
                        <LocalShippingIcon className='icon'/>
                        Mis Pedidos
                    </Link>
                </li>
                <li>
                    <Link className='Link' onClick={() => handleMClick("direcciones")}>
                        <PlaceIcon className='icon'/>
                        Mis direcciones
                    </Link>
                </li>
                <li>
                    <Link className='Link' onClick={() => handleMClick("MetodosPago")}>
                        <PaidIcon className='icon'/>
                        Métodos de pago
                    </Link>
                </li>
                <li>
                    <Link className='Link' onClick={() => handleMClick("Factura")}>
                        <StarIcon className='icon'/>
                        Facturación
                    </Link>
                </li>
                <li>
                    <Link className='Link' onClick={() => handleMClick("CerrarSesion")}>
                        <LogoutIcon className='icon'/>
                        Cerrar Sesión
                    </Link>
                </li>
            </ul>
        </div>
    </div>
    
    </>
  );
}

export default MenuPerfilMov;
import {React, useState, useEffect } from "react";
import axios from "axios";
import "./Faqs.scss"
import CardFaq from './CardFaq';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Faqs = () => {
  const [QuestionsData,setQuestionsData] = useState([])
  const [ReadMore,setReadMore] = useState(false);
  const [DataReadMore,SetDataReadMore] = useState("")
  
    useEffect(() => {
      const fetchData = async () => {
          const QuestionsData = await axios.get(process.env.REACT_APP_API_URL + 'questions');
          const DataFull = QuestionsData.data.data;
          setQuestionsData(DataFull);
      };
      fetchData();
  }, [DataReadMore]);


  const HandleReadMore = (readmore) =>{
    if(readmore)
    {
      window.scrollTo(0, 0); // Haz que la ventana se desplace hacia la parte superior
      setReadMore(true);
      SetDataReadMore(readmore)
    }
  }

  const HandleReturn = () =>{
    setReadMore(false);
  }

  const answer = DataReadMore.answer?.split('\n');

  return (
    <div className="wrapperFaqs">
      <div className="contentFaqs">
      <div className="title">Preguntas frecuentes <LiveHelpIcon className='icon'/></div>
          {!ReadMore ?
          <>
          
           <div className="infoFaqs">
            {QuestionsData.map((question,index) =>(
              <CardFaq 
              question = {question}
              OnReadMore = {HandleReadMore}
              color = {index % 2 === 0}
              />
            ))}
          </div>
          </>
          :
          <>
          <div className="detalleFaqs">
            <div className="ContentReadmore">
              <div className="return">
                <Link className='Link' onClick={HandleReturn}>
                      <ArrowBackIosNewIcon/>
                </Link>
              </div>
              <div className="titleReadMore">
                  {DataReadMore.question}
              </div>
              <div className="answer">
              {answer?.map((texto, index) => (
                <>
                {texto}
                <br />
                </>
              ))}
              </div>
            </div>
          </div>
          </>
          }
          
        </div>
    </div>
  )
}

export default Faqs
import React from 'react'
import CompRecuperarPwd from '../../components/Login/RecuperarPwd/CompRecuperarPwd';
import "../Login/Login.scss";

const RecuperarPwd = () => {
  return (
    <div className="wrapperLogin">
       <CompRecuperarPwd/>
    </div>
    
  )
}

export default RecuperarPwd
import axios from 'axios';
import React,{useEffect, useState} from 'react'
import {useNavigate } from 'react-router-dom'
import "../Info/CompInfo.scss"
import { Padding } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BackspaceIcon from '@mui/icons-material/Backspace';

const CompInfo = () => {
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [telefonoMovil,setTelefonoMovil] = useState('');
    const [nombre, setnombre] = useState('');
    const [apellido, setapellido] = useState('');
    const [ErrorUpdate, setErrorUpdate] = useState("");
    const [DataInfo, SetData] = useState("");
    const [password , setPassword] = useState("00000000");
    const navigate = useNavigate();
    const storedData = localStorage.getItem("SessionUser");
    const SessionUser = JSON.parse(storedData);
    const state = localStorage.getItem("Logged");
    const [IsUpdate,setIsUpdate] = useState(false);
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (ErrorUpdate || IsUpdate) {
          const timer = setTimeout(() => {
            setErrorUpdate(false);
            setIsUpdate(false);
          }, 3000); //
    
          return () => clearTimeout(timer);
        }
      }, [ErrorUpdate, IsUpdate]);

    useEffect(() => {
        axios
          .get(process.env.REACT_APP_API_LINUM + '/api/users', {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
              SetData(response.data.user);
              setnombre(response.data.user.nombre);
              setapellido(response.data.user.apellidos);
              setEmail(response.data.user.email);
              setTelefonoMovil(response.data.user.phone1.toString().substring(2));
              setTelefono(response.data.user.phone2.toString().substring(2));
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error delivery_points:', error);
          });
    }, []);

const handleNombreChange = (event) => {
        setnombre(event.target.value);
    };

    const handleApellidoChange = (event) => {
        setapellido(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleTelefonoChange = (event) => {
        setTelefono(event.target.value);
    };

    const handleTelefonoMovilChange = (event) => {
        setTelefonoMovil(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handlePerfil = (event) => {
        event.preventDefault();
        
        const RegisterData = {
          nombre: nombre,
          apellidos: apellido,
          email,
          password: password === "00000000" ? undefined : password,
          telefono : telefono && parseInt(52 + telefono),
          telefono_movil : telefonoMovil && parseInt(52 + telefonoMovil)
        }

        //console.log(RegisterData);

        axios
          .put(process.env.REACT_APP_API_LINUM + '/api/users', RegisterData, {
              headers: {
                  'Authorization': 'Bearer ' + SessionUser.access_token
              }
          })
          .then((response) => {
            //console.log(response);
             if(response.status === 200)
                setIsUpdate(true);
          })
          .catch((error) => {
            if(error.response?.status === 401){
                localStorage.removeItem('SessionUser');
                localStorage.removeItem('Logged');
                navigate("/login")
            }else
              console.error('Error delivery_points:', error);
              setErrorUpdate(true);
          });

        
    }

    const toggleEdit = (event) => {
        event.preventDefault();
        setEditable(!editable);
        if(editable)
            handlePerfil(event);
    };

    const toggleReturn = () =>{
        setEditable(false);
    }

  return (
    <div className="ContentInfo">
        <div className="PerfilInfo">
            <div className="tituloInfo">Información</div>
            <form  className='form'>
            <div className="row">
            <div className='FormRow'>
                <label className='label'>Nombre</label>
                <input 
                type="text" 
                value={nombre} 
                onChange={handleNombreChange} 
                placeholder="Nombre" required 
                readOnly={!editable} // Cambia readOnly según el estado 'editable'
                style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                className='FormRow'/>
            </div>
            <div className='FormRow'>
                <label className='label'>Apellidos</label>
                <input 
                type="text" 
                value={apellido}
                onChange={handleApellidoChange} 
                placeholder="Apellido" 
                required 
                readOnly={!editable} // Cambia readOnly según el estado 'editable'
                style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                className='FormRow'/>
                </div>
            </div>
            <div className="row">
                <div className='FormRow'>
                <label className='label'>Email</label>
                <input 
                type="email" 
                value={email} 
                onChange={handleEmailChange} 
                placeholder="Email" 
                required
                readOnly={!editable} // Cambia readOnly según el estado 'editable'
                style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                />
                </div>

                <div className='FormRow'>
                    <label className='label'>Teléfono Móvil (Notificaciones)</label>
                    <input 
                        type="text" 
                        value={telefonoMovil} 
                        onChange={handleTelefonoMovilChange} 
                        placeholder="Teléfono a 10 digitos" 
                        pattern="[0-9]{10}" 
                        title="Ingresa los 10 digitos de tu télefono"
                        maxLength="10"
                        inputMode="numeric"
                        required
                        readOnly={!editable} // Cambia readOnly según el estado 'editable'
                        style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                    />
                </div>
            
            </div>

            <div className="row">
            <div className='FormRow'>
                <label className='label'>Teléfono</label>
                <input 
                    type="text" 
                    value={telefono} 
                    onChange={handleTelefonoChange} 
                    placeholder="Teléfono a 10 digitos" 
                    pattern="[0-9]{10}"
                    title="Ingresa los 10 digitos de tu télefono"
                    maxLength="10"
                    inputMode="numeric"
                    readOnly={!editable} // Cambia readOnly según el estado 'editable'
                    style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                 />
                </div>

                <div className='FormRow'>
                <label className='label'>Contraseña</label>
                <input type="password" value={password} 
                    onChange={handlePasswordChange} 
                    placeholder="Contraseña" 
                    minLength="8" 
                    required
                    readOnly={!editable} // Cambia readOnly según el estado 'editable'
                    style={{ color: editable ? 'black' : 'gray' }} // Cambia el color del texto según el estado 'editable'
                 />
                </div>
            </div>

            
                {editable ? 
                    <div className='Actions'>
                        <button onClick={toggleEdit}>
                            GUARDAR
                        </button>
                            <BackspaceIcon className='RET' onClick={toggleReturn}/>
                    </div>
                : 
                    <div className='Actions'>
                        <button onClick={toggleEdit}>
                            EDITAR
                        </button>
                    </div>

                }
            

            <div className="mejerror">
                {ErrorUpdate && <p>Ocurrio un error al actualizar tu información, intenta nuevamente</p>}
            </div>
            <div className="msjeOK">
                {IsUpdate && <p>Se actualizó tu información con éxito</p>}
            </div>
            </form>
        </div>
    </div>
  )
}

export default CompInfo
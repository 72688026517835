import {React, useState, useEffect } from "react";
import axios from "axios";
import "./Tyc.scss"

const Tyc = () => {
  const [Data,setData] = useState("");

  useEffect(()=>{
    const fetchData = async () => {
      const catalogoData = await axios.get(process.env.REACT_APP_API_URL + 'terms-conditions');
      const DataFull = catalogoData.data.data;
      setData(DataFull.attributes);   
    };
    fetchData();
  },[])

  const textos = Data.Body?.split('\n');

  return (
    <div className="wrapperTyc">
      <div className="contentTyc">
        <div className="title">{Data.Title}</div>
        <div className="contetntxt">
          {textos?.map((texto, index) => (
            <>
            {texto}
            <br />
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tyc
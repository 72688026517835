import React, { useState, useEffect, useRef} from 'react'
import Modal from 'react-modal';
import "../Delivery/Delivery.scss"
import CloseIcon from '@mui/icons-material/Close';
import FormDirecciones from '../../Account/Direcciones/FormDirecciones/FormDirecciones';
import FormFactura from '../../Account/Facturacion/FormFactura/FormFactura';


const AddRegistroModal = ({ isOpen, onClose , onRegistroAgregado, comp }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const customModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : '60%', 
      height: '80%',
      background: 'white',
    }
  };

  return (
    <div className="ModalAddDireccion">
        <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Agregar Registro"
        style={customModalStyles}
        >
        <div className="contentModal">
          <div className="button">
            <button type="button" onClick={onClose} className='cerrar'><CloseIcon/></button>
          </div>
          <div className="modal">
            {
            comp === "Delivery" ?
            <>
            <FormDirecciones onRegistroAgregado = {onRegistroAgregado}  Ref = "/CartAuth"/>
            </>:
            <FormFactura onRegistroAgregado = {onRegistroAgregado}  Ref = "/CartAuth"/>
            }
          </div>
        </div>
        </Modal>
    </div>
  );
};

export default AddRegistroModal;

import { useState, useEffect } from "react";
import axios from 'axios';

export const ComponentCinta = () => {
        const [Data, setData] = useState([]);
        const Iconos = [
            { id: 0, name: 'pay', url: '/img/payment.svg' },
            { id: 1, name: 'pay', url: '/img/Quality.svg'},
            { id: 2, name: 'pay', url: '/img/Laptop.svg'}
          ];
        
        useEffect(() => {
            const fetchData = async () => {
                const DataCinta = await axios.get(process.env.REACT_APP_API_URL + 'cinta-icono?populate=*');
                setData(DataCinta.data.data.attributes.TitleMessage);
            };
            fetchData();
        }, []);

        return (
        <div className="wrapperSeccion1">
        <div className="contenedorSn1">
            {Data.map((Data, index) => (
                <div className="Itemtxt" key={index}>
                    <div className='img'>
                        <img alt='' className={Iconos[index].name} src={Iconos[index].url} key={index} />
                    </div>
                    <div className="infoItem">
                        <h1 className='tit'>{Data.title}</h1>
                        <span>{Data.message}</span>
                    </div>
                </div>
            ))}
        </div>
        </div>
        )
    };
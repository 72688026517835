import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';


function DropdownMenu() {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <div className="dropdown-menu">
      <button className="dropdown-menu__button" onClick={toggleMenu}>
      <MenuIcon/>
      </button>
        <div className={showMenu ? "menu-open":""}>
        <nav>
          <ul>
            <li>
                <Link className='Link' to="/Inicio" onClick={toggleMenu}>INICIO</Link>
            </li>
            <li>
                <Link className='Link' to="/Nosotros" onClick={toggleMenu}>NOSOTROS</Link>
            </li>
            <li>
                <Link className='Link' to="/Catalogo" onClick={toggleMenu}>CATALOGO</Link>
            </li>
            <li>
                <Link className='Link' to="/Ofertas" onClick={toggleMenu}>OFERTAS</Link>
            </li>
            <li>
                <Link className='Link' to="/Contacto" onClick={toggleMenu}>CONTACTO</Link>
            </li>
          </ul>
        </nav>
        </div>
    </div>
  );
}

export default DropdownMenu;
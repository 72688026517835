import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import "../../../pages/Home/Secciones/sn3/Seccion3.scss";

  export const Sn3Calidad = () => {
        const [CalidadData, setCalidadData] = useState([]);
        const [PhotoMain, setMainPhoto] = useState([]);
        const [Family, setFamily] = useState([]);

        useEffect(() => {
          const fetchData = async () => {

            const CalidadDt = await axios.get(process.env.REACT_APP_API_URL + 'catalogue-access?populate[MainPhoto]=*&[populate][Family][populate]=*');
            const data = CalidadDt.data.data.attributes;
            const mainPhoto = data.MainPhoto.data.attributes;
            const family = data.Family;
            setMainPhoto(mainPhoto);
            setFamily(family);
            setCalidadData(data);
            
          };
          fetchData();

        }, []);


    return (
    <div className="contenedorSn3">
        <div className="contentCalidad">
          <div className="left">
              <div className="tit">
                <div className="tituloh1">
                  <h1>{CalidadData.title}</h1>
                </div>
                <span className='textospan'>{CalidadData.Paragraph}</span>
              </div>
              <div className="ContImages">
              {Family.map((family,index) => ( 
                  <div className="Contimg" key={index}>
                    <Link to ={"/Catalogo/Fam/" + family.Family.data.id } className="Link">
                      <div className="img">
                        <img 
                        src={process.env.REACT_APP_UPLOAD_URL + family.photo.data.attributes.formats.large.url} alt=''></img>
                        <div className="detallesimg">
                          <h1>DETALLES</h1>
                        </div>
                      </div>
                      </Link>
                    <div className="imgtext">
                      <span> {family.Family.data.attributes.Family} </span>
                    </div> 
                  </div>
                  ))}
              </div>
          </div>
          <div className="imgPrincipal" >
            <div className="imgright" key="">
                <img className='img' 
                src={process.env.REACT_APP_UPLOAD_URL + PhotoMain.url} alt=''></img>
            </div>
          </div>
        </div>
        <div className="contentBtn">
            <Link className='btn' to="../Catalogo/">VER CATALOGO DE PRODUCTOS<EastIcon className='iconCat'/></Link>
        </div>
    </div>
    );
};